<!-- <p>dialogbox works!</p> -->
<!-- <h3 mat-dialog-title>Alerts</h3> -->
<mat-dialog-content class="mat-typography">
  <div class="col-md-12" *ngIf="this.data.clicked == 0">
    <table class="tableStyle">
      <tr>
        <th>Timestamp</th>
        <th>Temp(Actual)</th>
        <th>Temp(Forecast)</th>
        <th>KPI validation</th>
      </tr>
      <tr *ngFor="let tabval of tabdata">
        <td>{{ tabval.date_time | date : "dd/MM/yyyy HH:mm:ss" }}</td>
        <td>{{ tabval.actual_temp }}</td>
        <td>{{ tabval.forecast_temp }}</td>
        <td>{{ tabval.kpi_validation_temp }}</td>
      </tr>
    </table>
    <button mat-button mat-dialog-close>Close</button>
  </div>
  <div class="col-md-12" *ngIf="this.data.clicked == 1">
    <table class="tableStyle">
      <tr>
        <th>Timestamp</th>
        <th>Outlet CO(actual)</th>
        <th>Outlet CO(forecast)</th>
        <th>KPI validation</th>
      </tr>
      <tr *ngFor="let tabval of tabdata1">
        <td>{{ tabval.date_time | date : "dd/MM/yyyy HH:mm:ss" }}</td>
        <td>{{ tabval.actual_co }}</td>
        <td>{{ tabval.forecast_co }}</td>
        <td>{{ tabval.kpi_validation_co }}</td>
      </tr>
    </table>
    <button mat-button mat-dialog-close>Close</button>
  </div>
  <div class="col-md-12" *ngIf="this.data.clicked == 2">
    <!-- form to be added -->

    <div class="card">
      <div class="card-header">
        <div class="text-header">Add User</div>
      </div>
      <div class="card-body">
        <form [formGroup]="userForm">
          <div class="form-group">
            <label for="username">Username:</label>
            <input
              required=""
              class="form-control"
              id="username"
              type="text"
              formControlName="username"
            />
          </div>
          <div class="form-group">
            <label for="username">First Name:</label>
            <input
              required=""
              class="form-control"
              n
              id="first_name"
              type="text"
              formControlName="first_name"
            />
          </div>
          <div class="form-group">
            <label for="username">Last Name:</label>
            <input
              required=""
              class="form-control"
              id="last_name"
              type="text"
              formControlName="last_name"
            />
          </div>
          <div class="form-group">
            <label for="email">Email:</label>
            <input
              required=""
              class="form-control"
              id="email"
              type="email"
              formControlName="email"
            />
          </div>
          <div class="form-group">
            <label for="password">Password:</label>
            <input
              required=""
              class="form-control"
              id="password"
              type="password"
              formControlName="password"
            />
          </div>
          <div class="form-group">
            <label for="confirm-password">Confirm Password:</label>
            <input
              required=""
              class="form-control"
              id="confirm-password"
              formControlName="confirm-password"
              type="password"
            />
          </div>
          <div class="form-group">
            <label for="mobile_no">Mobile No:</label>
            <input
              required=""
              class="form-control"
              id="mobile_no"
              formControlName="mobile_no"
              type="input"
            />
          </div>
          <div class="form-group">
            <label for="role">Role:</label>
            <input
              required=""
              class="form-control"
              id="role"
              type="text"
              formControlName="role"
            />
          </div>
          <div class="form-group">
            <label for="is_active">Active:</label>
            <input
              required=""
              class="form-control"
              name="is_active"
              id="is_active"
              type="input"
              formControlName="is_active"
            />
          </div>
          <!-- <div class="form-group">
                        <label for="date_joined">Date joined:</label>
                        <input required="" class="form-control"  id="date_joined" type="input" formControlName="date_joined">
                    </div> -->
        </form>
      </div>
      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <button mat-button mat-dialog-close (click)="addUser()">Submit</button>
        <button mat-button mat-dialog-close>Close</button>
      </span>
    </div>
  </div>

  <div class="col-md-12" *ngIf="this.data.clicked == 3">
    <!-- form to be added -->

    <div class="card">
      <div class="card-header">
        <div class="text-header">Delete User</div>
      </div>
      <div class="card-body">Are you sure you want to delete user?</div>
      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <button mat-button mat-dialog-close (click)="deleteDriver(tabdata3)">
          delete
        </button>
        <button mat-button mat-dialog-close>Close</button>
      </span>
    </div>
  </div>
  <div class="col-md-12" *ngIf="this.data.clicked == 4">
    <!-- form to be added -->

    <div class="card">
      <div class="card-header">
        <div class="text-header">Edit User</div>
      </div>
      <div class="card-body">
        <form [formGroup]="userForm">
          <div class="form-group">
            <label for="username">Username:</label>
            <input
              required=""
              class="form-control"
              id="username"
              type="text"
              [(ngModel)]="tabVal.username"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div class="form-group">
            <label for="username">First Name:</label>
            <input
              required=""
              class="form-control"
              n
              id="first_name"
              type="text"
              [(ngModel)]="tabVal.first_name"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>

          <div class="form-group">
            <label for="username">Last Name:</label>
            <input
              required=""
              class="form-control"
              id="last_name"
              type="text"
              [(ngModel)]="tabVal.last_name"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div class="form-group">
            <label for="email">Email:</label>
            <input
              required=""
              class="form-control"
              id="email"
              type="email"
              [(ngModel)]="tabVal.email"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div class="form-group">
            <label for="password">Password:</label>
            <input
              required=""
              class="form-control"
              id="password"
              type="password"
              [(ngModel)]="tabVal.password"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div class="form-group">
            <label for="confirm-password">Confirm Password:</label>
            <input
              required=""
              class="form-control"
              id="confirm-password"
              type="password"
            />
          </div>
          <div class="form-group">
            <label for="mobile_no">Mobile No:</label>
            <input
              required=""
              class="form-control"
              id="mobile_no"
              [(ngModel)]="tabVal.mobile_no"
              [ngModelOptions]="{ standalone: true }"
              type="input"
            />
          </div>
          <div class="form-group">
            <label for="role">Role:</label>
            <input
              required=""
              class="form-control"
              id="role"
              type="text"
              [(ngModel)]="tabVal.role"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div class="form-group">
            <label for="is_active">Active:</label>
            <input
              required=""
              class="form-control"
              name="is_active"
              id="is_active"
              type="input"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tabVal.is_active"
            />
          </div>
        </form>
      </div>
      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <button mat-button mat-dialog-close (click)="updateUserDetails()">
          Update
        </button>
        <button mat-button mat-dialog-close>Close</button>
      </span>
    </div>
  </div>

  <div class="col-md-12" *ngIf="this.data.clicked == 5">
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1%;
      "
    >
      <p class="mainGridtitle1">Forecasting Values</p>
    </div>
    <!-- <hr> -->

    <div class="row" style="padding: 1%">
      <div class="col-md-6">
        <div
          class="red"
          style="
            border-radius: 6px;
            box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
          "
        >
          <label>Free Lime</label>
          <!-- <div *ngIf="Realtimedata.length !=0 ">
                        <label class="per" *ngIf="vval.cyc_6_avg_temp < 0 ">0.00</label>
                        <label class="per" *ngIf="vval.cyc_6_avg_temp >=  0 ">
                          {{vval.cyc_6_avg_temp}}
                        </label>
                      </div> -->
          <!-- <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div> -->
        </div>
      </div>

      <div class="col-md-6">
        <div
          class="red"
          style="
            border-radius: 6px;
            box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
          "
        >
          <label>Burning Zone Temp</label>
          <!-- <div *ngIf="Realtimedata.length !=0 ">
                        <label class="per" *ngIf="vval.cyc_6_avg_temp < 0 ">0.00</label>
                        <label class="per" *ngIf="vval.cyc_6_avg_temp >=  0 ">
                          {{vval.cyc_6_avg_temp}}
                        </label>
                      </div> -->
          <!-- <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div> -->
        </div>
      </div>

      <div class="col-md-6" style="padding-top: 15px">
        <div
          class="red"
          style="
            border-radius: 6px;
            box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
          "
        >
          <label>Kiln Inlet O2 (%)</label>
          <!-- <div *ngIf="Realtimedata.length !=0 ">
                        <label class="per" *ngIf="vval.cyc_6_avg_temp < 0 ">0.00</label>
                        <label class="per" *ngIf="vval.cyc_6_avg_temp >=  0 ">
                          {{vval.cyc_6_avg_temp}}
                        </label>
                      </div> -->
          <!-- <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div> -->
        </div>
      </div>

      <div class="col-md-6" style="padding-top: 15px">
        <div
          class="red"
          style="
            border-radius: 6px;
            box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
          "
        >
          <label>Kiln Inlet CO (%)</label>
          <!-- <div *ngIf="Realtimedata.length !=0 ">
                        <label class="per" *ngIf="vval.cyc_6_avg_temp < 0 ">0.00</label>
                        <label class="per" *ngIf="vval.cyc_6_avg_temp >=  0 ">
                          {{vval.cyc_6_avg_temp}}
                        </label>
                      </div> -->
          <!-- <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div> -->
        </div>
      </div>
    </div>

    <!-- <div class="row" style="padding: 1%;">
              <div class="col-md-6">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">Free Lime</label>
                  <div *ngIf="Realtimedata.length !=0 ">
                    <label class="per" *ngIf="vval.cyc_6_avg_temp < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.cyc_6_avg_temp >=  0 ">
                      {{vval.cyc_6_avg_temp}}
                    </label>
                  </div>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">Burning Zone Temp</label>
                  <div *ngIf="Realtimedata.length !=0 ">
                    <label class="per" *ngIf="vval.cyc_6_outlet_co < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.cyc_6_outlet_co >=  0 ">
                      {{vval.cyc_6_outlet_co}}
                    </label>
                  </div>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div> -->
    <!-- <div class="col-md-4">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">Kiln Feed (TPH)</label>
                  <div *ngIf="Realtimedata.length !=0 ">
                    <label class="per" *ngIf="vval.kiln_feed < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.kiln_feed >=  0 ">{{vval.kiln_feed}}</label>
                  </div>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div> -->
    <!-- </div> -->
    <!-- <div class="row" style="padding: 1%;">


              <div class="col-md-6">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">Kiln inlet O2  -->
    <!-- (M<sup>3</sup>/Hr) -->
    <!-- </label>
                  <div *ngIf="Realtimedata.length !=0 ">
                    <label class="per" *ngIf="vval.liquid_afr_flow_rate < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.liquid_afr_flow_rate >=  0 "> -->
    <!-- {{vval.liquid_afr_flow_rate}} -->
    <!-- </label>
                  </div>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">Kiln Inlet CO</label>
                  <div *ngIf="Realtimedata.length !=0 ">
                    <label class="per" *ngIf="vval.pc_coal < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.pc_coal >=  0 "> -->
    <!-- {{vval.pc_coal}} -->
    <!-- </label>
                  </div>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div> -->
    <!-- <div class="col-md-4">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">Main Coal (TPH)</label>
                  <div *ngIf="Realtimedata.length !=0 ">
                    <label class="per" *ngIf="vval.main_coal_tph < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.main_coal_tph >=  0 ">{{vval.main_coal_tph}}</label>
                  </div>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div> -->
    <!-- </div>
          </div>
        </div>
      </div> -->
    <button mat-button mat-dialog-close>Close</button>
  </div>

  <div class="col-md-12" *ngIf="this.data.clicked == 6">
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1%;
      "
    >
      <p class="mainGridtitle1">Savings</p>
    </div>
    <!-- <hr> -->

    <div class="row" style="padding: 1%">
      <div class="col-md-6">
        <div
          class="red"
          style="
            border-radius: 6px;
            box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
          "
        >
          <label>Main Coal (TPH)</label>
          <!-- <div *ngIf="Realtimedata.length !=0 ">
                        <label class="per" *ngIf="vval.cyc_6_avg_temp < 0 ">0.00</label>
                        <label class="per" *ngIf="vval.cyc_6_avg_temp >=  0 ">
                          {{vval.cyc_6_avg_temp}}
                        </label>
                      </div> -->
          <!-- <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div> -->
        </div>
      </div>

      <div class="col-md-6">
        <div
          class="red"
          style="
            border-radius: 6px;
            box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
          "
        >
          <label>Kiln Speed (RPM)</label>
          <!-- <div *ngIf="Realtimedata.length !=0 ">
                        <label class="per" *ngIf="vval.cyc_6_avg_temp < 0 ">0.00</label>
                        <label class="per" *ngIf="vval.cyc_6_avg_temp >=  0 ">
                          {{vval.cyc_6_avg_temp}}
                        </label>
                      </div> -->
          <!-- <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div> -->
        </div>
      </div>

      <div class="col-md-6" style="padding-top: 10px">
        <div
          class="red"
          style="
            border-radius: 6px;
            box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
          "
        >
          <label>PA Fan Speed (RPM)</label>
          <!-- <div *ngIf="Realtimedata.length !=0 ">
                        <label class="per" *ngIf="vval.cyc_6_avg_temp < 0 ">0.00</label>
                        <label class="per" *ngIf="vval.cyc_6_avg_temp >=  0 ">
                          {{vval.cyc_6_avg_temp}}
                        </label>
                      </div> -->
          <!-- <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div> -->
        </div>
      </div>

      <div class="col-md-6" style="padding-top: 10px">
        <div
          class="red"
          style="
            border-radius: 6px;
            box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
          "
        >
          <label>PH Fan Speed (RPM)</label>
          <!-- <div *ngIf="Realtimedata.length !=0 ">
                        <label class="per" *ngIf="vval.cyc_6_avg_temp < 0 ">0.00</label>
                        <label class="per" *ngIf="vval.cyc_6_avg_temp >=  0 ">
                          {{vval.cyc_6_avg_temp}}
                        </label>
                      </div> -->
          <!-- <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div> -->
        </div>
      </div>
    </div>

    <!-- <div class="row" style="padding: 1%;">
                  <div class="col-md-6">
                    <div class="red" style="border-radius: 6px;
                  box-shadow: inset 7px 7px 14px black,
                              inset -7px -7px 14px #5a5a5a;">
                      <label for="temp">Free Lime</label>
                      <div *ngIf="Realtimedata.length !=0 ">
                        <label class="per" *ngIf="vval.cyc_6_avg_temp < 0 ">0.00</label>
                        <label class="per" *ngIf="vval.cyc_6_avg_temp >=  0 ">
                          {{vval.cyc_6_avg_temp}}
                        </label>
                      </div>
                      <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="red" style="border-radius: 6px;
                  box-shadow: inset 7px 7px 14px black,
                              inset -7px -7px 14px #5a5a5a;">
                      <label for="temp">Burning Zone Temp</label>
                      <div *ngIf="Realtimedata.length !=0 ">
                        <label class="per" *ngIf="vval.cyc_6_outlet_co < 0 ">0.00</label>
                        <label class="per" *ngIf="vval.cyc_6_outlet_co >=  0 ">
                          {{vval.cyc_6_outlet_co}}
                        </label>
                      </div>
                      <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                    </div>
                  </div> -->
    <!-- <div class="col-md-4">
                    <div class="red" style="border-radius: 6px;
                  box-shadow: inset 7px 7px 14px black,
                              inset -7px -7px 14px #5a5a5a;">
                      <label for="temp">Kiln Feed (TPH)</label>
                      <div *ngIf="Realtimedata.length !=0 ">
                        <label class="per" *ngIf="vval.kiln_feed < 0 ">0.00</label>
                        <label class="per" *ngIf="vval.kiln_feed >=  0 ">{{vval.kiln_feed}}</label>
                      </div>
                      <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                    </div>
                  </div> -->
    <!-- </div> -->
    <!-- <div class="row" style="padding: 1%;">
    
    
                  <div class="col-md-6">
                    <div class="red" style="border-radius: 6px;
                  box-shadow: inset 7px 7px 14px black,
                              inset -7px -7px 14px #5a5a5a;">
                      <label for="temp">Kiln inlet O2  -->
    <!-- (M<sup>3</sup>/Hr) -->
    <!-- </label>
                      <div *ngIf="Realtimedata.length !=0 ">
                        <label class="per" *ngIf="vval.liquid_afr_flow_rate < 0 ">0.00</label>
                        <label class="per" *ngIf="vval.liquid_afr_flow_rate >=  0 "> -->
    <!-- {{vval.liquid_afr_flow_rate}} -->
    <!-- </label>
                      </div>
                      <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                    </div>
                  </div>
    
                  <div class="col-md-6">
                    <div class="red" style="border-radius: 6px;
                  box-shadow: inset 7px 7px 14px black,
                              inset -7px -7px 14px #5a5a5a;">
                      <label for="temp">Kiln Inlet CO</label>
                      <div *ngIf="Realtimedata.length !=0 ">
                        <label class="per" *ngIf="vval.pc_coal < 0 ">0.00</label>
                        <label class="per" *ngIf="vval.pc_coal >=  0 "> -->
    <!-- {{vval.pc_coal}} -->
    <!-- </label>
                      </div>
                      <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                    </div>
                  </div> -->
    <!-- <div class="col-md-4">
                    <div class="red" style="border-radius: 6px;
                  box-shadow: inset 7px 7px 14px black,
                              inset -7px -7px 14px #5a5a5a;">
                      <label for="temp">Main Coal (TPH)</label>
                      <div *ngIf="Realtimedata.length !=0 ">
                        <label class="per" *ngIf="vval.main_coal_tph < 0 ">0.00</label>
                        <label class="per" *ngIf="vval.main_coal_tph >=  0 ">{{vval.main_coal_tph}}</label>
                      </div>
                      <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                    </div>
                  </div> -->
    <!-- </div>
              </div>
            </div>
          </div> -->
    <button mat-button mat-dialog-close>Close</button>
  </div>

  <div class="col-md-12" *ngIf="this.data.clicked == 7">
    <div class="col-md-12">
      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <h2
          style="
            text-align: start;
            color: #fff;
            font-weight: bold;
            margin-bottom: 0px !important;
          "
        >
          Free Lime (%)
        </h2>
        <button mat-button mat-dialog-close (click)="closed()"> <mat-icon >close</mat-icon></button>
       
        </span
      >

      <!-- <span *ngIf="data.Freelimedata.length == 0">
        <p
          style="
            /* line-height: 50px; */
            /* height: 20px; */
            /* border: 1px solid; */
            text-align: center;
            border-radius: 10px;
            /* width: 100px; */
            margin-bottom: 0px !important;
            color: orange;
            font-size: 12px;
            font-weight: bold;
          "
        >
          No Data
        </p>
      </span>
      <span *ngIf="data.Freelimedata.length != 0">
        <div id="freelime"></div
      ></span> -->
      <!-- <div id="proddiv10mnsReal"></div> -->
      <!-- <span *ngIf="ForecastmixgraphData.length != 0"> <div id="proddiv10mnsReal"></div> </span>
             <span *ngIf="ForecastmixgraphData.length == 0"> <p>No Data</p> </span> -->
             <div id="freelime"></div>
    </div>
    
  </div>


  <div class="col-md-12" *ngIf="this.data.clicked == 8">
    <div class="col-md-12">
      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <h2
          style="
            text-align: start;
            color: #fff;
            font-weight: bold;
            margin-bottom: 0px !important;
          "
        >
        Lime Staturation Factor Ratio
        </h2>

         <button mat-button mat-dialog-close (click)="closed()"> <mat-icon >close</mat-icon></button>
        </span
      >

      <!-- <span *ngIf="data.Freelimedata.length == 0">
        <p
          style="
            /* line-height: 50px; */
            /* height: 20px; */
            /* border: 1px solid; */
            text-align: center;
            border-radius: 10px;
            /* width: 100px; */
            margin-bottom: 0px !important;
            color: orange;
            font-size: 12px;
            font-weight: bold;
          "
        >
          No Data
        </p>
      </span>
      <span *ngIf="data.Freelimedata.length != 0">
        <div id="freelime"></div
      ></span> -->
      <!-- <div id="proddiv10mnsReal"></div> -->
      <!-- <span *ngIf="ForecastmixgraphData.length != 0"> <div id="proddiv10mnsReal"></div> </span>
             <span *ngIf="ForecastmixgraphData.length == 0"> <p>No Data</p> </span> -->
             <div id="lsfchart"></div>
    </div>
   
  </div>
  <div class="col-md-12" *ngIf="this.data.clicked == 9">
    <div class="col-md-12">
      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <h2
          style="
            text-align: start;
            color: #fff;
            font-weight: bold;
            margin-bottom: 0px !important;
          "
        >
        Silica Ratio
        </h2>

         <button mat-button mat-dialog-close (click)="closed()"> <mat-icon >close</mat-icon></button></span
      >

      <!-- <span *ngIf="data.Freelimedata.length == 0">
        <p
          style="
            /* line-height: 50px; */
            /* height: 20px; */
            /* border: 1px solid; */
            text-align: center;
            border-radius: 10px;
            /* width: 100px; */
            margin-bottom: 0px !important;
            color: orange;
            font-size: 12px;
            font-weight: bold;
          "
        >
          No Data
        </p>
      </span>
      <span *ngIf="data.Freelimedata.length != 0">
        <div id="freelime"></div
      ></span> -->
      <!-- <div id="proddiv10mnsReal"></div> -->
      <!-- <span *ngIf="ForecastmixgraphData.length != 0"> <div id="proddiv10mnsReal"></div> </span>
             <span *ngIf="ForecastmixgraphData.length == 0"> <p>No Data</p> </span> -->
             <div id="srchart"></div>
    </div>
  
  </div>
  <div class="col-md-12" *ngIf="this.data.clicked == 10">
    <div class="col-md-12">
      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <h2
          style="
            text-align: start;
            color: #fff;
            font-weight: bold;
            margin-bottom: 0px !important;
          "
        >
        Alumina Modulus Ratio
        </h2>

         <button mat-button mat-dialog-close (click)="closed()"> <mat-icon >close</mat-icon></button></span
      >

      <!-- <span *ngIf="data.Freelimedata.length == 0">
        <p
          style="
            /* line-height: 50px; */
            /* height: 20px; */
            /* border: 1px solid; */
            text-align: center;
            border-radius: 10px;
            /* width: 100px; */
            margin-bottom: 0px !important;
            color: orange;
            font-size: 12px;
            font-weight: bold;
          "
        >
          No Data
        </p>
      </span>
      <span *ngIf="data.Freelimedata.length != 0">
        <div id="freelime"></div
      ></span> -->
      <!-- <div id="proddiv10mnsReal"></div> -->
      <!-- <span *ngIf="ForecastmixgraphData.length != 0"> <div id="proddiv10mnsReal"></div> </span>
             <span *ngIf="ForecastmixgraphData.length == 0"> <p>No Data</p> </span> -->
             <div id="Afchart"></div>
    </div>
   
  </div>
  <div class="col-md-12" *ngIf="this.data.clicked == 11">
    <div class="col-md-12">
      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <h2
          style="
            text-align: start;
            color: #fff;
            font-weight: bold;
            margin-bottom: 0px !important;
          "
        >
          Free Lime (%)
        </h2>

         <button mat-button mat-dialog-close (click)="closed()"> <mat-icon >close</mat-icon></button></span
      >

      <!-- <span *ngIf="data.Freelimedata.length == 0">
        <p
          style="
            /* line-height: 50px; */
            /* height: 20px; */
            /* border: 1px solid; */
            text-align: center;
            border-radius: 10px;
            /* width: 100px; */
            margin-bottom: 0px !important;
            color: orange;
            font-size: 12px;
            font-weight: bold;
          "
        >
          No Data
        </p>
      </span>
      <span *ngIf="data.Freelimedata.length != 0">
        <div id="freelime"></div
      ></span> -->
      <!-- <div id="proddiv10mnsReal"></div> -->
      <!-- <span *ngIf="ForecastmixgraphData.length != 0"> <div id="proddiv10mnsReal"></div> </span>
             <span *ngIf="ForecastmixgraphData.length == 0"> <p>No Data</p> </span> -->
             <div id="freelimekiln"></div>
    </div>
    
  </div>
  <div class="col-md-12" *ngIf="this.data.clicked == 12">
    <div class="col-md-12">
      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <h2
          style="
            text-align: start;
            color: #fff;
            font-weight: bold;
            margin-bottom: 0px !important;
          "
        >
        Burning Zone Temp (°C)
        </h2>

         <button mat-button mat-dialog-close (click)="closed()"> <mat-icon >close</mat-icon></button></span
      >

      <!-- <span *ngIf="data.Freelimedata.length == 0">
        <p
          style="
            /* line-height: 50px; */
            /* height: 20px; */
            /* border: 1px solid; */
            text-align: center;
            border-radius: 10px;
            /* width: 100px; */
            margin-bottom: 0px !important;
            color: orange;
            font-size: 12px;
            font-weight: bold;
          "
        >
          No Data
        </p>
      </span>
      <span *ngIf="data.Freelimedata.length != 0">
        <div id="freelime"></div
      ></span> -->
      <!-- <div id="proddiv10mnsReal"></div> -->
      <!-- <span *ngIf="ForecastmixgraphData.length != 0"> <div id="proddiv10mnsReal"></div> </span>
             <span *ngIf="ForecastmixgraphData.length == 0"> <p>No Data</p> </span> -->
             <div id="BurningChart"></div>
    </div>
  
  </div>
  <div class="col-md-12" *ngIf="this.data.clicked == 13">
    <div class="col-md-12">
      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <h2
          style="
            text-align: start;
            color: #fff;
            font-weight: bold;
            margin-bottom: 0px !important;
          "
        >
          Kiln Inlet O2 (%)
        </h2>

         <button mat-button mat-dialog-close (click)="closed()"> <mat-icon >close</mat-icon></button></span
      >

      <!-- <span *ngIf="data.Freelimedata.length == 0">
        <p
          style="
            /* line-height: 50px; */
            /* height: 20px; */
            /* border: 1px solid; */
            text-align: center;
            border-radius: 10px;
            /* width: 100px; */
            margin-bottom: 0px !important;
            color: orange;
            font-size: 12px;
            font-weight: bold;
          "
        >
          No Data
        </p>
      </span>
      <span *ngIf="data.Freelimedata.length != 0">
        <div id="freelime"></div
      ></span> -->
      <!-- <div id="proddiv10mnsReal"></div> -->
      <!-- <span *ngIf="ForecastmixgraphData.length != 0"> <div id="proddiv10mnsReal"></div> </span>
             <span *ngIf="ForecastmixgraphData.length == 0"> <p>No Data</p> </span> -->
             <div id="inleto2chart"></div>
    </div>
   
  </div>
  <div class="col-md-12" *ngIf="this.data.clicked == 14">
    <div class="col-md-12">
      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <h2
          style="
            text-align: start;
            color: #fff;
            font-weight: bold;
            margin-bottom: 0px !important;
          "
        >
          Kiln Inlet CO (%)
        </h2>

         <button mat-button mat-dialog-close (click)="closed()"> <mat-icon >close</mat-icon></button></span
      >

      <!-- <span *ngIf="data.Freelimedata.length == 0">
        <p
          style="
            /* line-height: 50px; */
            /* height: 20px; */
            /* border: 1px solid; */
            text-align: center;
            border-radius: 10px;
            /* width: 100px; */
            margin-bottom: 0px !important;
            color: orange;
            font-size: 12px;
            font-weight: bold;
          "
        >
          No Data
        </p>
      </span>
      <span *ngIf="data.Freelimedata.length != 0">
        <div id="freelime"></div
      ></span> -->
      <!-- <div id="proddiv10mnsReal"></div> -->
      <!-- <span *ngIf="ForecastmixgraphData.length != 0"> <div id="proddiv10mnsReal"></div> </span>
             <span *ngIf="ForecastmixgraphData.length == 0"> <p>No Data</p> </span> -->
             <div id="inletcochart"></div>
    </div>
   
  </div>
  <div class="col-md-12" *ngIf="this.data.clicked == 15">
    <div class="col-md-12">
      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <h2
          style="
            text-align: start;
            color: #fff;
            font-weight: bold;
            margin-bottom: 0px !important;
          "
        >
          Main Coal (TPH)
        </h2>

         <button mat-button mat-dialog-close (click)="closed()"> <mat-icon >close</mat-icon></button></span
      >

      <!-- <span *ngIf="data.Freelimedata.length == 0">
        <p
          style="
            /* line-height: 50px; */
            /* height: 20px; */
            /* border: 1px solid; */
            text-align: center;
            border-radius: 10px;
            /* width: 100px; */
            margin-bottom: 0px !important;
            color: orange;
            font-size: 12px;
            font-weight: bold;
          "
        >
          No Data
        </p>
      </span>
      <span *ngIf="data.Freelimedata.length != 0">
        <div id="freelime"></div
      ></span> -->
      <!-- <div id="proddiv10mnsReal"></div> -->
      <!-- <span *ngIf="ForecastmixgraphData.length != 0"> <div id="proddiv10mnsReal"></div> </span>
             <span *ngIf="ForecastmixgraphData.length == 0"> <p>No Data</p> </span> -->
             <div id="MCchart"></div>
    </div>
  
  </div>
  <div class="col-md-12" *ngIf="this.data.clicked == 16">
    <div class="col-md-12">
      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <h2
          style="
            text-align: start;
            color: #fff;
            font-weight: bold;
            margin-bottom: 0px !important;
          "
        >
          Kiln Speed(RPM)
        </h2>

         <button mat-button mat-dialog-close (click)="closed()"> <mat-icon >close</mat-icon></button></span
      >

      <!-- <span *ngIf="data.Freelimedata.length == 0">
        <p
          style="
            /* line-height: 50px; */
            /* height: 20px; */
            /* border: 1px solid; */
            text-align: center;
            border-radius: 10px;
            /* width: 100px; */
            margin-bottom: 0px !important;
            color: orange;
            font-size: 12px;
            font-weight: bold;
          "
        >
          No Data
        </p>
      </span>
      <span *ngIf="data.Freelimedata.length != 0">
        <div id="freelime"></div
      ></span> -->
      <!-- <div id="proddiv10mnsReal"></div> -->
      <!-- <span *ngIf="ForecastmixgraphData.length != 0"> <div id="proddiv10mnsReal"></div> </span>
             <span *ngIf="ForecastmixgraphData.length == 0"> <p>No Data</p> </span> -->
             <div id="kschart"></div>
    </div>
   
  </div>
  <div class="col-md-12" *ngIf="this.data.clicked == 17">
    <div class="col-md-12">
      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <h2
          style="
            text-align: start;
            color: #fff;
            font-weight: bold;
            margin-bottom: 0px !important;
          "
        >
          PA Fan Speed(RPM)
        </h2>

         <button mat-button mat-dialog-close (click)="closed()"> <mat-icon >close</mat-icon></button></span
      >

      <!-- <span *ngIf="data.Freelimedata.length == 0">
        <p
          style="
            /* line-height: 50px; */
            /* height: 20px; */
            /* border: 1px solid; */
            text-align: center;
            border-radius: 10px;
            /* width: 100px; */
            margin-bottom: 0px !important;
            color: orange;
            font-size: 12px;
            font-weight: bold;
          "
        >
          No Data
        </p>
      </span>
      <span *ngIf="data.Freelimedata.length != 0">
        <div id="freelime"></div
      ></span> -->
      <!-- <div id="proddiv10mnsReal"></div> -->
      <!-- <span *ngIf="ForecastmixgraphData.length != 0"> <div id="proddiv10mnsReal"></div> </span>
             <span *ngIf="ForecastmixgraphData.length == 0"> <p>No Data</p> </span> -->
             <div id="PAchart"></div>
    </div>
    
  </div>
</mat-dialog-content>
