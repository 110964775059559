<section>
  <div class="mainGridRgt11"
  style="display:flex;flex-direction:row;justify-content:space-between;color: white; border-bottom: 2px solid;">
      <div class="asImgBoxTop pt-2 pb-2">
        <div class="asImgBoxTopTxt">AFR Injection -Critical
          Process Controls</div>
      </div>
      <div style="display: flex">
        <div class="mr-2 date-picker">
          <mat-form-field class="padding-test" appearance="outline">
            <mat-label style="color: white">Date</mat-label>
            <input style="color: white; font-size: 15px" matInput [min]="minDate" [max]="maxDate"
              [matDatepicker]="startdate" placeholder="Choose Start Date" [(ngModel)]="appliedDateFilters.start_date"
              [disabled]="false" />
            <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
            <mat-datepicker #startdate></mat-datepicker>
          </mat-form-field>
        </div>
        <button title="Select the date and click." style="
           font-size: 16px;
      width: 70px;
      cursor: pointer;
      background-color: #302c2c;
      outline: unset;
      /* margin-left: 4%; */
      /* padding-top: 2px; */
      border: 1px solid rgba(255, 255, 255, 0.38);
      height: 35px;border-radius: 0.9375em !important;
      " mat-raised-button color="primary" class="get-sm-btn" (click)="changeDate()">
          Apply
        </button>
      </div> 
    </div>
    <div *ngIf="!isLoading" style=" font-size: 13px;flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    height: 560px;">
      <!-- [ngStyle]="{'color': checkstatus == 'Shutdown' ? '#FD1C03' : '#39FF14'}" -->
      <!-- <div class="row" style="padding: 1%;margin-top: 2%;">
        <div class="col-md-6">
          <div class="red" *ngFor="let vval of Coaldata  ">
            <label for="temp">Daily Average Coal Reduced (%)</label>
            <div *ngIf="vval.daily_coal_per">
              <label class="per">{{vval.daily_coal_per}}</label>&nbsp;&nbsp;
              <img src="../../../../assets/images/Redarrow.gif" style="width: 30px;" />
            </div>
            <div *ngIf="!vval.daily_coal_per"><label class="per">No data</label></div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="red" *ngFor="let vval of AFRdata1  ">
            <label for="temp">Daily Average AFR Increased (%)</label>
            <div *ngIf="vval.daily_afr_per">
              <label class="per">{{vval.daily_afr_per}}</label>&nbsp;&nbsp;
               <img src="../../../../assets/images/GreenArrow.gif" style="width: 30px;" />
            </div>
            <div *ngIf="!vval.daily_afr_per"><label class="per">No data</label></div>
          </div>
        </div>
      </div> -->
      <!-- <hr style="    margin-top: 10px !important;
      margin-bottom: 10px !important;
  "> -->
      <div class="red1">
        <div class="row" style="padding-left: 1%;
            padding-right: 1%;">
          <div class="col-md-12">

            <div style="display:flex ;flex-direction: row;justify-content: space-between;padding: 1%;">
              <p class="mainGridtitle1">RealTime Parameters </p>
              <span style=" color: #fff;">
                <tr *ngFor="let vval of Realtimedata  ">
                  <!-- <td>{{vval.obs_time | date:'dd/MM/yyyy, HH:mm:ss'}}                 
                        </td> -->
                  <td>Time -&nbsp;{{vval.dateandtime | date:'HH:mm'}}
                  </td>
                </tr>
              </span>
            </div>
            <!-- <hr> -->

            <div class="row" style="padding: 1%;">
              <div class="col-md-4">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">Cyc-6 Avg Temp (°C)</label>
                  <span *ngIf="Realtimedata.length != 0">
                  <div *ngFor ="let vval of Realtimedata">
                    <label class="per" *ngIf="vval.cyc_6_avg_temp < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.cyc_6_avg_temp >=  0 ">{{vval.cyc_6_avg_temp}}</label>
                  </div>
                </span>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">Cyc-6 Outlet CO (PPM)</label>
                  <span *ngIf="Realtimedata.length != 0">
                  <div *ngFor ="let vval of Realtimedata">
                    <label class="per" *ngIf="vval.cyc_6_outlet_co < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.cyc_6_outlet_co >=  0 ">{{vval.cyc_6_outlet_co}}</label>
                  </div>
                </span>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">Kiln Feed (TPH)</label>
                  <span *ngIf="Realtimedata.length != 0">
                  <div *ngFor ="let vval of Realtimedata">
                    <label class="per" *ngIf="vval.kiln_feed < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.kiln_feed >=  0 ">{{vval.kiln_feed}}</label>
                  </div>
                </span>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div>
            </div>
            <div class="row" style="padding: 1%;">


              <div class="col-md-4">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">AFR Flowrate (M<sup>3</sup>/Hr)</label>
                  <span *ngIf="Realtimedata.length != 0">
                  <div *ngFor ="let vval of Realtimedata">
                    <label class="per" *ngIf="vval.liquid_afr_flow_rate < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.liquid_afr_flow_rate >=  0 ">{{vval.liquid_afr_flow_rate}}</label>
                  </div>
                </span>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">PC Coal (TPH)</label>
                  <span *ngIf="Realtimedata.length != 0">
                  <div *ngFor ="let vval of Realtimedata">
                    <label class="per" *ngIf="vval.pc_coal < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.pc_coal >=  0 ">{{vval.pc_coal}}</label>
                  </div>
                </span>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                  <label for="temp">Main Coal (TPH)</label>
                  <span *ngIf="Realtimedata.length != 0">
                  <div *ngFor ="let vval of Realtimedata">
                    <label class="per" *ngIf="vval.main_coal_tph < 0 ">0.00</label>
                    <label class="per" *ngIf="vval.main_coal_tph >=  0 ">{{vval.main_coal_tph}}</label>
                  </div>
                </span>
                  <div *ngIf="Realtimedata.length ==0 || null "><label class="per">No data</label></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <hr style="    margin-top: 10px !important;
      margin-bottom: 7px !important;
  "> -->
      <div class="red1">
        <div style="display:flex ;flex-direction: row;justify-content: space-between;">
          <p class="mainGridtitle1">Forecasting Values </p>
          <span style=" color: #fff;">
            <tr *ngFor="let vval of Forecastdata  ">
              <!-- <td>{{vval.obs_time | date:'dd/MM/yyyy, HH:mm:ss'}}                 
                </td> -->
              <td>Time -&nbsp;{{vval.date_time | date:'HH:mm'}}
              </td>
            </tr>
          </span>
        </div>

        <div class="row" style="padding: 1%;">
          <div class="col-md-6">
            <div class="red" style="border-radius: 6px;
        box-shadow: inset 7px 7px 14px black,
                    inset -7px -7px 14px #5a5a5a;">
              <label for="temp">Cyc-6 avg Temp (°C)</label>
              <span *ngIf="Forecastdata.length != 0">
              <div *ngFor="let vval of Forecastdata  ">
                <label class="per" *ngIf="vval.forecast_cyc_6_avg_temp < 0 ">0.00</label>
                <label class="per" *ngIf="vval.forecast_cyc_6_avg_temp >= 0 ">{{vval.forecast_cyc_6_avg_temp}}</label>
              </div>
            </span>
              <!-- <div *ngIf="ForecastdatatempAvg == null"><label class="per">#</label></div> -->
              <div *ngIf="Forecastdata.length ==0 || null "><label class="per">No data</label></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="red" style="border-radius: 6px;
        box-shadow: inset 7px 7px 14px black,
                    inset -7px -7px 14px #5a5a5a;">
              <label for="temp">Cyc-6 outlet CO (PPM)</label>
              <span *ngIf="Forecastdata.length != 0">
              <div *ngFor="let vval of Forecastdata">
                <label class="per" *ngIf="vval.forecast_cyc_6_outlet_co < 0 ">0.00</label>
                <label class="per" *ngIf="vval.forecast_cyc_6_outlet_co >= 0 ">{{vval.forecast_cyc_6_outlet_co}}</label>
              </div>
            </span>
              <!-- <div *ngIf="Forecastdatatemp == null"><label class="per">#</label></div> -->
              <div *ngIf="Forecastdata.length ==0 || null"><label class="per">No data</label></div>
            </div>
          </div>


        </div>
      </div>

      <!-- <hr style="    margin-top: 10px !important;
      margin-bottom: 7px !important;
  "> -->
      <!-- <div class="red1">
        <div style="display:flex ;flex-direction: row;justify-content: center;">
          <p class="mainGridtitle1">RealTime Validation</p>
          <span style=" color: #fff;">
            <tr *ngFor="let vval of Forecastalertdata  ">
              <td>{{vval.obs_time | date:'dd/MM/yyyy, HH:mm:ss'}}                 
                    </td>
              <td>Time -&nbsp;{{vval.obs_time | date:'HH:mm'}}
              </td>
            </tr>
          </span>
        </div>
        <div>
          <div class="row" style="padding: 1%;">
            <div class="col-md-6">
              <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                <label for="temp"></label>
                <div>
                  <button class="btn1-button" (click)="openDialog()" style="font-size: 12px;  width:150px; background-color: #302C2C; outline: unset;
                border: 1px solid rgba(255, 255, 255, 0.38);">Cyc-6 avg Temp (°C)</button>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                <label for="temp"></label>
                <div>
                  <button class="btn1-button" (click)="openDialog1()" style="font-size: 12px;  width:180px; background-color: #302C2C; outline: unset;
                  border: 1px solid rgba(255, 255, 255, 0.38);">Cyc-6 outlet CO (PPM)</button>
                </div>
              </div>
            </div>
  
  
          </div>
        </div>
      </div> -->
      <!-- <div class="row" style="padding: 1%;">
        <div class="col-md-6">
          <div class="red" style="display: flex;flex-direction: row;justify-content: space-between;">
            <span  *ngFor="let vval of Forecastalertdata  " style="padding: 3%;">
              <label for="temp">Cyc-6 avg Temp (°C)</label>
              <div *ngIf="vval.forecast_cyc_6_avg_temp">
                <label class="per">{{vval.forecast_cyc_6_avg_temp}}</label>
   
              </div>
              <div *ngIf="!vval.forecast_cyc_6_avg_temp"><label class="per">No data</label></div>
            </span>
            <span *ngFor="let vval of Forecastalertdata  " style="padding: 3%;">
              <label for="temp">KPI</label>
              <div *ngIf="vval.forecast_cyc_6_avg_temp">
                <label class="per">{{vval.alert}} </label>
              </div>           
              <div *ngIf="!vval.forecast_cyc_6_avg_temp"><label class="per">No data</label></div>
            </span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="red" style="display: flex;flex-direction: row;justify-content: space-between;">
            <span  *ngFor="let vval of Forecastalertdata  "  style="padding: 3%;">
              <label for="temp">Cyc-6 outlet CO (PPM)</label>
              <div *ngIf="vval.forecast_cyc_6_outlet_co">
                <label class="per">{{vval.forecast_cyc_6_outlet_co}}</label>
              </div>
              <div *ngIf="!vval.forecast_cyc_6_outlet_co"><label class="per">No data</label></div>
            </span>
            <span *ngFor="let vval of Forecastalertdata  " style="padding: 3%;">
              <label for="temp">KPI</label>
              <div *ngIf="vval.forecast_cyc_6_outlet_co">
                <label class="per">{{vval.co_alert}}</label>
              </div>
              <div *ngIf="!vval.forecast_cyc_6_outlet_co"><label class="per">No data</label></div>
            </span>
          </div>

        </div>


       
      
      </div> -->

      <div style="display: flex;flex-direction:row;justify-content: space-between;padding: 1%;
            margin-top: 1%;
        ">
        <div>
          <span ><button mat-raised-button color="primary"
              (click)="onPreviousclick()"> Previous</button></span>
          <!-- <span *ngIf="this.selectedDatenext=='1660847400'">
            <button mat-raised-button disabled style="border: 1px solid #999999;
            background-color: #cccccc;
            color: #666666;" (click)="onPreviousclick()">
              Previous</button>
            </span> -->
        </div>
        <button mat-raised-button color="primary" (click)="onNextclick()"> Next</button>
      </div>
    </div>

    <div *ngIf="isLoading" style="height: 75vh; display: grid; width: 100%">
      <div style="margin: auto">
        <img
          src="../../../../assets/icons/Spinner.gif"
          style="width: 80px"
        />
        <p style="text-align: center;color: #fff;">Loading...</p>
      </div>
    </div>
</section>