<div class="container-view" style="margin-left: -20px; background-color: #f0fae2;">
    <div class="">
        <div class="row">
            <div class="col-md-8">
              <div class="info">
                    <div class="p-5">
                      <a target="_blank" href="https://livnsense.com/" title="LivNSense Technologies Pvt. Ltd">
                        <img class="logo img-fluid" src="assets\img\lns_logo.png"/>
                      </a>
                    </div>
            
                    <div class="py-3 mx-auto px-2 w-75 text-light bg-white text-center" style="opacity: 0.6; height: 6rem; background-color: cornsilk;">
                      <p class="text-maroon h1 font-weight-bold" style="padding-top: 10px;">Pyro 4.0 - <span style="font-size:20px">Powered  by</span>&nbsp;<img src="assets\img\greenOps.png" width="150px" alt=""> </p>
                      <!-- <p class="text-maroon font-weight-bold h5">Digital Production Optimization - Downstream</p> -->
                    </div>
                  </div>
            </div>
            <div class="col-md-4 bg-white">
                <section>
                    <form
                        class="form-signin"
                        [formGroup]="loginForm"
                        (ngSubmit)="login()"
                    >
  
                        <div class="input-group">
                        </div>
                        <div *ngIf="errMsg" style="color: red">
                            <i class="material-icons float-left mr-2">error</i>
                            <p class="font-weight-bold">{{ errMsg }}</p>
                        </div>
                        <div class="col-12 col-md py-1 text-left text-md-right" hidden="true">
                            <a
                                (click)="onForgot()"
                                [routerLink]="['/login']"
                                class="template-primary"
                                >Forgot Password?</a
                            >
                        </div>
                        <button
                            class="btn btn-lg btn-danger btn-block mt-2 text-white"
                            type="submit"
                            (click)="navHome()"
                            style="margin-top:43%"
                        >
                            Click to Proceed
                        </button>
                        <!-- <p class="h4 text-center text-secondary pt-4">
                            Powered by
                            <img
                                class="img-fluid"
                                style="width: 6rem"
                                src="assets/img/isense4i.png"
                                alt="isense4i logo"
                            />
                        </p> -->
                        <div class="text-right"></div>
                    </form>
                </section>
            </div>
        </div>
    </div>
  </div>
  