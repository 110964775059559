<app-navbar></app-navbar>
<!-- <app-navbar1
  *ngIf="
    SelectedTheme == 'Kiln 4.0' ||
    SelectedTheme == 'Kiln Optimization' ||
    SelectedTheme == 'Clinker Quality' ||
    SelectedTheme == 'SOP & Alerts'
  "
></app-navbar1> -->
<div
  class="row"
  style="
    padding-top: 3%;
    padding-right: 1.2%;
    max-height: 100px;
    padding-bottom: 10px;
  "
>
  <!-- <div style="display: flex; flex-direction: row; justify-content: right">
    <div class="date-picker">
      <mat-form-field class="padding-test" appearance="outline">
        <mat-label style="color: white">Date</mat-label>
        <input
          style="color: white; font-size: 15px"
          matInput
          [min]="minDate"
          [max]="maxDate"
          [matDatepicker]="startdate"
          placeholder="Choose Start Date"
          [(ngModel)]="appliedDateFilters.start_date"
          [disabled]="false"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startdate"
        ></mat-datepicker-toggle>
        <mat-datepicker #startdate></mat-datepicker>
      </mat-form-field>
    </div>
    <button
      title="Select the date and click."
      style="
        font-size: 16px;
        width: 110px;
        cursor: pointer;
        background-color: #302c2c;
        outline: unset;
        margin-left: 4%;
        padding-top: 2px;
        border: 1px solid rgba(255, 255, 255, 0.38);
        height: 35px;
      "
      mat-raised-button
      color="primary"
      class="get-sm-btn"
      (click)="changeDate()"
    >
      Apply
    </button>
  </div> -->
</div>
<!-- <main class="container-fluid"> -->
<div
  class="row"
  style="
    padding-top: 1.5%;
    padding-left: 1.2%;
    padding-right: 1.2%;
    max-height: 733px;
    overflow: auto;
  "
>
  <div
    class="col-md-2"
    style="
      background-image: linear-gradient(rgb(30, 30, 30), rgb(23, 23, 23));
      border: 1px solid #403c3c;
      min-height: 690px;
      max-height: max-content;
      box-shadow: 0 0 20px #000;
    "
  >
    <div *ngFor="let x of SelectTheme">
      <div
        class="radio"
        style="
          border: 1px solid rgba(255, 255, 255, 0.38);
          border-radius: 6px;
          box-shadow: 5px 5px 14px #3f3f3f, -5px -5px 14px black;
        "
        [ngClass]="SelectedTheme == x ? 'btn-th-active' : 'btn-th'"
      >
        <label
          ><input
            type="radio"
            class="radio"
            [(ngModel)]="SelectedTheme"
            value="{{ x }}"
            (change)="onItemChange($event)"
          /><span>{{ x }}</span></label
        >
      </div>
    </div>

    <!-- <div id="div_refresh" *ngIf="isValid">
                <a href={{lnsURL}}>
                    <span (click)="cleanUp()"><span style="color: white;">BackButton</span></span>
                </a>
            </div> -->
  </div>
  <!-- <div></div> -->
  <div class="col-md-5 h-100">
    <div
      style="
        background-image: linear-gradient(rgb(30, 30, 30), rgb(23, 23, 23));
        border: 1px solid #403c3c;
        min-height: 690px;
        max-height: max-content;
        box-shadow: 0 0 20px #000;
      "
    >
      <div class="asImgBoxTop pt-2">
        <div class="asImgBoxTopTxt">Pyro Process Twin</div>
      </div>
      <div class="row" style="margin: unset; padding: 1%">
        <div class="col-md-12" style="padding-left: unset">
          <button (click)="toggleImage(1)" class="image-toggle">
            <i class="bi bi-card-image"></i> Pyro Process Flow
          </button>
          <!-- <button (click)="toggleImage(2)" class="image-toggle"><i class="bi bi-card-image"></i>
                            Cyclones</button> -->
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" style="display: grid" *ngIf="imageToggle == 1">
          <img
            class="img-fluid"
            src="assets\img\cement_nw.svg"
            alt="Pyro Process flow"
            style="width: 495px; margin: auto"
          />
        </div>
      </div>
      <div style="height: 512px; display: grid" *ngIf="imageToggle == 2">
        <img
          class="img-fluid"
          src="assets\img\V2.png"
          style="margin: auto; height: 500px; padding: 1%"
          alt="Pyro Process"
        />
      </div>
      <!-- <div style="width: 100%;padding-top: 3%;">
                    <img class="img-fluid" src="assets\img\cement_img.svg" alt="AluminumSmeltingGraphic"
                        style="width:100%; margin:auto; height: 542px;">
                </div> -->
    </div>
  </div>
  <!-- <div></div> -->
  <div
    class="col-md-5 h-100"
    style="
      background-image: linear-gradient(rgb(30, 30, 30), rgb(23, 23, 23));
      border: 1px solid #403c3c;
      min-height: 690px;
      max-height: max-content;
      padding-top: 1rem;
      box-shadow: 0 0 20px #000;
    "
  >
    <!-- Theme 2 -->
    <div *ngIf="SelectedTheme == 'Dashboard'">
      <app-theme1></app-theme1>
    </div>

    <!-- Theme 1 -->
    <!-- <div *ngIf="SelectedTheme == 'KPI Validation'">
      <app-theme9></app-theme9>
    </div> -->

    <!-- Theme 4  -->
    <div *ngIf="SelectedTheme == 'AFR 4.0'">
      <app-theme2 *ngIf="this.dataToggle == 1"></app-theme2>
      <app-theme4 *ngIf="this.dataToggle == 2"></app-theme4>
      <button class="btn1-button"
        style="
          font-size: 12px;
          width: 100px;
          background-color: #302c2c;
          outline: unset;
          float: right;
          border: 1px solid rgba(255, 255, 255, 0.38);
        "
        (click)="onItemChangecontent($event)"
        *ngIf="this.dataToggle == 1"
      >
        Switch
      </button>
      <button class="btn1-button"
        style="
          font-size: 12px;
          width: 120px;
          background-color: #302c2c;
          outline: unset;
          float: right;
          border: 1px solid rgba(255, 255, 255, 0.38);
        "
        (click)="onItemChangeconten1t($event)"
        *ngIf="this.dataToggle == 2"
      >
        Switch Back
      </button>
    </div>
    <div *ngIf="SelectedTheme == 'SOP & Alerts'">
      <app-theme10></app-theme10>
    </div>
    <!-- Theme 3  -->
    <div *ngIf="SelectedTheme == 'CO Savings'">
      <app-theme3></app-theme3>
    </div>
    <!-- Theme 5 -->
    <div *ngIf="SelectedTheme == 'File Upload'">
      <app-theme5></app-theme5>
    </div>
    <div *ngIf="SelectedTheme == 'User Management'">
      <app-theme6></app-theme6>
    </div>
    <!-- Theme 7 -->
    <div *ngIf="SelectedTheme == 'Clinker Quality'">
      <app-theme7></app-theme7>
    </div>
    <div *ngIf="SelectedTheme == 'Kiln 4.0'">
      <app-theme8  *ngIf="this.kilnToggle == 1"></app-theme8>
      <app-theme9  *ngIf="this.kilnToggle == 2"></app-theme9>
      <button class="btn1-button"
      style="
        font-size: 12px;
        width: 100px;
        background-color: #302c2c;
        outline: unset;
        float: right;
        border: 1px solid rgba(255, 255, 255, 0.38);
      "
      (click)="onItemChangecontentkiln($event)"
      *ngIf="this.kilnToggle == 1"
    >
      Switch
    </button>
    <button class="btn1-button"
      style="
        font-size: 12px;
        width: 120px;
        background-color: #302c2c;
        outline: unset;
        float: right;
        border: 1px solid rgba(255, 255, 255, 0.38);
      "
      (click)="onItemChangeconten1tkiln($event)"
      *ngIf="this.kilnToggle == 2"
    >
      Switch Back
    </button>
    </div>
  </div>
</div>
<!-- </main> -->
<app-footer></app-footer>
