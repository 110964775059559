import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { APiService } from "src/app/core/balco.service";
import { Theme6Component } from "src/app/pages/components/theme6/theme6.component";

import { Theme7Component } from "src/app/pages/components/theme7/theme7.component";
import { Theme8Component } from "src/app/pages/components/theme8/theme8.component";
import { Theme9Component } from "src/app/pages/components/theme9/theme9.component";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { PlotChartsService } from "src/app/core/charts/plot-charts.service";

@Component({
  selector: "app-dialogbox",
  templateUrl: "./dialogbox.component.html",
  styleUrls: ["./dialogbox.component.scss"],
  providers: [Theme6Component,Theme7Component,Theme8Component,Theme9Component],
})
export class DialogboxComponent implements OnInit {
  tabdata: any[];
  tabdata1: any[];
  tabdata2: any[];
  tabdata3: any[];
  tabdata4: any[];
  tabdata5: any[];
  graphValuefc1: any = [];
  startdatef: any;
  appliedDateFilters: any = {};
  tabVal;
  click1: any;
  click2: any;
  UserData: any;
  userForm: FormGroup;
  edituserForm: FormGroup;
  today: number = Date.now();
  maxDate: Date = new Date('06/22/2023');
  minDate: Date = new Date("03/01/2023");
  Freelimedata: any;
  startDate: number;
  LSFdata: any;
  SRdata: any;
  AFdata: any[];
  Freelimekilndata: any;
  Burningdata: any;
  Inleto2data: any;
  InletCOdata: any;
  MCdata: any;
  KSdata: any;
  PAdata: any;

  constructor(
    public dialogRef: MatDialogRef<DialogboxComponent>,
    private ApicallService: APiService,
    private theme6: Theme6Component,
    private theme7: Theme7Component,
    private theme8: Theme8Component,
    private theme9: Theme9Component,
    private plotChartsService: PlotChartsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    // let dte = new Date();
    // this.appliedDateFilters["start_date"] = dte;
    // this.refreshUserList();
    // this.createUSERForm();
    // this.editUSERForm();

    if (this.data.clicked == 0) {
      this.tabdata = this.data.TableData;
    } else if (this.data.clicked == 1) {
      this.tabdata1 = this.data.TableData1;
    } else if (this.data.clicked == 2) {
      this.tabdata2 = this.data.TableData2;
    } else if (this.data.clicked == 3) {
      this.tabdata3 = this.data.TableData3;
    } else if (this.data.clicked == 4) {
      this.tabdata4 = this.data.TableData4;
      this.tabVal = this.data.tableval;
    } else if (this.data.clicked == 5) {
      // this.tabdata5 = this.data.TableData5;
      // this.tabVal = this.data.tableval;
      // console.log(this.tabdata5,this.tabVal)
    } else if (this.data.clicked == 6) {
      // this.tabdata5 = this.data.TableData5;
      // this.tabVal = this.data.tableval;
      // console.log(this.tabdata5,this.tabVal)
    } else if (this.data.clicked == 7) {
      this.getFLgraph();
      console.log(this.data.clicked, this.data.ApiDate, "click");
    } else if (this.data.clicked == 8) {
      this.getLsfgraph();
      console.log(this.data.clicked, this.data.ApiDate, "click");
    } else if (this.data.clicked == 9) {
      this.getSRchartgraph();
      console.log(this.data.clicked, this.data.ApiDate, "click");
    } else if (this.data.clicked == 10) {
      this.getAFchartgraph();
      console.log(this.data.clicked, this.data.ApiDate, "click");
    }
    else if (this.data.clicked == 11) {
      this.getKilnFLgraph();
      console.log(this.data.clicked, this.data.ApiDate, "click");
    } else if (this.data.clicked == 12) {
      this.getBurninggraph()
      console.log(this.data.clicked, this.data.ApiDate, "click");
    } else if (this.data.clicked == 13) {
      this.getInleto2graph();
      console.log(this.data.clicked, this.data.ApiDate, "click");
    } else if (this.data.clicked == 14) {
      this.getInletCOgraph();
      console.log(this.data.clicked, this.data.ApiDate, "click");
    }
    else if (this.data.clicked == 15) {
      this.getMCgraph();
      console.log(this.data.clicked, this.data.ApiDate, "click");
    } else if (this.data.clicked == 16) {
      this.getKSgraph();
      console.log(this.data.clicked, this.data.ApiDate, "click");
    } else if (this.data.clicked == 17) {
      this.getPAgraph();
      console.log(this.data.clicked, this.data.ApiDate, "click");
    }
  }
  closed() {
    this.dialogRef.close();
  }
  addUser() {
    let body = this.userForm["value"];
    this.ApicallService.registerUser(body).subscribe(
      (data) => {},
      (err) => {
        // // (err);
      }
    );
    this.theme6.refreshUserList();
  }
  refreshUserList() {
    this.ApicallService.getAllUsers().subscribe((data) => {
      this.UserData = data;
    });
  }

  deleteDriver(username) {
    // const body = {
    //   UserName: JSON.parse(localStorage.getItem("userDetails"))["name"],
    //   DeviceIMEI: this.selectedDriverToUpdate["DeviceIMEI"],
    // };
    this.ApicallService.deleteDriver(this.tabdata3).subscribe(
      (data) => {
        // this.users.splice(d_imei, 1);
      },
      (err) => {
        // // (err);
      }
    );
    this.theme6.refreshUserList();
  }
  updateUserDetails(this) {
    // const body = this.userForm["value"];
    const body = this.tabVal;
    console.log("body", body, this.tabVal);
    this.ApicallService.updateUserInfo(this.tabdata4, body).subscribe(
      (data) => {
        // (data);
      },
      (err) => {
        // (err);
      }
    );
    this.theme6.refreshUserList();
  }



  // Freelime --7
  getFLgraph() {
    this.startDate =
      new Date(
        new Date( this.data.ApiDate).setHours(0, 0, 0, 0)
      ).getTime() / 1000;
    this.plotChartsService.getFreelimeGraph(this.startDate).subscribe(
      (data: any) => {
        this.Freelimedata = data;
        this.drawFreelimeChart();
        console.log(
          this.Freelimedata,
          this.data.ApiDate,
          this.startDate,
          "Freelimedata"
        );
      },
      (err) => {
        err;
      }
    );
  }
  // LSF --8
  getLsfgraph(){
    this.startDate =
      new Date(
        new Date( this.data.ApiDate).setHours(0, 0, 0, 0)
      ).getTime() / 1000;
    this.plotChartsService.getLSFGraph(this.startDate).subscribe(
      (data: any) => {
        this.LSFdata = data;
        this.drawLSFChart();
        console.log(
          this.LSFdata,
          this.data.ApiDate,
          this.startDate,
          "LSFdata"
        );
      },
      (err) => {
        err;
      }
    );
  }
// SR --9
  getSRchartgraph(){
    this.startDate =
      new Date(
        new Date( this.data.ApiDate).setHours(0, 0, 0, 0)
      ).getTime() / 1000;
    this.plotChartsService.getSRGraph(this.startDate).subscribe(
      (data: any) => {
        this.SRdata = data;
        this.drawSRChart();
        console.log(
          this.SRdata,
          this.data.ApiDate,
          this.startDate,
          "SRdata"
        );
      },
      (err) => {
        err;
      }
    );
  }
// AF --10
  getAFchartgraph(){
    this.startDate =
      new Date(
        new Date( this.data.ApiDate).setHours(0, 0, 0, 0)
      ).getTime() / 1000;
    this.plotChartsService.getAFGraph(this.startDate).subscribe(
      (data: any) => {
        this.AFdata = data;
        this.drawAFChart();
        console.log(
          this.AFdata,
          this.data.ApiDate,
          this.startDate,
          "AFdata"
        );
      },
      (err) => {
        err;
      }
    );
  }

  // Kiln freelime --11
  getKilnFLgraph() {
    this.startDate =
      new Date(
        new Date( this.data.ApiDate).setHours(0, 0, 0, 0)
      ).getTime() / 1000;
    this.plotChartsService.getFreelimeKILNGraph(this.startDate).subscribe(
      (data: any) => {
        this.Freelimekilndata = data;
        this.drawFreelimeKILNChart();
        console.log(
          this.Freelimedata,
          this.data.ApiDate,
          this.startDate,
          "Freelimekilndata"
        );
      },
      (err) => {
        err;
      }
    );
  }

    // Kiln freelime --12
    getBurninggraph() {
      this.startDate =
        new Date(
          new Date( this.data.ApiDate).setHours(0, 0, 0, 0)
        ).getTime() / 1000;
      this.plotChartsService.getBurningGraph(this.startDate).subscribe(
        (data: any) => {
          this.Burningdata = data;
          this.drawBurningChart();
          console.log(
            this.Burningdata,
            this.data.ApiDate,
            this.startDate,
            "Burningdata"
          );
        },
        (err) => {
          err;
        }
      );
    }
      // Kiln inlet O2 --13
      getInleto2graph() {
        this.startDate =
          new Date(
            new Date( this.data.ApiDate).setHours(0, 0, 0, 0)
          ).getTime() / 1000;
        this.plotChartsService.getinleto2Graph(this.startDate).subscribe(
          (data: any) => {
            this.Inleto2data = data;
            this.drawkilnO2Chart();
            console.log(
              this.Inleto2data,
              this.data.ApiDate,
              this.startDate,
              "InletCOdata"
            );
          },
          (err) => {
            err;
          }
        );
      }
   // Kiln inlet CO --14
      getInletCOgraph() {
        this.startDate =
          new Date(
            new Date( this.data.ApiDate).setHours(0, 0, 0, 0)
          ).getTime() / 1000;
        this.plotChartsService.getinletCOGraph(this.startDate).subscribe(
          (data: any) => {
            this.InletCOdata = data;
            this.drawinletCOChart();
            console.log(
              this.InletCOdata,
              this.data.ApiDate,
              this.startDate,
              "InletCOdata"
            );
          },
          (err) => {
            err;
          }
        );
      }
//  Main Coal  --15
getMCgraph() {
  this.startDate =
    new Date(
      new Date( this.data.ApiDate).setHours(0, 0, 0, 0)
    ).getTime() / 1000;
  this.plotChartsService.getinletMCGraph(this.startDate).subscribe(
    (data: any) => {
      this.MCdata = data;
      this.drawMCchart();
      console.log(
        this.MCdata,
        this.data.ApiDate,
        this.startDate,
        "MCdata"
      );
    },
    (err) => {
      err;
    }
  );
}
// Kiln Speed --16
getKSgraph() {
  this.startDate =
    new Date(
      new Date( this.data.ApiDate).setHours(0, 0, 0, 0)
    ).getTime() / 1000;
  this.plotChartsService.getinletKSGraph(this.startDate).subscribe(
    (data: any) => {
      this.KSdata = data;
      this.drawKSchart();
      console.log(
        this.KSdata,
        this.data.ApiDate,
        this.startDate,
        "KSdata"
      );
    },
    (err) => {
      err;
    }
  );
}
// PA fan speed --17

getPAgraph() {
  this.startDate =
    new Date(
      new Date( this.data.ApiDate).setHours(0, 0, 0, 0)
    ).getTime() / 1000;
  this.plotChartsService.getinletPAGraph(this.startDate).subscribe(
    (data: any) => {
      this.PAdata = data;
      this.drawPAchart();
      console.log(
        this.PAdata,
        this.data.ApiDate,
        this.startDate,
        "PAdata"
      );
    },
    (err) => {
      err;
    }
  );
}


// Freelime Chart
  drawFreelimeChart(){
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("freelime", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      // chart.background.fill = am4core.color("#1e1e1e");

      // Add data
      chart.data = ref.Freelimedata;
      // chart.data = ref.graphValuefc1;
      chart.cursor = new am4charts.XYCursor();

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.title.text = "Time";
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.title.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.start = 0.9;
      categoryAxis.end = 1;
      // categoryAxis.keepSelection = true;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.zoomOutButton.disabled = true;
      chart.numberFormatter.numberFormat = "#.00";
      // chart.responsive.enabled = true;
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = " Free Lime (%)";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = "bold";
      // valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");

      // let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      // // valueAxis.min = 870;
      // valueAxis1.title.text = " Temperature";
      // valueAxis1.title.fill = am4core.color("#FFF");
      // valueAxis1.title.fontWeight = 'bold';
      // // valueAxis.title.fontWeight = 'bold';
      // valueAxis1.title.fontSize = 12;
      // valueAxis1.renderer.labels.template.fontSize = 12;
      // valueAxis1.renderer.labels.template.fill = am4core.color('#fff');

    
      // valueAxis1.syncWithAxis = valueAxis;

      // Create series

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(30);
      // series.clustered = false;
      // series.columns.template.dx = -10;
      series.dataFields.valueY = "free_lime";
      series.dataFields.categoryX = "obs_time";
      // series.strokeWidth = 5;
      series.stroke = am4core.color("#5ab033");
      series.fill = am4core.color("#5ab033");
      series.legendSettings.valueText = "free_lime [bold][/]";
      series.tooltipText = "Free Lime (%)" + `:{${"free_lime"}}`;
      // series.label.text ="{valueY}";

      let range1 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range1.dataFields.valueY = "upper_limit";
      range1.dataFields.categoryX = "obs_time";
      range1.strokeWidth = 3;
      range1.stroke = am4core.color("#fca503");
      range1.fill = am4core.color("#fca503");
      range1.legendSettings.valueText = "upper_limit [bold][/]";
      range1.tooltipText = "Upper Limit" + `:{${"upper_limit"}}`;
      // series.label.text ="{valueY}";

      let range2 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range2.dataFields.valueY = "lower_limit";
      range2.dataFields.categoryX = "obs_time";
      range2.strokeWidth = 3;
      range2.stroke = am4core.color("#03cffc");
      range2.fill = am4core.color("#03cffc");
      range2.propertyFields.strokeDasharray = "lineDash"
      range2.legendSettings.valueText = "lower_limit [bold][/]";
      range2.tooltipText = "Lower Limit" + `:{${"lower_limit"}}`;
      // series.label.text ="{valueY}";

      // let lineSeries = chart.series.push(new am4charts.LineSeries());
      // // lineSeries.name = "Incidents";
      // lineSeries.dataFields.valueY = "cc";
      // lineSeries.dataFields.dateX = "ts";
      // lineSeries.yAxis = categoryX;
      // lineSeries.stroke = am4core.color("#ee1b22");
      // lineSeries.strokeWidth = 3;
      // lineSeries.tensionX = 0.90;
      // lineSeries.propertyFields.strokeDasharray = "lineDash";
      // lineSeries.tooltip.label.textAlign = "middle";
      // lineSeries.legendSettings.valueText = "Incidents [bold {color}][/]";

      // let range = valueAxis.axisRanges.create();
      // range.value = ref.Freelimedata[0].upper_limit;
      // range.axisFill.tooltip = new am4core.Tooltip();

      // range.axisFill.tooltipText = 'Upper Limit:' +ref.Freelimedata[0].upper_limit;

      // range.axisFill.interactionsEnabled = true;

      // range.axisFill.isMeasured = true;
      // range.grid.stroke = am4core.color("#396478");
      // range.grid.strokeWidth = 2;
      // range.grid.strokeOpacity = 1;
      
      // let range2 = valueAxis.axisRanges.create();
      // range2.value = ref.Freelimedata[0].lower_limit;
      // range2.axisFill.tooltip = new am4core.Tooltip();

      // range2.axisFill.tooltipText = 'Lower Limit:' +ref.Freelimedata[0].lower_limit;

      // range2.axisFill.interactionsEnabled = true;

      // range2.axisFill.isMeasured = true;
      // range2.grid.stroke = am4core.color("#A96478");
      // range2.grid.strokeWidth = 2;
      // range2.grid.strokeOpacity = 1;

      // kiln_feed

     

      // let bullet1 = series.bullets.push(new am4charts.Bullet());
      // bullet1.fill = am4core.color("#ff9225");
      // let circleBullet1 = series.bullets.push(new am4charts.CircleBullet());
      // circleBullet1.circle.radius = 3;

      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "top";
      // chart.legend.labels.template.fill = am4core.color('#fff');
      // chart.legend.labels.template.fontWeight = 'bold';
      // chart.exporting.menu = new am4core.ExportMenu();
      // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
      // chart.exporting.formatOptions.getKey("json").disabled = true;
      // chart.exporting.formatOptions.getKey("html").disabled = true;
      // chart.exporting.formatOptions.getKey("jpg").disabled = true;
      // chart.exporting.formatOptions.getKey("png").disabled = true;
      // chart.exporting.formatOptions.getKey("svg").disabled = true;
      // chart.exporting.formatOptions.getKey("pdf").disabled = true;

      // chart.exporting.filePrefix = "Cyc_6_Avg_Temp";
      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
        am4core.color("rgb(64, 60, 60)");

      chart.tooltip.label.maxWidth = 100;
      chart.tooltip.label.wrap = true;

      // ref.loaded = true;
      // ref.isLoading=false;
    }, 100);
  }

// LSF chart
  drawLSFChart(){
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("lsfchart", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      // chart.background.fill = am4core.color("#1e1e1e");

      // Add data
      chart.data = ref.LSFdata;
      // chart.data = ref.graphValuefc1;
      chart.cursor = new am4charts.XYCursor();

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.title.text = "Time";
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.title.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.start = 0.9;
      categoryAxis.end = 1;
      // categoryAxis.keepSelection = true;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.zoomOutButton.disabled = true;
      chart.numberFormatter.numberFormat = "#.00";
      // chart.responsive.enabled = true;
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = "Lime Staturation Factor Ratio";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = "bold";
      // valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");

      // let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      // // valueAxis.min = 870;
      // valueAxis1.title.text = " Temperature";
      // valueAxis1.title.fill = am4core.color("#FFF");
      // valueAxis1.title.fontWeight = 'bold';
      // // valueAxis.title.fontWeight = 'bold';
      // valueAxis1.title.fontSize = 12;
      // valueAxis1.renderer.labels.template.fontSize = 12;
      // valueAxis1.renderer.labels.template.fill = am4core.color('#fff');

    
      // valueAxis1.syncWithAxis = valueAxis;

      // Create series

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(30);
      // series.clustered = false;
      // series.columns.template.dx = -10;
      series.dataFields.valueY = "lsf";
      series.dataFields.categoryX = "obs_time";
      // series.strokeWidth = 5;
      series.stroke = am4core.color("#5ab033");
      series.fill = am4core.color("#5ab033");
      series.legendSettings.valueText = "lsf [bold][/]";
      series.tooltipText = "Lime Staturation Factor Ratio" + `:{${"lsf"}}`;
      // series.label.text ="{valueY}";

      let range1 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range1.dataFields.valueY = "upper_limit";
      range1.dataFields.categoryX = "obs_time";
      range1.strokeWidth = 3;
      range1.stroke = am4core.color("#fca503");
      range1.fill = am4core.color("#fca503");
      range1.legendSettings.valueText = "upper_limit [bold][/]";
      range1.tooltipText = "Upper Limit" + `:{${"upper_limit"}}`;
      // series.label.text ="{valueY}";

      let range2 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range2.dataFields.valueY = "lower_limit";
      range2.dataFields.categoryX = "obs_time";
      range2.strokeWidth = 3;
      range2.stroke = am4core.color("#03cffc");
      range2.fill = am4core.color("#03cffc");
      range2.propertyFields.strokeDasharray = "lineDash"
      range2.legendSettings.valueText = "lower_limit [bold][/]";
      range2.tooltipText = "Lower Limit" + `:{${"lower_limit"}}`;
      // series.label.text ="{valueY}";

      // let range = valueAxis.axisRanges.create();
      // range.value = ref.LSFdata[0].upper_limit;
      // range.axisFill.tooltip = new am4core.Tooltip();

      // range.axisFill.tooltipText = 'Upper Limit:' +ref.LSFdata[0].upper_limit;

      // range.axisFill.interactionsEnabled = true;

      // range.axisFill.isMeasured = true;
      // range.grid.stroke = am4core.color("#396478");
      // range.grid.strokeWidth = 2;
      // range.grid.strokeOpacity = 1;
      
      // let range2 = valueAxis.axisRanges.create();
      // range2.value = ref.LSFdata[0].lower_limit;
      // range2.axisFill.tooltip = new am4core.Tooltip();

      // range2.axisFill.tooltipText = 'Lower Limit:' +ref.LSFdata[0].lower_limit;

      // range2.axisFill.interactionsEnabled = true;

      // range2.axisFill.isMeasured = true;
      // range2.grid.stroke = am4core.color("#A96478");
      // range2.grid.strokeWidth = 2;
      // range2.grid.strokeOpacity = 1;

      // kiln_feed

     

      // let bullet1 = series.bullets.push(new am4charts.Bullet());
      // bullet1.fill = am4core.color("#ff9225");
      // let circleBullet1 = series.bullets.push(new am4charts.CircleBullet());
      // circleBullet1.circle.radius = 3;

      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "top";
      // chart.legend.labels.template.fill = am4core.color('#fff');
      // chart.legend.labels.template.fontWeight = 'bold';
      // chart.exporting.menu = new am4core.ExportMenu();
      // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
      // chart.exporting.formatOptions.getKey("json").disabled = true;
      // chart.exporting.formatOptions.getKey("html").disabled = true;
      // chart.exporting.formatOptions.getKey("jpg").disabled = true;
      // chart.exporting.formatOptions.getKey("png").disabled = true;
      // chart.exporting.formatOptions.getKey("svg").disabled = true;
      // chart.exporting.formatOptions.getKey("pdf").disabled = true;

      // chart.exporting.filePrefix = "Cyc_6_Avg_Temp";
      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
        am4core.color("rgb(64, 60, 60)");

      chart.tooltip.label.maxWidth = 100;
      chart.tooltip.label.wrap = true;

      // ref.loaded = true;
      // ref.isLoading=false;
    }, 100);
  }

// SR Chart
  drawSRChart(){
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("srchart", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      // chart.background.fill = am4core.color("#1e1e1e");

      // Add data
      chart.data = ref.SRdata;
      // chart.data = ref.graphValuefc1;
      chart.cursor = new am4charts.XYCursor();

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.title.text = "Time";
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.title.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.start = 0.9;
      categoryAxis.end = 1;
      // categoryAxis.keepSelection = true;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.zoomOutButton.disabled = true;
      chart.numberFormatter.numberFormat = "#.00";
      // chart.responsive.enabled = true;
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = " Silica Ratio";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = "bold";
      // valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");

      // let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      // // valueAxis.min = 870;
      // valueAxis1.title.text = " Temperature";
      // valueAxis1.title.fill = am4core.color("#FFF");
      // valueAxis1.title.fontWeight = 'bold';
      // // valueAxis.title.fontWeight = 'bold';
      // valueAxis1.title.fontSize = 12;
      // valueAxis1.renderer.labels.template.fontSize = 12;
      // valueAxis1.renderer.labels.template.fill = am4core.color('#fff');

    
      // valueAxis1.syncWithAxis = valueAxis;

      // Create series

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(30);
      // series.clustered = false;
      // series.columns.template.dx = -10;
      series.dataFields.valueY = "sr";
      series.dataFields.categoryX = "obs_time";
      // series.strokeWidth = 5;
      series.stroke = am4core.color("#5ab033");
      series.fill = am4core.color("#5ab033");
      series.legendSettings.valueText = "sr [bold][/]";
      series.tooltipText = "Silica Ratio" + `:{${"sr"}}`;
      // series.label.text ="{valueY}";

      let range1 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range1.dataFields.valueY = "upper_limit";
      range1.dataFields.categoryX = "obs_time";
      range1.strokeWidth = 3;
      range1.stroke = am4core.color("#fca503");
      range1.fill = am4core.color("#fca503");
      range1.legendSettings.valueText = "upper_limit [bold][/]";
      range1.tooltipText = "Upper Limit" + `:{${"upper_limit"}}`;
      // series.label.text ="{valueY}";

      let range2 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range2.dataFields.valueY = "lower_limit";
      range2.dataFields.categoryX = "obs_time";
      range2.strokeWidth = 5;
      range2.stroke = am4core.color("#03cffc");
      range2.fill = am4core.color("#03cffc");
      range2.propertyFields.strokeDasharray = "lineDash"
      range2.legendSettings.valueText = "lower_limit [bold][/]";
      range2.tooltipText = "Lower Limit" + `:{${"lower_limit"}}`;
      // series.label.text ="{valueY}";

      // let range = valueAxis.axisRanges.create();
      // range.value = ref.SRdata[0].upper_limit;
      // range.axisFill.tooltip = new am4core.Tooltip();

      // range.axisFill.tooltipText = 'Upper Limit:' +ref.SRdata[0].upper_limit;

      // range.axisFill.interactionsEnabled = true;

      // range.axisFill.isMeasured = true;
      // range.grid.stroke = am4core.color("#396478");
      // range.grid.strokeWidth = 2;
      // range.grid.strokeOpacity = 1;
      
      // let range2 = valueAxis.axisRanges.create();
      // range2.value = ref.SRdata[0].lower_limit;
      // range2.axisFill.tooltip = new am4core.Tooltip();

      // range2.axisFill.tooltipText = 'Lower Limit:' +ref.SRdata[0].lower_limit;

      // range2.axisFill.interactionsEnabled = true;

      // range2.axisFill.isMeasured = true;
      // range2.grid.stroke = am4core.color("#A96478");
      // range2.grid.strokeWidth = 2;
      // range2.grid.strokeOpacity = 1;

      // kiln_feed

     

      // let bullet1 = series.bullets.push(new am4charts.Bullet());
      // bullet1.fill = am4core.color("#ff9225");
      // let circleBullet1 = series.bullets.push(new am4charts.CircleBullet());
      // circleBullet1.circle.radius = 3;

      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "top";
      // chart.legend.labels.template.fill = am4core.color('#fff');
      // chart.legend.labels.template.fontWeight = 'bold';
      // chart.exporting.menu = new am4core.ExportMenu();
      // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
      // chart.exporting.formatOptions.getKey("json").disabled = true;
      // chart.exporting.formatOptions.getKey("html").disabled = true;
      // chart.exporting.formatOptions.getKey("jpg").disabled = true;
      // chart.exporting.formatOptions.getKey("png").disabled = true;
      // chart.exporting.formatOptions.getKey("svg").disabled = true;
      // chart.exporting.formatOptions.getKey("pdf").disabled = true;

      // chart.exporting.filePrefix = "Cyc_6_Avg_Temp";
      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
        am4core.color("rgb(64, 60, 60)");

      chart.tooltip.label.maxWidth = 100;
      chart.tooltip.label.wrap = true;

      // ref.loaded = true;
      // ref.isLoading=false;
    }, 100);
  }

  // AF Chart
  drawAFChart(){
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("Afchart", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      // chart.background.fill = am4core.color("#1e1e1e");

      // Add data
      chart.data = ref.AFdata;
      // chart.data = ref.graphValuefc1;
      chart.cursor = new am4charts.XYCursor();

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.title.text = "Time";
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.title.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.start = 0.9;
      categoryAxis.end = 1;
      // categoryAxis.keepSelection = true;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.zoomOutButton.disabled = true;
      chart.numberFormatter.numberFormat = "#.00";
      // chart.responsive.enabled = true;
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = " Alumina Modulus Ratio";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = "bold";
      // valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");

      // let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      // // valueAxis.min = 870;
      // valueAxis1.title.text = " Temperature";
      // valueAxis1.title.fill = am4core.color("#FFF");
      // valueAxis1.title.fontWeight = 'bold';
      // // valueAxis.title.fontWeight = 'bold';
      // valueAxis1.title.fontSize = 12;
      // valueAxis1.renderer.labels.template.fontSize = 12;
      // valueAxis1.renderer.labels.template.fill = am4core.color('#fff');

    
      // valueAxis1.syncWithAxis = valueAxis;

      // Create series

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(30);
      // series.clustered = false;
      // series.columns.template.dx = -10;
      series.dataFields.valueY = "af";
      series.dataFields.categoryX = "obs_time";
      // series.strokeWidth = 5;
      series.stroke = am4core.color("#5ab033");
      series.fill = am4core.color("#5ab033");
      series.legendSettings.valueText = "af [bold][/]";
      series.tooltipText = "Alumina Modulus Ratio" + `:{${"af"}}`;
      // series.label.text ="{valueY}";

      let range1 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range1.dataFields.valueY = "upper_limit";
      range1.dataFields.categoryX = "obs_time";
      range1.strokeWidth = 3;
      range1.stroke = am4core.color("#fca503");
      range1.fill = am4core.color("#fca503");
      range1.legendSettings.valueText = "upper_limit [bold][/]";
      range1.tooltipText = "Upper Limit" + `:{${"upper_limit"}}`;
      // series.label.text ="{valueY}";

      let range2 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range2.dataFields.valueY = "lower_limit";
      range2.dataFields.categoryX = "obs_time";
      range2.strokeWidth = 3;
      range2.stroke = am4core.color("#03cffc");
      range2.fill = am4core.color("#03cffc");
      range2.propertyFields.strokeDasharray = "lineDash"
      range2.legendSettings.valueText = "lower_limit [bold][/]";
      range2.tooltipText = "Lower Limit" + `:{${"lower_limit"}}`;
      // series.label.text ="{valueY}";

      // let range = valueAxis.axisRanges.create();
      // range.value = ref.AFdata[0].upper_limit;
      // range.axisFill.tooltip = new am4core.Tooltip();

      // range.axisFill.tooltipText = 'Upper Limit:' +ref.AFdata[0].upper_limit;

      // range.axisFill.interactionsEnabled = true;

      // range.axisFill.isMeasured = true;
      // range.grid.stroke = am4core.color("#396478");
      // range.grid.strokeWidth = 2;
      // range.grid.strokeOpacity = 1;
      
      // let range2 = valueAxis.axisRanges.create();
      // range2.value = ref.AFdata[0].lower_limit;
      // range2.axisFill.tooltip = new am4core.Tooltip();

      // range2.axisFill.tooltipText = 'Lower Limit:' +ref.AFdata[0].lower_limit;

      // range2.axisFill.interactionsEnabled = true;

      // range2.axisFill.isMeasured = true;
      // range2.grid.stroke = am4core.color("#A96478");
      // range2.grid.strokeWidth = 2;
      // range2.grid.strokeOpacity = 1;

      // kiln_feed

     

      // let bullet1 = series.bullets.push(new am4charts.Bullet());
      // bullet1.fill = am4core.color("#ff9225");
      // let circleBullet1 = series.bullets.push(new am4charts.CircleBullet());
      // circleBullet1.circle.radius = 3;

      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "top";
      // chart.legend.labels.template.fill = am4core.color('#fff');
      // chart.legend.labels.template.fontWeight = 'bold';
      // chart.exporting.menu = new am4core.ExportMenu();
      // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
      // chart.exporting.formatOptions.getKey("json").disabled = true;
      // chart.exporting.formatOptions.getKey("html").disabled = true;
      // chart.exporting.formatOptions.getKey("jpg").disabled = true;
      // chart.exporting.formatOptions.getKey("png").disabled = true;
      // chart.exporting.formatOptions.getKey("svg").disabled = true;
      // chart.exporting.formatOptions.getKey("pdf").disabled = true;

      // chart.exporting.filePrefix = "Cyc_6_Avg_Temp";
      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
        am4core.color("rgb(64, 60, 60)");

      chart.tooltip.label.maxWidth = 100;
      chart.tooltip.label.wrap = true;

      // ref.loaded = true;
      // ref.isLoading=false;
    }, 100);
  }

  // FreelimeKIln chart
  drawFreelimeKILNChart(){
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("freelimekiln", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      // chart.background.fill = am4core.color("#1e1e1e");

      // Add data
      chart.data = ref.Freelimekilndata;
      // chart.data = ref.graphValuefc1;
      chart.cursor = new am4charts.XYCursor();

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.title.text = "Time";
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.title.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.start = 0.9;
      categoryAxis.end = 1;
      // categoryAxis.keepSelection = true;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.zoomOutButton.disabled = true;
      chart.numberFormatter.numberFormat = "#.00";
      // chart.responsive.enabled = true;
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = " Free Lime (%)";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = "bold";
      // valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");

      // let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      // // valueAxis.min = 870;
      // valueAxis1.title.text = " Temperature";
      // valueAxis1.title.fill = am4core.color("#FFF");
      // valueAxis1.title.fontWeight = 'bold';
      // // valueAxis.title.fontWeight = 'bold';
      // valueAxis1.title.fontSize = 12;
      // valueAxis1.renderer.labels.template.fontSize = 12;
      // valueAxis1.renderer.labels.template.fill = am4core.color('#fff');

    
      // valueAxis1.syncWithAxis = valueAxis;

      // Create series

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(30);
      // series.clustered = false;
      // series.columns.template.dx = -10;
      series.dataFields.valueY = "free_lime";
      series.dataFields.categoryX = "obs_time";
      // series.strokeWidth = 5;
      series.stroke = am4core.color("#5ab033");
      series.fill = am4core.color("#5ab033");
      series.legendSettings.valueText = "free_lime [bold][/]";
      series.tooltipText = "Free Lime (%)" + `:{${"free_lime"}}`;
      // series.label.text ="{valueY}";

      let range1 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range1.dataFields.valueY = "upper_limit";
      range1.dataFields.categoryX = "obs_time";
      range1.strokeWidth = 3;
      range1.stroke = am4core.color("#fca503");
      range1.fill = am4core.color("#fca503");
      range1.legendSettings.valueText = "upper_limit [bold][/]";
      range1.tooltipText = "Upper Limit" + `:{${"upper_limit"}}`;
      // series.label.text ="{valueY}";

      let range2 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range2.dataFields.valueY = "lower_limit";
      range2.dataFields.categoryX = "obs_time";
      range2.strokeWidth = 3;
      range2.stroke = am4core.color("#03cffc");
      range2.fill = am4core.color("#03cffc");
      range2.propertyFields.strokeDasharray = "lineDash"
      range2.legendSettings.valueText = "lower_limit [bold][/]";
      range2.tooltipText = "Lower Limit" + `:{${"lower_limit"}}`;
      // series.label.text ="{valueY}";

      // let range = valueAxis.axisRanges.create();
      // range.value = ref.Freelimekilndata[0].upper_limit;
      // range.axisFill.tooltip = new am4core.Tooltip();

      // range.axisFill.tooltipText = 'Upper Limit:' +ref.Freelimekilndata[0].upper_limit;

      // range.axisFill.interactionsEnabled = true;

      // range.axisFill.isMeasured = true;
      // range.grid.stroke = am4core.color("#396478");
      // range.grid.strokeWidth = 2;
      // range.grid.strokeOpacity = 1;
      
      // let range2 = valueAxis.axisRanges.create();
      // range2.value = ref.Freelimekilndata[0].lower_limit;
      // range2.axisFill.tooltip = new am4core.Tooltip();

      // range2.axisFill.tooltipText = 'Lower Limit:' +ref.Freelimekilndata[0].lower_limit;

      // range2.axisFill.interactionsEnabled = true;

      // range2.axisFill.isMeasured = true;
      // range2.grid.stroke = am4core.color("#A96478");
      // range2.grid.strokeWidth = 2;
      // range2.grid.strokeOpacity = 1;

      // kiln_feed

     

      // let bullet1 = series.bullets.push(new am4charts.Bullet());
      // bullet1.fill = am4core.color("#ff9225");
      // let circleBullet1 = series.bullets.push(new am4charts.CircleBullet());
      // circleBullet1.circle.radius = 3;

      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "top";
      // chart.legend.labels.template.fill = am4core.color('#fff');
      // chart.legend.labels.template.fontWeight = 'bold';
      // chart.exporting.menu = new am4core.ExportMenu();
      // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
      // chart.exporting.formatOptions.getKey("json").disabled = true;
      // chart.exporting.formatOptions.getKey("html").disabled = true;
      // chart.exporting.formatOptions.getKey("jpg").disabled = true;
      // chart.exporting.formatOptions.getKey("png").disabled = true;
      // chart.exporting.formatOptions.getKey("svg").disabled = true;
      // chart.exporting.formatOptions.getKey("pdf").disabled = true;

      // chart.exporting.filePrefix = "Cyc_6_Avg_Temp";
      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
        am4core.color("rgb(64, 60, 60)");

      chart.tooltip.label.maxWidth = 100;
      chart.tooltip.label.wrap = true;

      // ref.loaded = true;
      // ref.isLoading=false;
    }, 100);
  }

  // Burning chart
  drawBurningChart(){
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("BurningChart", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      // chart.background.fill = am4core.color("#1e1e1e");

      // Add data
      chart.data = ref.Burningdata;
      // chart.data = ref.graphValuefc1;
      chart.cursor = new am4charts.XYCursor();

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.title.text = "Time";
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.title.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.start = 0.9;
      categoryAxis.end = 1;
      // categoryAxis.keepSelection = true;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.zoomOutButton.disabled = true;
      chart.numberFormatter.numberFormat = "#.00";
      // chart.responsive.enabled = true;
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = " Burning Zone Temp (°C)";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = "bold";
      // valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");

      // let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      // // valueAxis.min = 870;
      // valueAxis1.title.text = " Temperature";
      // valueAxis1.title.fill = am4core.color("#FFF");
      // valueAxis1.title.fontWeight = 'bold';
      // // valueAxis.title.fontWeight = 'bold';
      // valueAxis1.title.fontSize = 12;
      // valueAxis1.renderer.labels.template.fontSize = 12;
      // valueAxis1.renderer.labels.template.fill = am4core.color('#fff');

    
      // valueAxis1.syncWithAxis = valueAxis;

      // Create series

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(30);
      // series.clustered = false;
      // series.columns.template.dx = -10;
      series.dataFields.valueY = "burning_zone_temp";
      series.dataFields.categoryX = "obs_time";
      // series.strokeWidth = 5;
      series.stroke = am4core.color("#5ab033");
      series.fill = am4core.color("#5ab033");
      series.legendSettings.valueText = "burning_zone_temp [bold][/]";
      series.tooltipText = "Burning Zone Temp (°C)" + `:{${"burning_zone_temp"}}`;
      // series.label.text ="{valueY}";

      let range1 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range1.dataFields.valueY = "upper_limit";
      range1.dataFields.categoryX = "obs_time";
      range1.strokeWidth = 3;
      range1.stroke = am4core.color("#fca503");
      range1.fill = am4core.color("#fca503");
      range1.legendSettings.valueText = "upper_limit [bold][/]";
      range1.tooltipText = "Upper Limit" + `:{${"upper_limit"}}`;
      // series.label.text ="{valueY}";

      let range2 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range2.dataFields.valueY = "lower_limit";
      range2.dataFields.categoryX = "obs_time";
      range2.strokeWidth = 3;
      range2.stroke = am4core.color("#03cffc");
      range2.fill = am4core.color("#03cffc");
      range2.propertyFields.strokeDasharray = "lineDash"
      range2.legendSettings.valueText = "lower_limit [bold][/]";
      range2.tooltipText = "Lower Limit" + `:{${"lower_limit"}}`;
      // series.label.text ="{valueY}";

      // let range = valueAxis.axisRanges.create();
      // range.value = ref.Burningdata[0].upper_limit;
      // range.axisFill.tooltip = new am4core.Tooltip();

      // range.axisFill.tooltipText = 'Upper Limit:' +ref.Burningdata[0].upper_limit;

      // range.axisFill.interactionsEnabled = true;

      // range.axisFill.isMeasured = true;
      // range.grid.stroke = am4core.color("#396478");
      // range.grid.strokeWidth = 2;
      // range.grid.strokeOpacity = 1;
      
      // let range2 = valueAxis.axisRanges.create();
      // range2.value = ref.Burningdata[0].lower_limit;
      // range2.axisFill.tooltip = new am4core.Tooltip();

      // range2.axisFill.tooltipText = 'Lower Limit:' +ref.Burningdata[0].lower_limit;

      // range2.axisFill.interactionsEnabled = true;

      // range2.axisFill.isMeasured = true;
      // range2.grid.stroke = am4core.color("#A96478");
      // range2.grid.strokeWidth = 2;
      // range2.grid.strokeOpacity = 1;

      // kiln_feed

     

      // let bullet1 = series.bullets.push(new am4charts.Bullet());
      // bullet1.fill = am4core.color("#ff9225");
      // let circleBullet1 = series.bullets.push(new am4charts.CircleBullet());
      // circleBullet1.circle.radius = 3;

      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "top";
      // chart.legend.labels.template.fill = am4core.color('#fff');
      // chart.legend.labels.template.fontWeight = 'bold';
      // chart.exporting.menu = new am4core.ExportMenu();
      // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
      // chart.exporting.formatOptions.getKey("json").disabled = true;
      // chart.exporting.formatOptions.getKey("html").disabled = true;
      // chart.exporting.formatOptions.getKey("jpg").disabled = true;
      // chart.exporting.formatOptions.getKey("png").disabled = true;
      // chart.exporting.formatOptions.getKey("svg").disabled = true;
      // chart.exporting.formatOptions.getKey("pdf").disabled = true;

      // chart.exporting.filePrefix = "Cyc_6_Avg_Temp";
      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
        am4core.color("rgb(64, 60, 60)");

      chart.tooltip.label.maxWidth = 100;
      chart.tooltip.label.wrap = true;

      // ref.loaded = true;
      // ref.isLoading=false;
    }, 100);
  }

  // Kiln-Inlet O2 chart
  drawkilnO2Chart(){
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("inleto2chart", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      // chart.background.fill = am4core.color("#1e1e1e");

      // Add data
      chart.data = ref.Inleto2data;
      // chart.data = ref.graphValuefc1;
      chart.cursor = new am4charts.XYCursor();

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.title.text = "Time";
      categoryAxis.title.fill = am4core.color("#FFF");
      categoryAxis.title.fontSize = 12;
      categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
      categoryAxis.start = 0.9;
      categoryAxis.end = 1;
      // categoryAxis.keepSelection = true;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      chart.zoomOutButton.disabled = true;
      chart.numberFormatter.numberFormat = "#.00";
      // chart.responsive.enabled = true;
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = " Kiln Inlet O2 (%)";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = "bold";
      // valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color("#fff");

      // let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
      // // valueAxis.min = 870;
      // valueAxis1.title.text = " Temperature";
      // valueAxis1.title.fill = am4core.color("#FFF");
      // valueAxis1.title.fontWeight = 'bold';
      // // valueAxis.title.fontWeight = 'bold';
      // valueAxis1.title.fontSize = 12;
      // valueAxis1.renderer.labels.template.fontSize = 12;
      // valueAxis1.renderer.labels.template.fill = am4core.color('#fff');

    
      // valueAxis1.syncWithAxis = valueAxis;

      // Create series

      let series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(30);
      // series.clustered = false;
      // series.columns.template.dx = -10;
      series.dataFields.valueY = "kiln_inlet_o2";
      series.dataFields.categoryX = "obs_time";
      // series.strokeWidth = 5;
      series.stroke = am4core.color("#5ab033");
      series.fill = am4core.color("#5ab033");
      series.legendSettings.valueText = "kiln_inlet_o2 [bold][/]";
      series.tooltipText = "Kiln Inlet O2 (%)" + `:{${"kiln_inlet_o2"}}`;
      // series.label.text ="{valueY}";

      let range1 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range1.dataFields.valueY = "upper_limit";
      range1.dataFields.categoryX = "obs_time";
      range1.strokeWidth = 3;
      range1.stroke = am4core.color("#fca503");
      range1.fill = am4core.color("#fca503");
      range1.legendSettings.valueText = "upper_limit [bold][/]";
      range1.tooltipText = "Upper Limit" + `:{${"upper_limit"}}`;
      // series.label.text ="{valueY}";

      let range2 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range2.dataFields.valueY = "lower_limit";
      range2.dataFields.categoryX = "obs_time";
      range2.strokeWidth = 3;
      range2.stroke = am4core.color("#03cffc");
      range2.fill = am4core.color("#03cffc");
      range2.propertyFields.strokeDasharray = "lineDash"
      range2.legendSettings.valueText = "lower_limit [bold][/]";
      range2.tooltipText = "Lower Limit" + `:{${"lower_limit"}}`;
      // series.label.text ="{valueY}";

      // let range = valueAxis.axisRanges.create();
      // range.value = ref.Inleto2data[0].upper_limit;
      // range.axisFill.tooltip = new am4core.Tooltip();

      // range.axisFill.tooltipText = 'Upper Limit:' +ref.Inleto2data[0].upper_limit;

      // range.axisFill.interactionsEnabled = true;

      // range.axisFill.isMeasured = true;
      // range.grid.stroke = am4core.color("#396478");
      // range.grid.strokeWidth = 2;
      // range.grid.strokeOpacity = 1;
      
      // let range2 = valueAxis.axisRanges.create();
      // range2.value = ref.Inleto2data[0].lower_limit;
      // range2.axisFill.tooltip = new am4core.Tooltip();

      // range2.axisFill.tooltipText = 'Lower Limit:' +ref.Inleto2data[0].lower_limit;

      // range2.axisFill.interactionsEnabled = true;

      // range2.axisFill.isMeasured = true;
      // range2.grid.stroke = am4core.color("#A96478");
      // range2.grid.strokeWidth = 2;
      // range2.grid.strokeOpacity = 1;

      // kiln_feed

     

      // let bullet1 = series.bullets.push(new am4charts.Bullet());
      // bullet1.fill = am4core.color("#ff9225");
      // let circleBullet1 = series.bullets.push(new am4charts.CircleBullet());
      // circleBullet1.circle.radius = 3;

      // Add legend
      // chart.legend = new am4charts.Legend();
      // chart.legend.position = "top";
      // chart.legend.labels.template.fill = am4core.color('#fff');
      // chart.legend.labels.template.fontWeight = 'bold';
      // chart.exporting.menu = new am4core.ExportMenu();
      // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
      // chart.exporting.formatOptions.getKey("json").disabled = true;
      // chart.exporting.formatOptions.getKey("html").disabled = true;
      // chart.exporting.formatOptions.getKey("jpg").disabled = true;
      // chart.exporting.formatOptions.getKey("png").disabled = true;
      // chart.exporting.formatOptions.getKey("svg").disabled = true;
      // chart.exporting.formatOptions.getKey("pdf").disabled = true;

      // chart.exporting.filePrefix = "Cyc_6_Avg_Temp";
      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer;
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "hover"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
        am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey(
        "down"
      ).properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
        am4core.color("rgb(64, 60, 60)");

      chart.tooltip.label.maxWidth = 100;
      chart.tooltip.label.wrap = true;

      // ref.loaded = true;
      // ref.isLoading=false;
    }, 100);
  }

    // Kiln CO chart
    drawinletCOChart(){
      setTimeout(() => {
        let ref = this;
        am4core.useTheme(am4themes_animated);
        // Themes end
  
        // Create chart instance
        let chart = am4core.create("inletcochart", am4charts.XYChart);
        chart.logo.disabled = true;
        chart.paddingRight = 25;
        // chart.background.fill = am4core.color("#1e1e1e");
  
        // Add data
        chart.data = ref.InletCOdata;
        // chart.data = ref.graphValuefc1;
        chart.cursor = new am4charts.XYCursor();
  
        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "obs_time";
        categoryAxis.title.text = "Time";
        categoryAxis.title.fill = am4core.color("#FFF");
        categoryAxis.title.fontSize = 12;
        categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
        categoryAxis.start = 0.9;
        categoryAxis.end = 1;
        // categoryAxis.keepSelection = true;
        // categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 50;
        categoryAxis.renderer.labels.template.fontSize = 12;
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = categoryAxis;
        chart.zoomOutButton.disabled = true;
        chart.numberFormatter.numberFormat = "#.00";
        // chart.responsive.enabled = true;
        // categoryAxis.renderer.cellStartLocation = 0.1;
        // categoryAxis.renderer.cellEndLocation = 0.9;
  
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = "Kiln Inlet CO (%)";
        valueAxis.title.fill = am4core.color("#FFF");
        valueAxis.title.fontWeight = "bold";
        // valueAxis.title.fontWeight = 'bold';
        valueAxis.title.fontSize = 12;
        valueAxis.renderer.labels.template.fontSize = 12;
        valueAxis.renderer.labels.template.fill = am4core.color("#fff");
  
        // let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
        // // valueAxis.min = 870;
        // valueAxis1.title.text = " Temperature";
        // valueAxis1.title.fill = am4core.color("#FFF");
        // valueAxis1.title.fontWeight = 'bold';
        // // valueAxis.title.fontWeight = 'bold';
        // valueAxis1.title.fontSize = 12;
        // valueAxis1.renderer.labels.template.fontSize = 12;
        // valueAxis1.renderer.labels.template.fill = am4core.color('#fff');
  
      
        // valueAxis1.syncWithAxis = valueAxis;
  
        // Create series
  
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.columns.template.width = am4core.percent(30);
        // series.clustered = false;
        // series.columns.template.dx = -10;
        series.dataFields.valueY = "kiln_inlet_co";
        series.dataFields.categoryX = "obs_time";
        // series.strokeWidth = 5;
        series.stroke = am4core.color("#5ab033");
        series.fill = am4core.color("#5ab033");
        series.legendSettings.valueText = "kiln_inlet_co [bold][/]";
        series.tooltipText = "Kiln Inlet CO (%)" + `:{${"kiln_inlet_co"}}`;
        // series.label.text ="{valueY}";

        let range1 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range1.dataFields.valueY = "upper_limit";
      range1.dataFields.categoryX = "obs_time";
      range1.strokeWidth = 3;
      range1.stroke = am4core.color("#fca503");
      range1.fill = am4core.color("#fca503");
      range1.legendSettings.valueText = "upper_limit [bold][/]";
      range1.tooltipText = "Upper Limit" + `:{${"upper_limit"}}`;
      // series.label.text ="{valueY}";

      let range2 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range2.dataFields.valueY = "lower_limit";
      range2.dataFields.categoryX = "obs_time";
      range2.strokeWidth = 3;
      range2.stroke = am4core.color("#03cffc");
      range2.fill = am4core.color("#03cffc");
      range2.propertyFields.strokeDasharray = "lineDash"
      range2.legendSettings.valueText = "lower_limit [bold][/]";
      range2.tooltipText = "Lower Limit" + `:{${"lower_limit"}}`;
      // series.label.text ="{valueY}";
  
        // let range = valueAxis.axisRanges.create();
        // range.value = ref.InletCOdata[0].upper_limit;
        // range.axisFill.tooltip = new am4core.Tooltip();
  
        // range.axisFill.tooltipText = 'Upper Limit:' +ref.InletCOdata[0].upper_limit;
  
        // range.axisFill.interactionsEnabled = true;
  
        // range.axisFill.isMeasured = true;
        // range.grid.stroke = am4core.color("#396478");
        // range.grid.strokeWidth = 2;
        // range.grid.strokeOpacity = 1;
        
        // let range2 = valueAxis.axisRanges.create();
        // range2.value = ref.InletCOdata[0].lower_limit;
        // range2.axisFill.tooltip = new am4core.Tooltip();
  
        // range2.axisFill.tooltipText = 'Lower Limit:' +ref.InletCOdata[0].lower_limit;
  
        // range2.axisFill.interactionsEnabled = true;
  
        // range2.axisFill.isMeasured = true;
        // range2.grid.stroke = am4core.color("#A96478");
        // range2.grid.strokeWidth = 2;
        // range2.grid.strokeOpacity = 1;
  
        // kiln_feed
  
       
  
        // let bullet1 = series.bullets.push(new am4charts.Bullet());
        // bullet1.fill = am4core.color("#ff9225");
        // let circleBullet1 = series.bullets.push(new am4charts.CircleBullet());
        // circleBullet1.circle.radius = 3;
  
        // Add legend
        // chart.legend = new am4charts.Legend();
        // chart.legend.position = "top";
        // chart.legend.labels.template.fill = am4core.color('#fff');
        // chart.legend.labels.template.fontWeight = 'bold';
        // chart.exporting.menu = new am4core.ExportMenu();
        // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
        // chart.exporting.formatOptions.getKey("json").disabled = true;
        // chart.exporting.formatOptions.getKey("html").disabled = true;
        // chart.exporting.formatOptions.getKey("jpg").disabled = true;
        // chart.exporting.formatOptions.getKey("png").disabled = true;
        // chart.exporting.formatOptions.getKey("svg").disabled = true;
        // chart.exporting.formatOptions.getKey("pdf").disabled = true;
  
        // chart.exporting.filePrefix = "Cyc_6_Avg_Temp";
        //Add scrollbar
        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.parent = chart.topAxesContainer;
        chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
        chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
        chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
        chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
        chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
        chart.scrollbarX.minHeight = 7;
        // Applied on hover
        chart.scrollbarX.startGrip.background.states.getKey(
          "hover"
        ).properties.fill = am4core.color("rgb(64, 60, 60)");
        chart.scrollbarX.endGrip.background.states.getKey(
          "hover"
        ).properties.fill = am4core.color("rgb(64, 60, 60)");
        chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
          am4core.color("rgb(64, 60, 60)");
        // Applied on mouse down
        chart.scrollbarX.startGrip.background.states.getKey(
          "down"
        ).properties.fill = am4core.color("rgb(64, 60, 60)");
        chart.scrollbarX.endGrip.background.states.getKey(
          "down"
        ).properties.fill = am4core.color("rgb(64, 60, 60)");
        chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
          am4core.color("rgb(64, 60, 60)");
  
        chart.tooltip.label.maxWidth = 100;
        chart.tooltip.label.wrap = true;
  
        // ref.loaded = true;
        // ref.isLoading=false;
      }, 100);
    }


    // MAin caolchart
    drawMCchart(){
      setTimeout(() => {
        let ref = this;
        am4core.useTheme(am4themes_animated);
        // Themes end
  
        // Create chart instance
        let chart = am4core.create("MCchart", am4charts.XYChart);
        chart.logo.disabled = true;
        chart.paddingRight = 25;
        // chart.background.fill = am4core.color("#1e1e1e");
  
        // Add data
        chart.data = ref.MCdata;
        // chart.data = ref.graphValuefc1;
        chart.cursor = new am4charts.XYCursor();
  
        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "obs_time";
        categoryAxis.title.text = "Time";
        categoryAxis.title.fill = am4core.color("#FFF");
        categoryAxis.title.fontSize = 12;
        categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
        categoryAxis.start = 0.9;
        categoryAxis.end = 1;
        // categoryAxis.keepSelection = true;
        // categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 50;
        categoryAxis.renderer.labels.template.fontSize = 12;
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = categoryAxis;
        chart.zoomOutButton.disabled = true;
        chart.numberFormatter.numberFormat = "#.00";
        // chart.responsive.enabled = true;
        // categoryAxis.renderer.cellStartLocation = 0.1;
        // categoryAxis.renderer.cellEndLocation = 0.9;
  
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = "Main Coal (TPH)";
        valueAxis.title.fill = am4core.color("#FFF");
        valueAxis.title.fontWeight = "bold";
        // valueAxis.title.fontWeight = 'bold';
        valueAxis.title.fontSize = 12;
        valueAxis.renderer.labels.template.fontSize = 12;
        valueAxis.renderer.labels.template.fill = am4core.color("#fff");
  
        // let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
        // // valueAxis.min = 870;
        // valueAxis1.title.text = " Temperature";
        // valueAxis1.title.fill = am4core.color("#FFF");
        // valueAxis1.title.fontWeight = 'bold';
        // // valueAxis.title.fontWeight = 'bold';
        // valueAxis1.title.fontSize = 12;
        // valueAxis1.renderer.labels.template.fontSize = 12;
        // valueAxis1.renderer.labels.template.fill = am4core.color('#fff');
  
      
        // valueAxis1.syncWithAxis = valueAxis;
  
        // Create series
  
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.columns.template.width = am4core.percent(30);
        // series.clustered = false;
        // series.columns.template.dx = -10;
        series.dataFields.valueY = "main_coal_tph";
        series.dataFields.categoryX = "obs_time";
        // series.strokeWidth = 5;
        series.stroke = am4core.color("#5ab033");
        series.fill = am4core.color("#5ab033");
        series.legendSettings.valueText = "main_coal_tph [bold][/]";
        series.tooltipText = "Main Coal (TPH) Actual" + `:{${"main_coal_tph"}}`;
        // series.label.text ="{valueY}";

        let range1 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range1.dataFields.valueY = "mc_tph";
      range1.dataFields.categoryX = "obs_time";
      range1.strokeWidth = 3;
      range1.stroke = am4core.color("#fca503");
      range1.fill = am4core.color("#fca503");
      range1.legendSettings.valueText = "mc_tph [bold][/]";
      range1.tooltipText = "Main Coal (TPH) Recommended" + `:{${"mc_tph"}}`;
      // series.label.text ="{valueY}";
  
        // let range = valueAxis.axisRanges.create();
        // range.value = ref.MCdata[0].upper_limit;
        // range.axisFill.tooltip = new am4core.Tooltip();
  
        // range.axisFill.tooltipText = 'Upper Limit:' +ref.MCdata[0].upper_limit;
  
        // range.axisFill.interactionsEnabled = true;
  
        // range.axisFill.isMeasured = true;
        // range.grid.stroke = am4core.color("#396478");
        // range.grid.strokeWidth = 2;
        // range.grid.strokeOpacity = 1;
        
        // let range2 = valueAxis.axisRanges.create();
        // range2.value = ref.MCdata[0].lower_limit;
        // range2.axisFill.tooltip = new am4core.Tooltip();
  
        // range2.axisFill.tooltipText = 'Lower Limit:' +ref.MCdata[0].lower_limit;
  
        // range2.axisFill.interactionsEnabled = true;
  
        // range2.axisFill.isMeasured = true;
        // range2.grid.stroke = am4core.color("#A96478");
        // range2.grid.strokeWidth = 2;
        // range2.grid.strokeOpacity = 1;
  
        // kiln_feed
  
       
  
        // let bullet1 = series.bullets.push(new am4charts.Bullet());
        // bullet1.fill = am4core.color("#ff9225");
        // let circleBullet1 = series.bullets.push(new am4charts.CircleBullet());
        // circleBullet1.circle.radius = 3;
  
        // Add legend
        // chart.legend = new am4charts.Legend();
        // chart.legend.position = "top";
        // chart.legend.labels.template.fill = am4core.color('#fff');
        // chart.legend.labels.template.fontWeight = 'bold';
        // chart.exporting.menu = new am4core.ExportMenu();
        // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
        // chart.exporting.formatOptions.getKey("json").disabled = true;
        // chart.exporting.formatOptions.getKey("html").disabled = true;
        // chart.exporting.formatOptions.getKey("jpg").disabled = true;
        // chart.exporting.formatOptions.getKey("png").disabled = true;
        // chart.exporting.formatOptions.getKey("svg").disabled = true;
        // chart.exporting.formatOptions.getKey("pdf").disabled = true;
  
        // chart.exporting.filePrefix = "Cyc_6_Avg_Temp";
        //Add scrollbar
        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.parent = chart.topAxesContainer;
        chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
        chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
        chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
        chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
        chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
        chart.scrollbarX.minHeight = 7;
        // Applied on hover
        chart.scrollbarX.startGrip.background.states.getKey(
          "hover"
        ).properties.fill = am4core.color("rgb(64, 60, 60)");
        chart.scrollbarX.endGrip.background.states.getKey(
          "hover"
        ).properties.fill = am4core.color("rgb(64, 60, 60)");
        chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
          am4core.color("rgb(64, 60, 60)");
        // Applied on mouse down
        chart.scrollbarX.startGrip.background.states.getKey(
          "down"
        ).properties.fill = am4core.color("rgb(64, 60, 60)");
        chart.scrollbarX.endGrip.background.states.getKey(
          "down"
        ).properties.fill = am4core.color("rgb(64, 60, 60)");
        chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
          am4core.color("rgb(64, 60, 60)");
  
        chart.tooltip.label.maxWidth = 100;
        chart.tooltip.label.wrap = true;
  
        // ref.loaded = true;
        // ref.isLoading=false;
      }, 100);
    }

    // kiln speed rpm chart
    drawKSchart(){
      setTimeout(() => {
        let ref = this;
        am4core.useTheme(am4themes_animated);
        // Themes end
  
        // Create chart instance
        let chart = am4core.create("kschart", am4charts.XYChart);
        chart.logo.disabled = true;
        chart.paddingRight = 25;
        // chart.background.fill = am4core.color("#1e1e1e");
  
        // Add data
        chart.data = ref.KSdata;
        // chart.data = ref.graphValuefc1;
        chart.cursor = new am4charts.XYCursor();
  
        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "obs_time";
        categoryAxis.title.text = "Time";
        categoryAxis.title.fill = am4core.color("#FFF");
        categoryAxis.title.fontSize = 12;
        categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
        categoryAxis.start = 0.9;
        categoryAxis.end = 1;
        // categoryAxis.keepSelection = true;
        // categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 50;
        categoryAxis.renderer.labels.template.fontSize = 12;
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.xAxis = categoryAxis;
        chart.zoomOutButton.disabled = true;
        chart.numberFormatter.numberFormat = "#.00";
        // chart.responsive.enabled = true;
        // categoryAxis.renderer.cellStartLocation = 0.1;
        // categoryAxis.renderer.cellEndLocation = 0.9;
  
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = "Kiln Speed (RPM) ";
        valueAxis.title.fill = am4core.color("#FFF");
        valueAxis.title.fontWeight = "bold";
        // valueAxis.title.fontWeight = 'bold';
        valueAxis.title.fontSize = 12;
        valueAxis.renderer.labels.template.fontSize = 12;
        valueAxis.renderer.labels.template.fill = am4core.color("#fff");
  
        // let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
        // // valueAxis.min = 870;
        // valueAxis1.title.text = " Temperature";
        // valueAxis1.title.fill = am4core.color("#FFF");
        // valueAxis1.title.fontWeight = 'bold';
        // // valueAxis.title.fontWeight = 'bold';
        // valueAxis1.title.fontSize = 12;
        // valueAxis1.renderer.labels.template.fontSize = 12;
        // valueAxis1.renderer.labels.template.fill = am4core.color('#fff');
  
      
        // valueAxis1.syncWithAxis = valueAxis;
  
        // Create series
  
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.columns.template.width = am4core.percent(30);
        // series.clustered = false;
        // series.columns.template.dx = -10;
        series.dataFields.valueY = "cd_kiln_speed";
        series.dataFields.categoryX = "obs_time";
        // series.strokeWidth = 5;
        series.stroke = am4core.color("#5ab033");
        series.fill = am4core.color("#5ab033");
        series.legendSettings.valueText = "cd_kiln_speed [bold][/]";
        series.tooltipText = "Kiln Speed (RPM) Actual" + `:{${"cd_kiln_speed"}}`;
        // series.label.text ="{valueY}";

        let range1 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range1.dataFields.valueY = "ko_kiln_speed";
      range1.dataFields.categoryX = "obs_time";
      range1.strokeWidth = 3;
      range1.stroke = am4core.color("#fca503");
      range1.fill = am4core.color("#fca503");
      range1.legendSettings.valueText = "ko_kiln_speed [bold][/]";
      range1.tooltipText = "Kiln Speed (RPM) Recommended" + `:{${"ko_kiln_speed"}}`;
      // series.label.text ="{valueY}";
  
        // let range = valueAxis.axisRanges.create();
        // range.value = ref.InletCOdata[0].upper_limit;
        // range.axisFill.tooltip = new am4core.Tooltip();
  
        // range.axisFill.tooltipText = 'Upper Limit:' +ref.InletCOdata[0].upper_limit;
  
        // range.axisFill.interactionsEnabled = true;
  
        // range.axisFill.isMeasured = true;
        // range.grid.stroke = am4core.color("#396478");
        // range.grid.strokeWidth = 2;
        // range.grid.strokeOpacity = 1;
        
        // let range2 = valueAxis.axisRanges.create();
        // range2.value = ref.InletCOdata[0].lower_limit;
        // range2.axisFill.tooltip = new am4core.Tooltip();
  
        // range2.axisFill.tooltipText = 'Lower Limit:' +ref.InletCOdata[0].lower_limit;
  
        // range2.axisFill.interactionsEnabled = true;
  
        // range2.axisFill.isMeasured = true;
        // range2.grid.stroke = am4core.color("#A96478");
        // range2.grid.strokeWidth = 2;
        // range2.grid.strokeOpacity = 1;
  
        // kiln_feed
  
       
  
        // let bullet1 = series.bullets.push(new am4charts.Bullet());
        // bullet1.fill = am4core.color("#ff9225");
        // let circleBullet1 = series.bullets.push(new am4charts.CircleBullet());
        // circleBullet1.circle.radius = 3;
  
        // Add legend
        // chart.legend = new am4charts.Legend();
        // chart.legend.position = "top";
        // chart.legend.labels.template.fill = am4core.color('#fff');
        // chart.legend.labels.template.fontWeight = 'bold';
        // chart.exporting.menu = new am4core.ExportMenu();
        // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
        // chart.exporting.formatOptions.getKey("json").disabled = true;
        // chart.exporting.formatOptions.getKey("html").disabled = true;
        // chart.exporting.formatOptions.getKey("jpg").disabled = true;
        // chart.exporting.formatOptions.getKey("png").disabled = true;
        // chart.exporting.formatOptions.getKey("svg").disabled = true;
        // chart.exporting.formatOptions.getKey("pdf").disabled = true;
  
        // chart.exporting.filePrefix = "Cyc_6_Avg_Temp";
        //Add scrollbar
        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.parent = chart.topAxesContainer;
        chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
        chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
        chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
        chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
        chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
        chart.scrollbarX.minHeight = 7;
        // Applied on hover
        chart.scrollbarX.startGrip.background.states.getKey(
          "hover"
        ).properties.fill = am4core.color("rgb(64, 60, 60)");
        chart.scrollbarX.endGrip.background.states.getKey(
          "hover"
        ).properties.fill = am4core.color("rgb(64, 60, 60)");
        chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
          am4core.color("rgb(64, 60, 60)");
        // Applied on mouse down
        chart.scrollbarX.startGrip.background.states.getKey(
          "down"
        ).properties.fill = am4core.color("rgb(64, 60, 60)");
        chart.scrollbarX.endGrip.background.states.getKey(
          "down"
        ).properties.fill = am4core.color("rgb(64, 60, 60)");
        chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
          am4core.color("rgb(64, 60, 60)");
  
        chart.tooltip.label.maxWidth = 100;
        chart.tooltip.label.wrap = true;
  
        // ref.loaded = true;
        // ref.isLoading=false;
      }, 100);
    }

    // PA fan speed chart
drawPAchart(){
  setTimeout(() => {
    let ref = this;
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    let chart = am4core.create("PAchart", am4charts.XYChart);
    chart.logo.disabled = true;
    chart.paddingRight = 25;
    // chart.background.fill = am4core.color("#1e1e1e");

    // Add data
    chart.data = ref.PAdata;
    // chart.data = ref.graphValuefc1;
    chart.cursor = new am4charts.XYCursor();

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "obs_time";
    categoryAxis.title.text = "Time";
    categoryAxis.title.fill = am4core.color("#FFF");
    categoryAxis.title.fontSize = 12;
    categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
    categoryAxis.start = 0.9;
    categoryAxis.end = 1;
    // categoryAxis.keepSelection = true;
    // categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 50;
    categoryAxis.renderer.labels.template.fontSize = 12;
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
    chart.zoomOutButton.disabled = true;
    chart.numberFormatter.numberFormat = "#.00";
    // chart.responsive.enabled = true;
    // categoryAxis.renderer.cellStartLocation = 0.1;
    // categoryAxis.renderer.cellEndLocation = 0.9;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = "PA Fan Speed (RPM) ";
    valueAxis.title.fill = am4core.color("#FFF");
    valueAxis.title.fontWeight = "bold";
    // valueAxis.title.fontWeight = 'bold';
    valueAxis.title.fontSize = 12;
    valueAxis.renderer.labels.template.fontSize = 12;
    valueAxis.renderer.labels.template.fill = am4core.color("#fff");

    // let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
    // // valueAxis.min = 870;
    // valueAxis1.title.text = " Temperature";
    // valueAxis1.title.fill = am4core.color("#FFF");
    // valueAxis1.title.fontWeight = 'bold';
    // // valueAxis.title.fontWeight = 'bold';
    // valueAxis1.title.fontSize = 12;
    // valueAxis1.renderer.labels.template.fontSize = 12;
    // valueAxis1.renderer.labels.template.fill = am4core.color('#fff');

  
    // valueAxis1.syncWithAxis = valueAxis;

    // Create series

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.columns.template.width = am4core.percent(30);
    // series.clustered = false;
    // series.columns.template.dx = -10;
    series.dataFields.valueY = "pa_fan_speed";
    series.dataFields.categoryX = "obs_time";
    // series.strokeWidth = 5;
    series.stroke = am4core.color("#5ab033");
    series.fill = am4core.color("#5ab033");
    series.legendSettings.valueText = "pa_fan_speed [bold][/]";
    series.tooltipText = "PA Fan speed (RPM) Actual" + `:{${"pa_fan_speed"}}`;
    // series.label.text ="{valueY}";

    let range1 = chart.series.push(new am4charts.LineSeries());
      // series.clustered = false;
      // series.columns.template.dx = -10;
      range1.dataFields.valueY = "pa_fan_rpm";
      range1.dataFields.categoryX = "obs_time";
      range1.strokeWidth = 3;
      range1.stroke = am4core.color("#fca503");
      range1.fill = am4core.color("#fca503");
      range1.legendSettings.valueText = "pa_fan_rpm [bold][/]";
      range1.tooltipText = "PA Fan speed (RPM) Recommended" + `:{${"pa_fan_rpm"}}`;
      // series.label.text ="{valueY}";

    // let range = valueAxis.axisRanges.create();
    // range.value = ref.InletCOdata[0].upper_limit;
    // range.axisFill.tooltip = new am4core.Tooltip();

    // range.axisFill.tooltipText = 'Upper Limit:' +ref.InletCOdata[0].upper_limit;

    // range.axisFill.interactionsEnabled = true;

    // range.axisFill.isMeasured = true;
    // range.grid.stroke = am4core.color("#396478");
    // range.grid.strokeWidth = 2;
    // range.grid.strokeOpacity = 1;
    
    // let range2 = valueAxis.axisRanges.create();
    // range2.value = ref.InletCOdata[0].lower_limit;
    // range2.axisFill.tooltip = new am4core.Tooltip();

    // range2.axisFill.tooltipText = 'Lower Limit:' +ref.InletCOdata[0].lower_limit;

    // range2.axisFill.interactionsEnabled = true;

    // range2.axisFill.isMeasured = true;
    // range2.grid.stroke = am4core.color("#A96478");
    // range2.grid.strokeWidth = 2;
    // range2.grid.strokeOpacity = 1;

    // kiln_feed

   

    // let bullet1 = series.bullets.push(new am4charts.Bullet());
    // bullet1.fill = am4core.color("#ff9225");
    // let circleBullet1 = series.bullets.push(new am4charts.CircleBullet());
    // circleBullet1.circle.radius = 3;

    // Add legend
    // chart.legend = new am4charts.Legend();
    // chart.legend.position = "top";
    // chart.legend.labels.template.fill = am4core.color('#fff');
    // chart.legend.labels.template.fontWeight = 'bold';
    // chart.exporting.menu = new am4core.ExportMenu();
    // chart.exporting.formatOptions.getKey("xlsx").disabled = true;
    // chart.exporting.formatOptions.getKey("json").disabled = true;
    // chart.exporting.formatOptions.getKey("html").disabled = true;
    // chart.exporting.formatOptions.getKey("jpg").disabled = true;
    // chart.exporting.formatOptions.getKey("png").disabled = true;
    // chart.exporting.formatOptions.getKey("svg").disabled = true;
    // chart.exporting.formatOptions.getKey("pdf").disabled = true;

    // chart.exporting.filePrefix = "Cyc_6_Avg_Temp";
    //Add scrollbar
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.topAxesContainer;
    chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
    chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
    chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
    chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
    chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
    chart.scrollbarX.minHeight = 7;
    // Applied on hover
    chart.scrollbarX.startGrip.background.states.getKey(
      "hover"
    ).properties.fill = am4core.color("rgb(64, 60, 60)");
    chart.scrollbarX.endGrip.background.states.getKey(
      "hover"
    ).properties.fill = am4core.color("rgb(64, 60, 60)");
    chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
      am4core.color("rgb(64, 60, 60)");
    // Applied on mouse down
    chart.scrollbarX.startGrip.background.states.getKey(
      "down"
    ).properties.fill = am4core.color("rgb(64, 60, 60)");
    chart.scrollbarX.endGrip.background.states.getKey(
      "down"
    ).properties.fill = am4core.color("rgb(64, 60, 60)");
    chart.scrollbarX.thumb.background.states.getKey("down").properties.fill =
      am4core.color("rgb(64, 60, 60)");

    chart.tooltip.label.maxWidth = 100;
    chart.tooltip.label.wrap = true;

    // ref.loaded = true;
    // ref.isLoading=false;
  }, 100);
}
  private createUSERForm() {
    this.userForm = new FormGroup({
      first_name: new FormControl("", Validators.required),
      last_name: new FormControl("", Validators.required),
      username: new FormControl("", Validators.required),
      email: new FormControl(
        "",
        (Validators.required,
        Validators.pattern("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"))
      ),
      password: new FormControl("", Validators.required),
      role: new FormControl("", Validators.required),
      mobile_no: new FormControl(
        "",
        (Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern("[0-9]{10}$"))
      ),
      is_active: new FormControl("", Validators.required),
    });
  }

  private editUSERForm() {
    this.edituserForm = new FormGroup({
      first_name: new FormControl("", Validators.required),
      last_name: new FormControl("", Validators.required),
      username: new FormControl("", Validators.required),
      email: new FormControl(
        "",
        (Validators.required,
        Validators.pattern("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"))
      ),
      password: new FormControl("", Validators.required),
      role: new FormControl("", Validators.required),
      mobile_no: new FormControl(
        "",
        (Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern("[0-9]{10}$"))
      ),
      is_active: new FormControl("", Validators.required),
    });
  }
}
