import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

const baseUrl = environment['baseUrl'];
const baseUrl2 = environment['baseUrl2'];
const baseUrl3 =environment['baseUrl3'];
@Injectable({
  providedIn: 'root',
})
export class APiService {
  [x: string]: any;
  
  public potNo = new BehaviorSubject(1303);
  public boilerNo = new BehaviorSubject('Z701');
  getSelectedAlgoConfig: any;

  constructor(private http: HttpClient) {}

// DASHBOARD PAGE -- REALTIME PARAMETERS TABLE API INTEGRATION

getTableValues( starttime: any): Observable<any> {
  // return this.http.get(`${baseUrl2}filter_optimization_data?epoch_time=${starttime}`).pipe(getTableValues(starttime: any)
    return this.http.get(`${baseUrl2}real_time_filter_optimization_data?date_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length==0) {
         return [];
        }else{ return response;}
       
      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}
// DASHBOARD PAGE -- FORECAST TABLE API INTEGRATION
getForecastTableValues( starttime: any): Observable<any> {
  // return this.http.get(`${baseUrl2}forecast_data?date_time=${starttime}`).pipe(
    return this.http.get(`${baseUrl2}real_time_forecast?date_time=${starttime}`).pipe(

    map(
      (response: any) => {
        if (response.length==0) {
         return [];
        }else{ return response;}
       
      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}
getForecastAlertTableValues( starttime: any): Observable<any> {
  return this.http.get(`${baseUrl2}forecast_alert?date_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length==0) {
         return [];
        }else{ return response;}
       
      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}

getAFRTableValues(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl3}afr_table_data?date_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length==0) {
         return [];
        }else{ return response;}
       
      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}

getAFRTableValues1(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl3}daily_average_afr_per?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length==0) {
         return [];
        }else{ return response;}
       
      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}
getCoalTableValues(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl3}daily_average_coal_per?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length==0) {
         return [];
        }else{ return response;}
       
      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}


// Theme 3 Api Calls
getCOtableValues1(starttime: any): Observable<any>{
  return this.http.get(`${baseUrl2}co_reduction_data?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        if (response.length==0) {
         return [];
        }else{ return response;}
       
      },
      (error) => {
        // // console.log(error);
      }
    ),
    catchError(err => {
      let value = [];
      // // console.log("Error is handled",err);
      return value;
    })
  );
}

// Theme5apicalls
// uploadFilekpi(filetype:any): Observable<any>{
// return this.http.post<any>(`${baseUrl2}kpi/file_upload?type=${filetype}`,{}).pipe(map((response: any) => {
//   return response
// }, (error: any) => {
//    console.log(error);
// }
// ));
// }

 uploadFiles(_type:any, fd:any): Observable<any> {
  
   return this.http.post<any>(`${baseUrl}kpi/file_upload?type=${_type}`,fd, {observe: "response"})
    .pipe(catchError(this.handleError))

  }




// Theme 6 Api calls
getAllUsers() {
  return this.http.get(`${baseUrl}user`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}

getPrevData() {
  return this.http.get(`${baseUrl}temp_previously_shift_data`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}

getPrevPDFData() {
  return this.http.get(`${baseUrl}pdf_temp`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}
// pdf_temp?date= this shall be used if pdftempdata is shift wise 
getpdftempdata(st_dt){
  return this.http.get(`${baseUrl}day-wise_pdf/t?date=${st_dt}`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}
// pdf_co?date= this shall be used if pdfcodata is shift wise 
getpdfcodata(st_dt){
  return this.http.get(`${baseUrl}day-wise_pdf/c?date=${st_dt}`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}

getStatus() {
  return this.http.get(`${baseUrl}status`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}
getDaywiseSatus(st_dt){
  
  return this.http.get(`${baseUrl}day-wise_status?date=${st_dt}`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}

getPrevPDFData1() {
  return this.http.get(`${baseUrl}pdf_co`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}


getPrevData1() {
  return this.http.get(`${baseUrl}co_previously_shift_data`,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}



registerUser(body) {
  return this.http.post(`${baseUrl}user`, body,{headers:this.reqHeaders}).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );

  
}

deleteDriver(identification) {
  return this.http.delete(`${baseUrl}user?username=${identification}`, { headers: this.reqHeaders }).pipe(
    map(
      (response) => {
        return response;
      },
      (err) => {
        // // (err);
      }
    )
  );
}

updateUserInfo(identification,body) {
  return this.http.put(`${baseUrl}user?username=${identification}`,body,{headers:this.reqHeaders}).pipe(
    map(
   (response) => {
     return response;
   },
   (err) => {} ) );}

  //  https://api.dev.afr4.0.livnsense.com/clinker_quality?start_time=1680426900
  getClinkerValues(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}clinker_quality?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }
  getClinkerRecommendedValues(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}real_time/parameter?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );

  }

  // Theme8

  getActualForecastKiln(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}forecast_actual?report_date=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }
  getForecastKiln(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}kiln_forecasting?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }
 


  // Theme9
  getActualForecastKilnRecmnd(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}recommendation_actual?report_date=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }
  getForecastKilnRecmnd(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}kiln_recommendation?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }



  //Theme 1
  AfrDashboardoptimisation(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}afr_optimization/2?report_date=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }

  KilnDashboardrcmnd(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}kiln_recommendation_data/2?report_date=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }

  //Theme 10

SopAlerts(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}alerts?alert_date=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }

  SopTable(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}kiln_constrains?report_date=${starttime}`).pipe(
      map(
        (response: any) => {
          if (response.length==0) {
           return [];
          }else{ return response;}
         
        },
        (error) => {
          // // console.log(error);
        }
      ),
      catchError(err => {
        let value = [];
        console.log("Error is handled",err);
        return value;
      })
    );
  }

// method to get report table and graph values

// getTableGraphValues(): Observable<any> {
//   // const sDate = new Date(filters['start_date']).toISOString();
//   // const eDate = new Date(filters['end_date']).toISOString();
//   return this.http.get(`${baseUrl2}realtime_opt_graph?start_time=1660847400&end_time=1660933500`).pipe(
//     map(
//       (response: any) => {
//         if (response.length==0) {
//          return [];
//         }else{ return response;}
       
//       },
//       (error) => {
//         // // console.log(error);
//       }
//     ),
//     catchError(err => {
//       let value = [];
//       // // console.log("Error is handled",err);
//       return value;
//     })
//   );
// }


// getfc2TableGraphValues(): Observable<any> {
//   // const sDate = new Date(filters['start_date']).toISOString();
//   // const eDate = new Date(filters['end_date']).toISOString();
//   return this.http.get(`${baseUrl2}actual_forecast_graph_02?start_time=1660847400&end_time=1660933500`).pipe(
//     map(
//       (response: any) => {
//         if (response.length==0) {
//          return [];
//         }else{ return response;}
       
//       },
//       (error) => {
//         // // console.log(error);
//       }
//     ),
//     catchError(err => {
//       let value = [];
//       // // console.log("Error is handled",err);
//       return value;
//     })
//   );
// }
// getfc1TableGraphValues(): Observable<any> {
//   // const sDate = new Date(filters['start_date']).toISOString();
//   // const eDate = new Date(filters['end_date']).toISOString();
//   return this.http.get(`${baseUrl2}actual_forecast_graph_01?start_time=1660847400&end_time=1660933500`).pipe(
//     map(
//       (response: any) => {
//         if (response.length==0) {
//          return [];
//         }else{ return response;}
       
//       },
//       (error) => {
//         // // console.log(error);
//       }
//     ),
//     catchError(err => {
//       let value = [];
//       // // console.log("Error is handled",err);
//       return value;
//     })
//   );
// }

// method to get report dosing table values 

// getDosingTableData(filters:any): Observable<any> {
//   const sDate = new Date(filters['start_date']).toISOString();
//   const eDate = new Date(filters['end_date']).toISOString();
//   return this.htttp.get(`${baseUrl2}dashboard/dosing_table?start_date=${sDate}&end_date=${eDate}`).pipe(
//     map(
//       (response: any) => {
//         if (response.length==0) {
//          return [];
//         }else{ return response;}
       
//       },
//       (error) => {
//         // // console.log(error);
//       }
//     ),
//     catchError(err => {
//       let value = [];
//       // // console.log("Error is handled",err);
//       return value;
//     })
//   );
// }



// getROITableValues(boilerNo): Observable<any> {
//   return this.htttp.get(`${baseUrl2}dashboard/roi_data?boiler=${boilerNo}`).pipe(
//     map(
//       (response: any) => {
//         if (response.length==0) {
//          return [];
//         }else{ return response;}
       
//       },
//       (error) => {
//         // // console.log(error);
//       }
//     ),
//     catchError(err => {
//       let value = [];
//       // // console.log("Error is handled",err);
//       return value;
//     })
//   );
// }

// uploadDosing(body:any): Observable<any> {
//   return this.htttp.post(`${baseUrl2}dashboard/dosing_data`,body).pipe(
//     map(
//       (response: any) => {
//         if (response.length==0) {
//          return [];
//         }else{ return response;}
       
//       },
//       (error) => {
//         // // console.log(error);
//       }
//     ),
//     catchError(err => {
//       let value = [];
//       // // console.log("Error is handled",err);
//       return value;
//     })
//   );
// }
  
}


