<header class="asHeader">
  <nav>
    <!-- <a class="asLogo" target="_blank" href="https://livnsense.com/" title="LivNSense Technologies Pvt. Ltd">
        <img src="assets/img/livnsenceLogo.png" width="" alt=""></a> -->

    <!-- <div style="padding: 0.8%; display: grid;">
          <div style="background: white; border-radius: 50%; max-width:82px; padding:5px; max-height:78px; margin-right: 82px; margin: auto;" class="logo">
            <img src="assets\img\lns_logo.png" alt="" style="border-radius: 38%; width: 70px; "/>
          </div>
         </div> -->
    <div style="background-color: white; border-radius: 50%;height: 49px;width: 49px;margin-left: 2%;">
      <img src="assets\img\lns_logo.png" alt="" style="border-radius: 38%; width: 70px; " />
    </div>
    <span style="display: flex;flex-direction: row;justify-content: flex-start;">
      <h1>Kiln 4.0</h1>
      <!-- <p style="font-size:20px;padding-top: 30px;">Powered by</p> &nbsp;
      <img src="assets\img\greenOps.png" width="100px" alt="" style="    margin-bottom: 25px;
      margin-top: 30px;"> -->
    </span>
    
    <!-- <div class="content text-right pr-1">
      <div class="time-slot">{{date | ordinal}}</div>
      <div class="date">Last Updated: {{date | date:'HH:mm:ss'}}</div>
    </div> -->
    <div class="headProfile">
      <!-- Last Updated -->
    <!-- <div *ngFor="let vval of Realtimedata  " class="date" style="color: #F5F5F5;margin-right: 10px;">Last Updated: &nbsp;{{vval.dateandtime | date:'dd/MM/yyyy HH:mm'}}</div> -->
      <img src="assets/img/headProfileIcon.svg" alt="" width="43" height="43">
      <!-- <div class="headProfileMdl dropdown">
              <span class="headProfileMdlName">{{currentUser}}</span><br>
              <span class="headProfileMdlRole">Super Admin</span>
          </div> -->
      <button style="border: unset;    background-image: linear-gradient(rgb(30, 30, 30), rgb(23, 23, 23));"
        class=" dropdown-toggle logoutdropdown" data-toggle="dropdown">
        <img src="assets\img\headProfileArrow.svg" alt="">
      </button>
      <ul class="dropdown-menu">
        <li> <a class="dropdown-item" href="#" (click)="logout()"><i class="fas fa-sign-out-alt p-1"></i>Logout</a></li>
      </ul>
    </div>
  </nav>
</header>