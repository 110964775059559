<section>
  <div class="mainGridRgt11"
  style="display:flex;flex-direction:row;justify-content:space-between;color: white; border-bottom: 2px solid;">
  <div class="asImgBoxTop pt-2 pb-2">
    <div class="asImgBoxTopTxt">AFR Recommendation</div>
  </div>
      <div style="display: flex">
        <div class="mr-2 date-picker">
          <mat-form-field class="padding-test" appearance="outline">
            <mat-label style="color: white">Date</mat-label>
            <input style="color: white; font-size: 15px" matInput [min]="minDate" [max]="maxDate"
              [matDatepicker]="startdate" placeholder="Choose Start Date" [(ngModel)]="appliedDateFilters.start_date"
              [disabled]="false" />
            <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
            <mat-datepicker #startdate></mat-datepicker>
          </mat-form-field>
        </div>
        <button class="btn1" title="Select the date and click." style="
           font-size: 16px;
      width: 70px;
      cursor: pointer;
      background-color: #302c2c;
      outline: unset;
      /* margin-left: 4%; */
      /* padding-top: 2px; */
      border: 1px solid rgba(255, 255, 255, 0.38);
      height: 35px;border-radius: 0.9375em !important;
      " mat-raised-button color="primary" class="get-sm-btn" (click)="changeDate()">
          Apply
        </button>
      </div> 
    </div>
    <!-- <div class="row" style="padding-left: 1%;
      padding-right: 1%;">
      <div class="col-md-12">
        <select class="global_dropdown" style=" max-width: max-content;
              float: right;
              padding: 10px;  
              font-size: 13px;
              padding-top: 8px;
              padding-bottom: 8px;
              margin-bottom: 1%;margin-top: 1%;" (change)="changeDropdown($event)">
          <option value="1660847400">
            Aug 19th 2022
          </option>
          <option value="1660933800">
            Aug 20th 2022
          </option>
          <option value="1661020200">
            Aug 21th 2022
          </option>
          <option value="1661106600">
            Aug 22th 2022
          </option>
        </select>
      </div>
    </div> -->
    <div *ngIf="!isLoading" style=" font-size: 13px;flex-direction: column;
    display: flex;
    justify-content: space-evenly;
    height: 590px;">
    <div class="col-md-12">
      <span style=" color: #fff; float: right;">
        <tr *ngFor="let vval of AFRdata  ">
          <td>Time -&nbsp;{{vval.obs_time | date:'HH:mm'}}
          </td>
        </tr>
      </span>
    </div>
    <!-- <div class="row" style="padding: 1%;">
      <div class="col-md-6">
        <table class="tableStyle">
          <tr>
            <td style="font-size: 15px;color: yellow; ">Daily Average Coal Reduced (%) </td>
          </tr>
          <tr *ngFor="let vval of Coaldata  ">
           
            <td>{{vval.daily_coal_per}} &nbsp;&nbsp;&nbsp;
              <img src="../../../../assets/images/Redarrow.gif" style="width: 30px;" />
            </td>
          </tr>
        </table>
      </div>
      <div class="col-md-6">
        <table class="tableStyle">
          <tr>
            <td style="font-size: 15px;color: yellow; "> Daily Average AFR Increased (%) </td>
          </tr>
          <tr *ngFor="let vval of  AFRdata1 ">
      
            <td>{{vval.daily_afr_per}}&nbsp;&nbsp;&nbsp;
              <img src="../../../../assets/images/GreenArrow.gif" style="width: 30px;" />
            </td>

          </tr>
        </table>
      </div>
    
    </div> -->
    <!-- <div class="row">
      <div class="col-md-6">
        <div class="red">
          <label for="temp">Daily Average Coal Reduced (%)</label>
          <span  *ngIf="Coaldata !=0" >
          <div *ngFor="let vval of Coaldata  ">
            <label class="per">{{vval.daily_coal_per}}</label>&nbsp;&nbsp;&nbsp;
            <img src="../../../../assets/images/Redarrow.gif" style="width: 30px;" />
          </div>
        </span>
          <div *ngIf="Coaldata ==0 || null"><label class="per">No data</label></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="red" >
          <label for="temp">Daily Average AFR Increased (%)</label>
          <span  *ngIf="AFRdata1 !=0" >
          <div *ngFor="let vval of AFRdata1  ">
            <label class="per">{{vval.daily_afr_per}}</label>&nbsp;&nbsp;&nbsp;
            <img src="../../../../assets/images/GreenArrow.gif" style="width: 30px;" />
          </div>
          </span>
          <div  *ngIf="AFRdata1 ==0"><label class="per">No data</label></div>
        </div>
      </div>
    </div> -->
    <div class="theme1PlotBox">
      <div class="row">
        <div class="col-md-12" >
         <h1 style="font-weight: bold; margin-left: 2px; color:#ffffff; font-size: 17px;margin: 0px; padding-bottom: 5px;"> Liquid AFR vs Main Coal</h1>
      <div class="theme1PlotBoxGraph">
        <div id="ppediv"></div>
      </div>
      </div>
    </div>
  </div>


    <div class="row">
      <div class="col-md-12">
      
        <table class="tableStyle1" *ngIf="AFRdata.length != 0">
          <tr>
            <th colspan="2" style="font-size: 15px;color: yellow;text-align: center;border-radius: 10px;">Constrained</th>
            <th colspan="2" style="font-size: 15px;color: yellow;text-align: center;border-radius: 10px;">Recommended</th>
          </tr>
          <tr>
            <td style="font-size: 15px;color: yellow;">Temperature (°C)</td>
            <td style="font-size: 15px;color: yellow;">CO (PPM)</td>
            <td style="font-size: 15px;color: yellow;">Coal (TPH)</td>
            <td style="font-size: 15px;color: yellow;">AFR (M3/Hr)</td>
          </tr>
          <tr  *ngFor="let vval of AFRdata  ">
             <td>{{vval.temperature}}</td>
             <td>100</td>
             <td>- &nbsp;{{vval.coal_decreased}}</td>
             <td> + &nbsp;{{vval.afr_increased}}</td>
          </tr>

  

        
        </table>
        <table class="tableStyle1" *ngIf="AFRdata.length == 0">
          <tr>
            <th colspan="2" style="font-size: 15px;color: yellow;text-align: center;border-radius: 10px;">Constrained</th>
            <th colspan="2" style="font-size: 15px;color: yellow;text-align: center;border-radius: 10px;">Recommended</th>
          </tr>
          <tr>
            <td style="font-size: 15px;color: yellow;">Temperature (°C)</td>
            <td style="font-size: 15px;color: yellow;">CO (PPM)</td>
            <td style="font-size: 15px;color: yellow;">Coal (TPH)</td>
            <td style="font-size: 15px;color: yellow;">AFR (M3/Hr)</td>
          </tr>
    
          <tr  >
             <td colspan="4">No Data</td>
           
          </tr>

        
        </table>
      </div>
    </div>
    <div style="display: flex;flex-direction:row;justify-content: space-between;padding: 1%;
    margin-top: 1%;
">
<div>
  <span ><button mat-raised-button color="primary"
      (click)="onPreviousclick()"> Previous</button></span>
  <!-- <span *ngIf="this.selectedDatenext=='1660847400'"><button mat-raised-button  disabled style="border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;">
      Previous</button></span> -->
</div>
<button mat-raised-button color="primary" (click)="onNextclick()"> Next</button>
</div>
</div>

<div *ngIf="isLoading" style="height: 75vh; display: grid; width: 100%">
  <div style="margin: auto">
    <img
      src="../../../../assets/icons/Spinner.gif"
      style="width: 80px"
    />
    <p style="text-align: center;color: #fff;">Loading...</p>
  </div>
</div>
</section>
