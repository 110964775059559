import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from './models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('boilerUser'))
      
    );
    this.currentUser = this.currentUserSubject.asObservable();
    
  }

  public get currentUserValue(): User {
   
    return this.currentUserSubject.value;
    
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.baseUrl}auth/login`, { username, password })
      .pipe(
        map((user) => {
        
          if (user) {
            // console.log(user);
            // store user details in local storage to keep user logged in
            localStorage.setItem('boilerUser', JSON.stringify(user));
            this.currentUserSubject.next(user);                  
          }
          return user;
         
        })
      );
  }

  logout() {
    // remove user data from local storage for log out
    localStorage.removeItem('boilerUser');
    this.currentUserSubject.next(null);
  }

  tempLogin(): Observable<any> {
    const user = {
      username: "admin",
      password: "lns@123"
    }
    return this.http.post(`${environment.baseUrl}auth/login`, user)
      .pipe(map((response: any) => {
        return response
      }, (error: any) => {
        console.log(error);
      }
      ));
  }
  marketLogin(receivedToken:any): Observable<any> {
    // return this.http.get(`https://api.demo1.livnsense.com/dcu/get_algo_token?project_id=1`).pipe(
      // return this.http.get(`https://api.demo1.livnsense.com/dcu/get_algo_token?token=`+receivedToken).pipe(
      return this.http.get(`https://api.new-demo1.livnsense.com/dcu/get_algo_token?token=`+receivedToken).pipe(

      map(
        (response: any) => {
          return response;
        },
        ( err: any) => {
          console.log(err);
        }
      )
    );
  } 


  updateToken(body:any) {
    return this.http
      .post<any>(`https://api.new-demo1.livnsense.com/dcu/algo_token_post?action=D`, body);
      // .post<any>(`https://api.demo1.livnsense.com/dcu/algo_token_post?action=D`, body);
  }
}
