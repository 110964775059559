<section>
  <div class="mainGridRgt11"
    style="display:flex;flex-direction:row;justify-content:space-between;color: white; border-bottom: 2px solid;">
    <div class="asImgBoxTop pt-2">
      <div class="asImgBoxTopTxt">Kiln & AFR Savings Summary</div>
    </div>
    <div style="display: flex">
      <div class="mr-2 date-picker">
        <mat-form-field class="padding-test" appearance="outline">
          <mat-label style="color: white">Date</mat-label>
          <input style="color: white; font-size: 15px" matInput [min]="minDate" [max]="maxDate"
            [matDatepicker]="startdate" placeholder="Choose Start Date" [(ngModel)]="appliedDateFilters.start_date"
            [disabled]="false" />
          <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
          <mat-datepicker #startdate></mat-datepicker>
        </mat-form-field>
      </div>
      <button title="Select the date and click." style="
         font-size: 16px;
    width: 70px;
    cursor: pointer;
    background-color: #302c2c;
    outline: unset;
    /* margin-left: 4%; */
    /* padding-top: 2px; */
    border: 1px solid rgba(255, 255, 255, 0.38);
    height: 35px;
    " mat-raised-button color="primary" class="get-sm-btn" (click)="changeDate()">
        Apply
      </button>
    </div> 
      <!-- <div class="date-picker" style="width: 20%">
        <button title="Click to download the reports pdf." style="
              font-size: 16px;
              width: 50px;
              cursor: pointer;
              background-color: #302c2c;
              outline: unset;
              margin-left: 4%;
              padding-top: 2px;
              border: 1px solid rgba(255, 255, 255, 0.38);
              height: 35px;
            " class="get-sm-btn" (click)="getTableGraphValues();getTableGraphValues1();">
          <img src="../../../../assets/images/pdfimg.png" style="width:100%;height: 100%;" />
        </button>
      </div> -->
    <!-- </div> -->
  </div>

  <div *ngIf="!isLoading" style=" font-size: 13px;flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  height: 640px;">
  <div>
    <div style="display: flex;flex-direction: row; justify-content:space-between">
      <p class="mainGridtitle1" style=" color: #fff;text-align:end">Daywise Savings</p>
      <span style="display: flex;flex-direction: row; justify-content:flex-start">
        <p class="mainGridtitle1" style=" color: #fff;">Date:</p>
        <span style=" color: #fff;">
          <tr *ngFor="let vval of KilnrcmdData  ">
            <td>{{vval.obs_time | date:'MM/dd/yyyy'}}                 
                  </td>
            <!-- <td>Time -&nbsp;{{vval.obs_time | date:'HH:mm' }}
            </td> -->
          </tr>
        </span>
      </span>
  </div>
  </div>
    <div class="red1">
      <div class="row" style="padding: 1%;">
        <div class="col-md-12">
          <p class="mainGridtitle1" style="font-size: 20px;font-weight: 600;">Kiln Optimization</p>
          <table class="tableStyle1" *ngIf="KilnrcmdData.length != 0">
            <tr>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Parameter</th>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Actual</th>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Optimized</th>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Savings (%)</th>
            </tr>
            <tr *ngFor="let vval of KilnrcmdData  ">
              <th style="font-size: 16px;color: yellow;">Main Coal(Tons)</th>
              <td style="font-size: 16px;">{{vval.main_coal_actual}}</td>
              <td style="font-size: 16px;">{{vval.main_coal_optimized}}</td>
              <td style="font-size: 16px;">{{vval.main_coal_savings}}</td>
            </tr>
            <tr *ngFor="let vval of KilnrcmdData  ">
              <th style="font-size: 16px;color: yellow;">Kiln Speed(RPM)</th>
              <td style="font-size: 16px;">{{vval.kiln_actual}}</td>
              <td style="font-size: 16px;">{{vval.kiln_optimized}}</td>
              <td style="font-size: 16px;">{{vval.kiln_savings}}</td>
            </tr>
            <tr *ngFor="let vval of KilnrcmdData  ">
              <th style="font-size: 16px;color: yellow;">PA Fan Speed(RPM)</th>
              <td style="font-size: 16px;">{{vval.pa_fan_act}}</td>
              <td style="font-size: 16px;">{{vval.pa_fan_optimized}}</td>
              <td style="font-size: 16px;">{{vval.pa_savings}}</td>
            </tr>
            <tr *ngFor="let vval of KilnrcmdData  ">
              <th style="font-size: 16px;color: yellow;">Carbon Savings (Tons)</th>
              <td style="font-size: 16px;">{{vval.co2_actual}}</td>
              <td style="font-size: 16px;">{{vval.co2_optimized}}</td>
              <td style="font-size: 16px;">{{vval.co2_savings}}</td>
            </tr>
            <!-- <tr >
               <td>{{vval.temperature}}</td>
               <td>100</td>
               <td>- &nbsp;{{vval.coal_decreased}}</td>
               <td> + &nbsp;{{vval.afr_increased}}</td>
            </tr>
            <tr >
              <td>{{vval.temperature}}</td>
              <td>100</td>
              <td>- &nbsp;{{vval.coal_decreased}}</td>
              <td> + &nbsp;{{vval.afr_increased}}</td>
           </tr> -->
          </table>

          <table class="tableStyle1" *ngIf="KilnrcmdData.length == 0">
            <tr>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Parameter</th>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Actual</th>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Optimized</th>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Savings (%)</th>
            </tr>
            <tr>
              <th style="font-size: 16px;color: yellow;">Main Coal(Tons)</th>
              <td colspan="4">No Data</td>
            </tr>
            <tr>
              <th style="font-size: 16px;color: yellow;">Kiln Speed(RPM)</th>
              <td colspan="4">No Data</td>
            </tr>
            <tr>
              <th style="font-size: 16px;color: yellow;">PA Fan Speed(RPM)</th>
              <td colspan="4">No Data</td>
            </tr>
            <tr>
              <th style="font-size: 16px;color: yellow;">Carbon Savings (Tons)</th>
              <td colspan="4">No Data</td>
            </tr>
          </table>
        </div>
      </div>
      </div>
  
        <!-- <hr style="    margin-top: 10px !important;
        margin-bottom: 7px !important;
    "> -->
    <div class="red1">
      <div class="row" style="padding: 1%;">
        <div class="col-md-12">
          <p class="mainGridtitle1" style="font-size: 20px;font-weight: 600;">AFR Optimization</p>
          <table class="tableStyle1" *ngIf="AfrOptData.length != 0">
            <tr>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Parameter</th>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Actual</th>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Optimized</th>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Savings (%)</th>
            </tr>
            <tr *ngFor="let vval of AfrOptData  ">
              <th style="font-size: 16px;color: yellow;">PC Coal (Tons)</th>
              <td style="font-size: 16px;">{{vval.pc_coal_actual}}</td>
              <td style="font-size: 16px;">{{vval.pc_coal_optimized}}</td>
              <td style="font-size: 16px;">{{vval.pc_coal_savings}}</td>
            </tr>
            <tr *ngFor="let vval of AfrOptData  ">
              <th style="font-size: 16px;color: yellow;">AFR</th>
              <td style="font-size: 16px;">{{vval.afr_actual}}</td>
              <td style="font-size: 16px;">{{vval.afr_recom}}</td>
              <td style="font-size: 16px;">{{vval.afr_savings}}</td>
            </tr>
            <tr *ngFor="let vval of AfrOptData  ">
              <th style="font-size: 16px;color: yellow;">Carbon Savings (Tons)</th>
              <td style="font-size: 16px;">{{vval.co2_actual}}</td>
              <td style="font-size: 16px;">{{vval.co2_optimized}}</td>
              <td style="font-size: 16px;">{{vval.co2_savings}}</td>
            </tr>
            <!-- <tr  >
               <td>{{vval.temperature}}</td>
               <td>100</td>
               <td>- &nbsp;{{vval.coal_decreased}}</td>
               <td> + &nbsp;{{vval.afr_increased}}</td>
            </tr>
            <tr  >
              <td>{{vval.temperature}}</td>
              <td>100</td>
              <td>- &nbsp;{{vval.coal_decreased}}</td>
              <td> + &nbsp;{{vval.afr_increased}}</td>
           </tr> -->
          </table>

          <table class="tableStyle1" *ngIf="AfrOptData.length == 0">
            <tr>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Parameter</th>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Actual</th>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Optimized</th>
              <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Savings (%)</th>
            </tr>
            <tr>
              <th style="font-size: 16px;color: yellow;">PC Coal (Tons)</th>
              <td colspan="3">No Data</td>
            </tr>
            <tr>
              <th style="font-size: 16px;color: yellow;">AFR</th>
              <td colspan="3">No Data</td>
            </tr>
            <tr>
              <th style="font-size: 16px;color: yellow;">Carbon Savings (Tons)</th>
              <td colspan="3">No Data</td>
            </tr>
          </table>
        </div>
      </div>
      </div>

    <!-- <div style="display: flex;flex-direction:row;justify-content: space-between;padding: 1%;
          margin-top: 1%;
      ">
      <div>
        <span ><button mat-raised-button color="primary"
            (click)="onPrevClick()"> Previous</button></span> -->
        <!-- <span *ngIf="this.selectedDatenext=='1660847400'"><button mat-raised-button disabled style="border: 1px solid #999999;
          background-color: #cccccc;
          color: #666666;">
            Previous</button></span> -->
      <!-- </div>
      <button mat-raised-button color="primary" (click)="onNextclick()"> Next</button>
    </div> -->
  </div>

  <div *ngIf="isLoading" style="height: 75vh; display: grid; width: 100%">
    <div style="margin: auto">
      <img
        src="../../../../assets/icons/Spinner.gif"
        style="width: 80px"
      />
      <p style="text-align: center;color: #fff;">Loading...</p>
    </div>
  </div>
</section>
