import { PlotChartsService } from './../../../core/charts/plot-charts.service';
// import { BalcoService } from './../../../core/balco.service';
import { APiService } from 'src/app/core/balco.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { UtilsService } from 'src/app/core/utils.service';

am4core.useTheme(am4themes_animated);
@Component({
  selector: 'app-theme4',
  templateUrl: './theme4.component.html',
  styleUrls: ['./theme4.component.scss']
})
export class Theme4Component implements OnInit {

  today: number = Date.now();
  kips: any;
  Apilabel: any = 'Actual vs Predicted PO4';
  label: any;
  values: any;
  listOfGraphData = [];
  alumFormVal: any;
  alumTapVal: any;
  anodeVal: any;
  temperatureVal: any;
  errMesage;
  submitted: boolean;
  potNo: any;
  selectProductType = [
    'Actual vs Predicted PO4',
    'Actual vs Predicted pH',
  ];
  selectedValue;
  xalfs: any;
  temps: any;
  boilerNo: any;
  actualVal: any;
  receivedData = [];
  receivedData1 = [];
  selectedGraphAFR = '';
  tableSettings: any = [];
  graphTableValues: any = [];
  graphValue: any;
  // appliedDateFilters: any = {};
  // maxDate: Date = new Date();
  AFRdata: any = [];
  AFRdata1: any = [];
  Coaldata: any = [];
  startRealTime = '1660847400';

  graphValueafr: any = [];
  selectedDatenext: any;

  appliedDateFilters: any = {};
  maxDate: Date = new Date('06/22/2023');
  minDate:  Date = new Date('04/01/2023');
  startDate: any;
  isLoading: boolean = true;


  constructor(private ApicallService: APiService,
    private plotChartsService: PlotChartsService,
    public dialog: MatDialog, readonly utils: UtilsService,) {
    this.ApicallService.boilerNo.subscribe((res) => {
      this.boilerNo = res;
    });


  }

  ngOnInit() {
    this.isLoading = true;
    if (this.utils.dateFilter) {
      this.appliedDateFilters["start_date"] = new Date(
        this.utils.dateFilter["start_date"]
      );
    } else {
      // this.appliedDateFilters["start_date"] = new Date(
      //    new Date().setDate(new Date().getDate())
      //    ).setHours(0, 0, 0, 0);
         this.appliedDateFilters["start_date"]= this.maxDate;
    }
    console.log(this.appliedDateFilters['start_date'] ,"date1")
   
    this.getAFRTable();
    this.getAFRTable1();
    this.getCOALTable();
    this.getAFrGraph();
    setTimeout(() => {
      this.isLoading = false;
    }, 100);
  }
  changeDate(){
    this.appliedDateFilters["start_date"] = this.appliedDateFilters.start_date;
    this.utils.dateFilter = this.appliedDateFilters;
    console.log(this.appliedDateFilters['start_date'] ,
    this.appliedDateFilters.start_date,
    "changedata")
    // this.startDate
    this.getAFRTable();
    this.getAFrGraph();
  
    // this.getClinker();

  }

  getAFRTable() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    this.ApicallService.getAFRTableValues(this.startDate).subscribe(
      (data: any) => {
        this.AFRdata = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }

  getAFRTable1() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    this.ApicallService.getAFRTableValues1(this.startDate).subscribe(
      (data: any) => {
        this.AFRdata1 = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }
  getCOALTable() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    this.ApicallService.getCoalTableValues(this.startDate).subscribe(
      (data: any) => {
        this.Coaldata = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }

  getAFrGraph() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    let ref = this;
    ref.plotChartsService.getAfrGraphData(this.startDate).subscribe((res) => {
      ref.graphValueafr = res;
      ref.graphValueafr.map((cvalue: any) => {
        let dt = cvalue.obs_time;
        let forcast = cvalue.forecast_cyc_6_avg_temp;
        let temp = cvalue.cyc_6_avg_temp;
      })
      // console.log("jhbh", ref.graphValueafr)
      ref.drawAFRChart();
      // ref.drawFC2Chart();

    });
  }

 

  drawAFRChart() {
    setTimeout(() => {
      let ref = this;
      am4core.useTheme(am4themes_animated);
      // Themes end

      // Create chart instance
      let chart = am4core.create("ppediv", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.paddingRight = 25;
      chart.background.fill = am4core.color('#1e1e1e');
      // chart.tooltip.label.fill = am4core.color("#fff");

      // Add data
      chart.data = ref.graphValueafr;
      
      

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "obs_time";
      categoryAxis.title.text = "Timestamp";
      categoryAxis.title.fill = am4core.color("#FFF");
      // categoryAxis.title.fontSize = 10;
      // categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.labels.template.fill = am4core.color('#fff');
      categoryAxis.renderer.minGridDistance = 50;
      categoryAxis.renderer.labels.template.fontSize = 12;
      chart.cursor = new am4charts.XYCursor();
      chart.cursor.xAxis = categoryAxis;
      
      // categoryAxis.tooltipDateFormat = 'd MMM, hh-mm a';
      // categoryAxis.renderer.grid.template.stroke = "#fff";
      // categoryAxis.renderer.cellStartLocation = 0.1;
      // categoryAxis.renderer.cellEndLocation = 0.9;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.title.text = "FlowRate";
      valueAxis.title.fill = am4core.color("#FFF");
      valueAxis.title.fontWeight = 'bold';
      valueAxis.title.fontSize = 12;
      valueAxis.renderer.labels.template.fontSize = 12;
      valueAxis.renderer.labels.template.fill = am4core.color('#fff');

      // Create series
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "coal_decreased";
      series.dataFields.categoryX = "obs_time";
      series.tooltipText = "Coal Decreased" + `:{${"coal_decreased"}}`;
      series.strokeWidth = 5;
      series.stroke = am4core.color("#00cccc");
      series.fill = am4core.color("#00cccc");
      // series.tooltip.label.propertyFields.fill = "#fff";
      series.legendSettings.valueText = "[#fff font-size: 12px] Coal Decreased [/]";
      // series.label.text ="{valueY}";

      let series1 = chart.series.push(new am4charts.LineSeries());
      series1.dataFields.valueY = "afr_increased";
      series1.dataFields.categoryX = "obs_time";
      series1.tooltipText = "AFR Increased" + `:{${"afr_increased"}}`;
      series1.strokeWidth = 5;
      series1.stroke = am4core.color("#b3ff66");
      series1.fill = am4core.color("#b3ff66");
      // series1.legendSettings.labelText = "AFR Increased [bold][/]";
      series1.legendSettings.valueText =

      "[#fff font-size: 12px] AFR Increased [/]";


      // series1.legendSettings.labelText = "[{stroke} {name}][/]";
      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = "bottom";

      //Add scrollbar
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.parent = chart.topAxesContainer
      chart.scrollbarX.startGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.endGrip.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.thumb.background.fill = am4core.color("#302C2C");
      chart.scrollbarX.startGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.endGrip.icon.stroke = am4core.color("#fff");
      chart.scrollbarX.minHeight = 7;
      // Applied on hover
      chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("rgb(64, 60, 60)");
      // Applied on mouse down
      chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");
      chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("rgb(64, 60, 60)");


      chart.tooltip.label.maxWidth = 150;
      chart.tooltip.label.wrap = true;


      // ref.loaded = true;
      // ref.isLoading=false;

    }, 100);
  }

  onNextclick() {
    console.log(this.startDate, "5mns")
    this.startDate = parseInt(this.startDate) + 600;
    console.log(this.startDate)
    this.ApicallService.getAFRTableValues(this.startDate).subscribe(
      (data: any) => {
        this.AFRdata = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);
      }
    )

  }
  onPreviousclick() {
    this.selectedDatenext = parseInt(this.selectedDatenext) - 600;
    console.log(this.startDate, "5mns")
    this.startDate = parseInt(this.startDate) - 600;
    console.log(this.startDate)
    // // console.log(this.onclicknext)
    this.ApicallService.getAFRTableValues(this.startDate).subscribe(
      (data: any) => {
        this.AFRdata = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);
      }
    )

  }

}
