// import { Theme3Component } from './pages/components/theme3/theme3.component';
// import { Theme2Component } from './pages/components/theme2/theme2.component';
// import { Theme1Component } from './pages/components/theme1/theme1.component';

import { Theme1Component } from './pages/components/theme1/theme1.component';
import { Theme2Component } from './pages/components/theme2/theme2.component';
import { Theme3Component } from './pages/components/theme3/theme3.component';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { FooterComponent } from './components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SnackbarComponent } from './core/snackbar/snackbar.component';
import { AlertboxComponent } from './components/alertbox/alertbox.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MushroomPredictionComponent } from './pages/components/mushroom-prediction/mushroom-prediction.component';
import { MarketplaceloginComponent } from './pages/marketplacelogin/marketplacelogin.component';

import { Theme4Component } from './pages/components/theme4/theme4.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { Theme5Component } from './pages/components/theme5/theme5.component';
import { DialogboxComponent } from './components/dialogbox/dialogbox.component';
import { Theme6Component } from './pages/components/theme6/theme6.component';
import { Theme7Component } from './pages/components/theme7/theme7.component';
import { Theme8Component } from './pages/components/theme8/theme8.component';
import { Navbar1Component } from './components/navbar copy/navbar1.component';
import { Theme9Component } from './pages/components/theme9/theme9.component';
import { Theme10Component } from './pages/components/theme10/theme10.component';





@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    NavbarComponent,
    FooterComponent,

    Theme1Component,
    Theme2Component,
    Theme3Component,

    SnackbarComponent,
    AlertboxComponent,
    MushroomPredictionComponent,
    MarketplaceloginComponent,

    Theme4Component,
    Theme5Component,
    DialogboxComponent,
    Theme6Component,
    Theme7Component,
    Theme8Component,
    Navbar1Component,
    Theme9Component,
    Theme10Component,


  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    // ChartsModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatButtonModule,
    MatInputModule,

    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [Theme1Component, Theme2Component, Theme3Component],
})
export class AppModule { }
