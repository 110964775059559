import { APiService } from 'src/app/core/balco.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlotChartsService } from 'src/app/core/charts/plot-charts.service';
import { DialogboxComponent } from 'src/app/components/dialogbox/dialogbox.component';
// import { setInterval } from 'timers';

@Component({
  selector: 'app-theme6',
  templateUrl: './theme6.component.html',
  styleUrls: ['./theme6.component.scss'],
})
export class Theme6Component implements OnInit {
  [x: string]: any;
  isLoading: boolean = true;

  Realtimedata: any = [];
  Forecastdata: any = [];

  Forecastalertdata: any = [];
  selectedDate: any;
  selectedDatenext: any;
  selectedForecastDatenext: any;
  selectedDateprevious: any;
  AFRdata1: any = [];
  Coaldata: any = [];

  constructor(
    private toastr: ToastrService,
    private ApicallService: APiService,
    private object: FormBuilder,
    public dialog: MatDialog,
    private plotChartsService: PlotChartsService,
  ) {
  }

  openDialog() {
    this.ApicallService.getForecastAlertTableValues(this.selectedDatenext).subscribe(
      (data: any) => {
        this.Forecastalertdata = data;
        // // console.log(this.Forecastdata, "data")
        // const dialogRef = this.dialog.open(DialogboxComponent);
        let dialogRef = this.dialog.open(DialogboxComponent, {
          width: '600px',
          height: '400px',
          data: { TableData: this.Forecastalertdata, clicked: 0 },
          // data: {graphData: this.modelGraphValue.slice(900), label:label, plot:plot},  
        });
        dialogRef.afterClosed().subscribe(result => {
          // console.log('The dialog was closed');
        });
      },
      (err) => {
        // (err);

      }
    )
  }
  openDialog1() {
    this.ApicallService.getForecastAlertTableValues(this.selectedDatenext).subscribe(
      (data: any) => {
        this.Forecastalertdata = data;
        // // console.log(this.Forecastdata, "data")
        // const dialogRef = this.dialog.open(DialogboxComponent);
        let dialogRef = this.dialog.open(DialogboxComponent, {
          width: '600px',
          height: '400px',
          data: { TableData1: this.Forecastalertdata, clicked: 1 },
          // data: {graphData: this.modelGraphValue.slice(900), label:label, plot:plot},  
        });
        dialogRef.afterClosed().subscribe(result => {
          // console.log('The dialog was closed');
        });
      },
      (err) => {
        // (err);

      }
    )
  }

  openDialog2() {
    this.ApicallService.getForecastAlertTableValues(this.selectedDatenext).subscribe(
      (data: any) => {
        this.Forecastalertdata = data;
        // // console.log(this.Forecastdata, "data")
        // const dialogRef = this.dialog.open(DialogboxComponent);
        let dialogRef = this.dialog.open(DialogboxComponent, {
          width: '600px',
          height: '400px',
          data: { TableData2: this.Forecastalertdata, clicked: 2 },
          // data: {graphData: this.modelGraphValue.slice(900), label:label, plot:plot},  
        });
        dialogRef.afterClosed().subscribe(result => {
          // console.log('The dialog was closed');
        });
      },
      (err) => {
        // (err);

      }
    )
  }


  ngOnInit() {
    this.isLoading = true;
    this.appliedDateFilters = new Date(new Date().setHours(0, 0, 0, 0));
    let startDate = new Date(new Date(this.appliedDateFilters).setHours(0, 0, 0, 0)).getTime() / 1000;
    this.selectedDatenext = startDate;
    console.log(this.appliedDateFilters, startDate)
    // this.selectedDatenext='1660847400';
    this.selectedForecastDatenext = parseInt(this.selectedDatenext) + 300;
    setInterval(() => {
      this.getTable();
      this.getforecastTable();

    }, 3000); // 5 minutes in milliseconds  }

    // this.getforecastTable();
    this.getforecastAlertTable();
    this.getCOALTable();
    this.getAFRTable1();
    this.getUsers();
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);

  }

  // delete
  openDialog3(user) {
    let dialogRef = this.dialog.open(DialogboxComponent, {
      width: '400px',
      height: '80px',
      data: { TableData3: user, clicked: 3 },
    });
    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {
        this.getUsers();
      }, 2000);

      // console.log('The dialog was closed');
    });

  }

  openDialog4(user1, user) {
    let dialogRef = this.dialog.open(DialogboxComponent, {
      width: '600px',
      height: '400px',
      data: { TableData4: user1, tableval: user, clicked: 4 },
    });
    dialogRef.afterClosed().subscribe(result => {
      setTimeout(() => {
        this.getUsers();
      }, 2000);

      // console.log('The dialog was closed');
    });

  }

  // openDialog4() {
  //   this.ApicallService.getForecastAlertTableValues(this.selectedDatenext).subscribe(
  //     (data: any) => {
  //       this.Forecastalertdata = data;
  //       // // console.log(this.Forecastdata, "data")
  //       // const dialogRef = this.dialog.open(DialogboxComponent);
  //       let dialogRef = this.dialog.open(DialogboxComponent, {
  //         width: '600px',
  //         height:'400px',
  //         data: {TableData2: this.Forecastalertdata,clicked:2},
  //         // data: {graphData: this.modelGraphValue.slice(900), label:label, plot:plot},  
  //       });
  //       dialogRef.afterClosed().subscribe(result => {
  //         // console.log('The dialog was closed');
  //       });
  //     },
  //     (err) => {
  //       // (err);

  //     }
  //   )
  // }









  // getAllUsers

  getUsers() {
    // getAllUsers
    this.ApicallService.getAllUsers().subscribe(
      (data: any) => {
        this.UserData = data;
        // console.log( "data")
      },
      (err) => {
        // (err);

      }
    )
  }

  refreshUserList() {
    this.ApicallService.getAllUsers().subscribe((data: any) => {
      this.UserData = data;
      console.log("clcked")
    });
  }

  getTable() {
    // this.ApicallService.getTableValues().subscribe(
    //   (data: any) => {
    //     this.Realtimedata = data;
    //     // console.log(this.Realtimedata, "data")
    //   },
    //   (err) => {
    //     // (err);

    //   }
    // )
  }


  getforecastTable() {
    this.ApicallService.getForecastTableValues(this.selectedForecastDatenext).subscribe(
      (data: any) => {
        this.Forecastdata = data;
        // // console.log(this.Forecastdata, "data")
      },
      (err) => {
        // (err);

      }
    )
  }
  getforecastAlertTable() {
    this.ApicallService.getForecastAlertTableValues(this.selectedDatenext).subscribe(
      (data: any) => {
        this.Forecastalertdata = data;
        // // console.log(this.Forecastdata, "data")
      },
      (err) => {
        // (err);

      }
    )
  }
  // changeDropdown(types:any) {  
  //   this.selectedDatenext=types.target.value
  //   console.log(this.selectedDatenext)
  //   this.ApicallService.getTableValues(types.target.value).subscribe(
  //     (data: any) => {
  //       this.Realtimedata = data;
  //       // // console.log(this.Realtimedata, "data")
  //     },
  //     (err) => {
  //       // (err);
  //     }
  //   )
  //   this.selectedForecastDatenext = parseInt(types.target.value) +300;
  //   this.ApicallService.getForecastTableValues(this.selectedForecastDatenext).subscribe(
  //     (data: any) => {
  //       this.Forecastdata = data;
  //       // // console.log(this.Forecastdata, "data")
  //     },
  //     (err) => {
  //       // (err);

  //     }
  //   )
  //   this.ApicallService.getAFRTableValues1(this.selectedDatenext).subscribe(
  //     (data: any) => {
  //       this.AFRdata1 = data;
  //       // // console.log(this.AFRdata, "data")
  //     },
  //     (err) => {
  //       // (err);

  //     }
  //   )

  //   this.ApicallService.getCoalTableValues(this.selectedDatenext).subscribe(
  //     (data: any) => {
  //       this.Coaldata = data;
  //       // // console.log(this.AFRdata, "data")
  //     },
  //     (err) => {
  //       // (err);

  //     }
  //   )
  //   this.getforecastAlertTable() ;
  // }
  getAFRTable1() {
    this.ApicallService.getAFRTableValues1(this.selectedDatenext).subscribe(
      (data: any) => {
        this.AFRdata1 = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }
  getCOALTable() {
    this.ApicallService.getCoalTableValues(this.selectedDatenext).subscribe(
      (data: any) => {
        this.Coaldata = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }
  onNextclick() {
    // console.log(this.startRealTime)getTableValues(starttime: any)
    this.selectedDatenext = parseInt(this.selectedDatenext) + 300;
    console.log(this.selectedDatenext)
    // this.ApicallService.getTableValues().subscribe(
    //   (data: any) => {
    //     this.Realtimedata = data;
    //   },
    //   (err) => {
    //     // (err);
    //   }
    // )
    // this.onclicknext = parseInt(this.onclicknext) + 300;
    this.selectedForecastDatenext = parseInt(this.selectedForecastDatenext) + 300;
    this.ApicallService.getForecastTableValues(this.selectedForecastDatenext).subscribe(
      (data: any) => {
        this.Forecastdata = data;
      },
      (err) => {
        // (err);
      }
    )
    this.getforecastAlertTable();
  }
  onPreviousclick() {
    this.selectedDatenext = parseInt(this.selectedDatenext) - 300;
    console.log(this.selectedDatenext)
    // this.ApicallService.getTableValues().subscribe(
    //   (data: any) => {
    //     this.Realtimedata = data;
    //   },
    //   (err) => {
    //     // (err);
    //   }
    // )
    this.selectedForecastDatenext = parseInt(this.selectedForecastDatenext) - 300;
    console.log(this.selectedForecastDatenext)
    this.ApicallService.getForecastTableValues(this.selectedForecastDatenext).subscribe(

      (data: any) => {
        this.Forecastdata = data;
      },
      (err) => {
        // (err);
      }
    )
    this.getforecastAlertTable();
  }






}

