<section>
  <div class="mainGridRgtTitl" style="display:flex;flex-direction:row;justify-content:space-between;padding-bottom: 1%;margin-bottom: 1%;">
    File Upload
 </div>
 


  <div class="theme1PlotBox">
    <span style="display: flex;flex-direction: row;justify-content: space-between;">
    <p style="color: #fff;
    font-weight: bold;
    text-decoration: underline;
    font-size: 20px;
   
    ">Upload File</p>
       <select class="global_dropdown" style=" max-width: max-content;
    
       padding: 10px;  
       font-size: 13px;
       padding-top: 8px;
       padding-bottom: 8px;
    
       "
      (change)="changeDropdown($event)">
     <option  value="kiln_quality">
     Kiln Quantity
     </option>
     <option  value="coal_quality">
       Coal Quantity
     </option>
     </select>
    </span>
    <div class="row">
      <div  style="font-weight: bold; margin-left: 2px; color:white; font-size: 17px; display: flex;flex-direction: row;justify-content: space-between;padding: 15px;">
     
     
 
 
<input type="file" (change)="getFile($event)" />

<button mat-raised-button color="primary" (click)="submitData()"> Upload File</button>




      </div>

      <div class="row" style="padding: 1%;">
        <div class="col-md-12">
          <div style="text-align: center;color: green;font-size: 14px;" *ngFor="let vval of apiresp  ">
         
            <div *ngIf="vval.message">
              <label class="per">{{vval.message}}</label>
            </div>
            <div *ngIf="!vval.message"><label class="per">{{vval.message}}</label></div>
          </div>
          <div style="text-align: center;color: red;font-size: 14px;"  *ngIf="error">
            <p > {{error}} </p> 
       
          </div>
        </div>
        
      
      
      </div>
  <!-- <span>


    <img src="../../../../assets/images/file-upload-icon.png" style="
    margin-left: 38%;
    height: 250px;
"/>
  </span> -->
  
    </div>
   
  </div>
  <span>


    <img src="../../../../assets/images/file-upload-icon.png" style="
    margin-left: 38%;
    height: 300px;
    margin-top: 5%;
"/>
  </span> 
  
</section>