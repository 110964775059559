<section>
    <div>
        <div class="asImgBoxTop">
            <div class="asImgBoxTopTxt" style="color: #fff;">
                User Management
            </div>
            <div>
                <button (click)="openDialog2()" style="font-size: 12px;  width:150px; background-color: #302C2C; outline: unset;
          border: 1px solid rgba(255, 255, 255, 0.38);">+ Add User</button>
            </div>
        </div>

        <div *ngIf="!isLoading" style=" font-size: 13px;">
            <div style="display:flex ;flex-direction: row;justify-content: space-between;">
                <p class="mainGridtitle1">Manage Users
                </p>
            </div>
            <div>
                <div class="row" style="padding: 1%;">
                    <div class="fixTableHead" id="style-1" >
                    <table>
                        <thead style="color: yellow;">
                            <tr>
                                <th>S.No.</th>
                                <th>Username</th>
                                <th>Email Id</th>
                                <th>Role</th>
                                <th>Mobile No.</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr *ngFor="let userVal of UserData;
                            index as i">
                                          <td>{{i+1}}</td>
                                          <td>{{userVal.username}}</td>
                                          <td>{{userVal.email}}</td>
                                          <td>{{userVal.role}}</td>
                                          <td>{{userVal.mobile_no}}</td>
                                          <td><span style="display: flex;flex-direction: row;justify-content: space-between;">
                                                  <button (click)="openDialog4(userVal.username,userVal)" style="
                                   display: flex;
                                    flex-direction: row;
                                     justify-content: center;
                                     align-items: center;
                                      padding: 0.8em;
                                       border: 0px solid transparent;
                                        background-color: rgba(100,77,237,0.08); 
                                        border-radius: 1.25em;
                                         transition: all 0.2s linear;">
                                                      <i class="bi bi-pencil-fill"></i></button>
                                                  <button (click)="openDialog3(userVal.username)" style=" 
                                          display: flex;
                                          flex-direction: row;
                                          justify-content: center;
                                           align-items: center; 
                                           padding: 0.8em;
                                           border: 0px solid transparent;
                                            background-color: rgba(100,77,237,0.08); 
                                             border-radius: 1.25em;
                                             transition: all 0.2s linear;
                                             "><i class="bi bi-eraser-fill"></i></button></span>
                                          </td>
                                      </tr>
                        </tbody>
                        <!-- <tbody>
                          <tr style="height: 235px;text-align: center;">
                            <td colspan="3">No Alerts</td>
                          </tr>
                        </tbody> -->
              
                      </table>
                    </div>
<!-- 
                    <table class="tableStyle">
                        <tr>
                            <th>S.No.</th>
                            <th>Username</th>
                            <th>Email Id</th>
                            <th>Role</th>
                            <th>Mobile No.</th>
                            <th>Actions</th>
                        </tr>
                        <tr *ngFor="let userVal of UserData;
              index as i">
                            <td>{{i+1}}</td>
                            <td>{{userVal.username}}</td>
                            <td>{{userVal.email}}</td>
                            <td>{{userVal.role}}</td>
                            <td>{{userVal.mobile_no}}</td>
                            <td><span>
                                    <button (click)="openDialog4(userVal.username,userVal)" style="
                     display: flex;
                      flex-direction: row;
                       justify-content: center;
                       align-items: center;
                        padding: 0.8em;
                         border: 0px solid transparent;
                          background-color: rgba(100,77,237,0.08); 
                          border-radius: 1.25em;
                           transition: all 0.2s linear;">
                                        <i class="bi bi-pencil-fill"></i></button>
                                    <button (click)="openDialog3(userVal.username)" style=" 
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                             align-items: center; 
                             padding: 0.8em;
                             border: 0px solid transparent;
                              background-color: rgba(100,77,237,0.08); 
                               border-radius: 1.25em;
                               transition: all 0.2s linear;
                               "><i class="bi bi-eraser-fill"></i></button></span>
                            </td>
                        </tr>

                    </table> -->
                  
                    <!-- <div class="col-md-6">
              <div class="red" style="border-radius: 6px;
              box-shadow: inset 7px 7px 14px black,
                          inset -7px -7px 14px #5a5a5a;">
                <label for="temp">Cyc-6 outlet CO (PPM)</label>
                <div>
                  <button (click)="openDialog1()" style="font-size: 16px;  width:150px; background-color: #302C2C; outline: unset;
                  border: 1px solid rgba(255, 255, 255, 0.38);">Click Here</button>
                </div>
              </div>
            </div> -->
                </div>
            </div>
        </div>

        <div *ngIf="isLoading" style="height: 75vh; display: grid; width: 100%">
            <div style="margin: auto">
              <img
                src="../../../../assets/icons/Spinner.gif"
                style="width: 80px"
              />
              <p style="text-align: center;color: #fff;">Loading...</p>
            </div>
          </div>

    </div>
</section>