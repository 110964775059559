import { APiService } from 'src/app/core/balco.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlotChartsService } from 'src/app/core/charts/plot-charts.service';
import { DialogboxComponent } from 'src/app/components/dialogbox/dialogbox.component';
// import { setInterval } from 'timers';
import { UtilsService } from 'src/app/core/utils.service';
@Component({
  selector: 'app-theme2',
  templateUrl: './theme2.component.html',
  styleUrls: ['./theme2.component.scss'],
})
export class Theme2Component implements OnInit {
  [x: string]: any;
  isLoading: boolean = true;

  Realtimedata: any = [];
  Forecastdata: any = [];
  Forecastalertdata: any = [];
  selectedDate: any;
  selectedDatenext: any;
  selectedForecastDatenext: any;
  selectedDateprevious: any;
  AFRdata1: any = [];
  Coaldata: any = [];
  status1: any;
  valuestatus: any;
  checkstatus: any;
  coloval: any;
  Realtimedatadt: any;
  Forecastalertdatadt: any;
  Forecastdatatemp: any;
  ForecastdatatempAvg: any;
  appliedDateFilters: any = {};
  maxDate: Date = new Date('06/22/2023');
  minDate:  Date = new Date('04/01/2023');



  constructor(
    private toastr: ToastrService,
    private ApicallService: APiService,
    private object: FormBuilder,
    public dialog: MatDialog,
    private plotChartsService: PlotChartsService,
    readonly utils: UtilsService,
  ) {
  }

  openDialog() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
  console.log( this.startDate, "forecast")
    this.ApicallService.getForecastAlertTableValues(this.startDate).subscribe(
      (data: any) => {
        this.Forecastalertdata = data;
        // // console.log(this.Forecastdata, "data")
        // const dialogRef = this.dialog.open(DialogboxComponent);
        let dialogRef = this.dialog.open(DialogboxComponent, {
          width: '700px',
          height: '280px',
          data: { TableData: this.Forecastalertdata, clicked: 0 },
          // data: {graphData: this.modelGraphValue.slice(900), label:label, plot:plot},  
        });
        dialogRef.afterClosed().subscribe(result => {
          // console.log('The dialog was closed');
        });
      },
      (err) => {
        // (err);

      }
    )
  }
  openDialog1() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    this.ApicallService.getForecastAlertTableValues(this.startDate).subscribe(
      (data: any) => {
        this.Forecastalertdata = data;
        // // console.log(this.Forecastdata, "data")
        // const dialogRef = this.dialog.open(DialogboxComponent);
        let dialogRef = this.dialog.open(DialogboxComponent, {
          width: '700px',
          height: '280px',
          data: { TableData1: this.Forecastalertdata, clicked: 1 },
          // data: {graphData: this.modelGraphValue.slice(900), label:label, plot:plot},  
        });
        dialogRef.afterClosed().subscribe(result => {
          // console.log('The dialog was closed');
        });
      },
      (err) => {
        // (err);

      }
    )
  }

  changeDate(){
    this.appliedDateFilters["start_date"] = this.appliedDateFilters.start_date;
    this.utils.dateFilter = this.appliedDateFilters;
    console.log(this.appliedDateFilters['start_date'] ,
    this.appliedDateFilters.start_date,
    "changedata")
    this.getTable();
    this.getforecastAlertTable();
    this. getAFRTable1();
    this.getCOALTable();
    // this.ApicallService.getForecastAlertTableValues( this.startDate).subscribe(
    //   (data: any) => {
    //     this.Forecastalertdata = data;
    //   },
    //   (err) => {
    //     // (err);

    //   }
    // );
    // this.ApicallService.getAFRTableValues1(this.startDate).subscribe(
    //   (data: any) => {
    //     this.AFRdata1 = data;
    //     // // console.log(this.AFRdata, "data")
    //   },
    //   (err) => {
    //     // (err);

    //   }
    // );
    // this.ApicallService.getCoalTableValues(this.startDate).subscribe(
    //   (data: any) => {
    //     this.Coaldata = data;
    //     // // console.log(this.AFRdata, "data")
    //   },
    //   (err) => {
    //     // (err);

    //   }
    // );
  }

  ngOnInit() {
    this.isLoading = true;
    if (this.utils.dateFilter) {
      this.appliedDateFilters["start_date"] = new Date(
        this.utils.dateFilter["start_date"]
      );
    } else {
      // this.appliedDateFilters["start_date"] = new Date(
      //    new Date().setDate(new Date().getDate())
      //    ).setHours(0, 0, 0, 0);
         this.appliedDateFilters["start_date"]= this.maxDate;
    }
    console.log(this.appliedDateFilters['start_date'] ,"date1")
    // setTimeout(()=>{                           
      // this.getClinker();
      this.getforecastAlertTable();
      this.getCOALTable();
      this.getAFRTable1();
      this.getTable();
      this.getforecastTable();
      this.getStatusApi();
        // }, 500);
    // this.appliedDateFilters = new Date(new Date().setHours(0, 0, 0, 0));
    // this.startDate = new Date(new Date(this.appliedDateFilters).setHours(0, 0, 0, 0)).getTime() / 1000;
    // this.selectedDatenext = startDate;
    // console.log(this.appliedDateFilters, startDate)
    // // this.selectedDatenext='1660847400';
    this.selectedForecastDatenext = parseInt(this.selectedDatenext) + 300;
    // setInterval(() => {
    //   this.getTable();
    //   this.getforecastTable();
    //   this.getStatusApi();

    // }, 120000); // 5 minutes in milliseconds  }

    // setInterval
    //   (() => {
    //     this.getTable();

    //   }, 50000);

    // this.getforecastTable();
    this.getforecastAlertTable();
    this.getCOALTable();
    this.getAFRTable1();
    this.getTable();
    this.getforecastTable();
    this.getStatusApi();
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);

  }

  getTable() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
  console.log(  this.startDate, "forecastlin")
    this.ApicallService.getTableValues( this.startDate ).subscribe(
      (data: any) => {
        this.Realtimedata = data;
        this.Realtimedatadt = this.Realtimedata[0].dateandtime
        // console.log(this.Realtimedata,this.Realtimedata[0].dateandtime)
      },
      (err) => {
        // (err);

      }
    )
  }

  getStatusApi() {
    this.ApicallService.getStatus().subscribe(
      (data: any) => {
        this.status1 = data;
        this.valuestatus = this.status1[0].plant_status
        // console.log(this.valuestatus)
        if (this.valuestatus == 'In Operation') {
          this.checkstatus = 'In Operation'
          this.coloval = '#39FF14'
          // this.checkstatus = 'Shutdown'
          // this.coloval = '#FD1C03'
          // } else if (this.valuestatus == 'Complained') {
          //   this.checkstatus = 'In Operation'
          //   this.coloval = '#39FF14'
        } else if (this.valuestatus == 'Unstable') {
          this.checkstatus = 'Unstable'
          this.coloval = 'orange'
        }
        // console.log(this.valuestatus,this.checkstatus,this.coloval)

        // console.log(this.status1.status, "data")
      },
      (err) => {
        // (err);

      }
    )
  }



  //   openDialog(): void {
  //     let dialogRef = this.dialog.open(AgmdemoComponent, {
  //       width: '600px',
  //       height:'400px',
  //       // data: {graphData: this.modelGraphValue, label:label, plot:plot},
  //       // data: {graphData: this.modelGraphValue.slice(900), label:label, plot:plot},
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //       // console.log('The dialog was closed');
  //     });

  // }

  getforecastTable() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    this.ApicallService.getForecastTableValues(this.startDate).subscribe(
      (data: any) => {
        this.Forecastdata = data;
        this.Forecastdatatemp = this.Forecastdata[0].forecast_cyc_6_outlet_co;
        this.ForecastdatatempAvg = this.Forecastdata[0].forecast_cyc_6_avg_temp;
        this.Forecastalertdatadt = this.Forecastalertdata[0].dateandtime;
        // // console.log(this.Forecastdata, "data")
      },
      (err) => {
        // (err);

      }
    )
  }
  getforecastAlertTable() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
  console.log(  this.startDate, "forecastwda")
    this.ApicallService.getForecastAlertTableValues( this.startDate).subscribe(
      (data: any) => {
        this.Forecastalertdata = data;

        // // console.log(this.Forecastdata, "data")
      },
      (err) => {
        // (err);

      }
    )
  }
  // changeDropdown(types:any) {  
  //   this.selectedDatenext=types.target.value
  //   console.log(this.selectedDatenext)
  //   this.ApicallService.getTableValues(types.target.value).subscribe(
  //     (data: any) => {
  //       this.Realtimedata = data;
  //       // // console.log(this.Realtimedata, "data")
  //     },
  //     (err) => {
  //       // (err);
  //     }
  //   )
  //   this.selectedForecastDatenext = parseInt(types.target.value) +300;
  //   this.ApicallService.getForecastTableValues(this.selectedForecastDatenext).subscribe(
  //     (data: any) => {
  //       this.Forecastdata = data;
  //       // // console.log(this.Forecastdata, "data")
  //     },
  //     (err) => {
  //       // (err);

  //     }
  //   )
  //   this.ApicallService.getAFRTableValues1(this.selectedDatenext).subscribe(
  //     (data: any) => {
  //       this.AFRdata1 = data;
  //       // // console.log(this.AFRdata, "data")
  //     },
  //     (err) => {
  //       // (err);

  //     }
  //   )

  //   this.ApicallService.getCoalTableValues(this.selectedDatenext).subscribe(
  //     (data: any) => {
  //       this.Coaldata = data;
  //       // // console.log(this.AFRdata, "data")
  //     },
  //     (err) => {
  //       // (err);

  //     }
  //   )
  //   this.getforecastAlertTable() ;
  // }
  getAFRTable1() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    this.ApicallService.getAFRTableValues1(this.startDate).subscribe(
      (data: any) => {
        this.AFRdata1 = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }
  getCOALTable() {
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
    this.ApicallService.getCoalTableValues(this.startDate).subscribe(
      (data: any) => {
        this.Coaldata = data;
        // // console.log(this.AFRdata, "data")
      },
      (err) => {
        // (err);

      }
    )

  }
  onNextclick() {
  //   this.startDate=
  //   new Date(
  //     new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
  //   ).getTime()/1000
  // ;
    console.log(this.startDate, "5mns")
    this.startDate = parseInt(this.startDate) + 600;
    console.log(this.startDate)
    this.ApicallService.getForecastTableValues(this.startDate).subscribe(

      (data: any) => {
        this.Forecastdata = data;
      },
      (err) => {
        // (err);
      }
    );
    this.ApicallService.getTableValues( this.startDate).subscribe(
      (data: any) => {
        this.Realtimedata = data;
      },
      (err) => {
        // (err);
      }
    )
  }
  onPreviousclick() {
    console.log(this.startDate, "--5mns")
    this.startDate = parseInt(this.startDate) - 600;
    this.selectedDatenext = parseInt(this.selectedDatenext) - 600;
    console.log(this.startDate)
    this.ApicallService.getTableValues( this.startDate).subscribe(
      (data: any) => {
        this.Realtimedata = data;
      },
      (err) => {
        // (err);
      }
    )
  
    this.ApicallService.getForecastTableValues(this.startDate).subscribe(

      (data: any) => {
        this.Forecastdata = data;
      },
      (err) => {
        // (err);
      }
    )
    // this.getforecastAlertTable();
  }
}
