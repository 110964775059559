<section>
    <div class="mainGridRgt11"
      style="display:flex;flex-direction:row;justify-content:space-between;color: white; border-bottom: 2px solid;">
      <div class="asImgBoxTop pt-2">
        <div class="asImgBoxTopTxt">Alert Table</div>
      </div>
      <div style="display: flex">
        <div class="mr-2 date-picker">
          <mat-form-field class="padding-test" appearance="outline">
            <mat-label style="color: white">Date</mat-label>
            <input style="color: white; font-size: 15px" matInput [min]="minDate" [max]="maxDate"
              [matDatepicker]="startdate" placeholder="Choose Start Date" [(ngModel)]="appliedDateFilters.start_date"
              [disabled]="false" />
            <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
            <mat-datepicker #startdate></mat-datepicker>
          </mat-form-field>
        </div>
        <button class="btn1" title="Select the date and click." style="
           font-size: 16px;
      width: 70px;
      cursor: pointer;
      background-color: #302c2c;
      outline: unset;
      /* margin-left: 4%; */
      /* padding-top: 2px; */
      border: 1px solid rgba(255, 255, 255, 0.38);
      height: 35px;border-radius: 0.9375em !important;
      " mat-raised-button color="primary"(click)="changeDate()">
          Apply
        </button>
      </div> 
        <!-- <div class="date-picker" style="width: 20%">
          <button title="Click to download the reports pdf." style="
                font-size: 16px;
                width: 50px;
                cursor: pointer;
                background-color: #302c2c;
                outline: unset;
                margin-left: 4%;
                padding-top: 2px;
                border: 1px solid rgba(255, 255, 255, 0.38);
                height: 35px;
              " class="get-sm-btn" (click)="getTableGraphValues();getTableGraphValues1();">
            <img src="../../../../assets/images/pdfimg.png" style="width:100%;height: 100%;" />
          </button>
        </div> -->
      <!-- </div> -->
    </div>
  
    <div *ngIf="!isLoading" style=" font-size: 13px;">
      <div class="red1">
        <div class="row" style="padding: 1%;">
          <div class="col-md-12">
            <p class="mainGridtitle1" style="font-size: 20px;font-weight: 600;">Alert Table</p>
            <table class="tableStyle1" *ngIf="SopAlerts.length != 0">
              <tr>
                <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Recorded Time</th>
                <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Clinker Quality</th>
                <!-- <td style="font-size: 16px;">{{vval.obs_time}}</td>
                <td style="font-size: 16px;">{{vval.value}}</td> -->
              </tr>
              <tr *ngFor="let vval of SopAlerts  ">
                <!-- <th style="font-size: 16px;color: yellow;">Main Coal(TPH)</th> -->
                <td style="font-size: 16px;">{{vval.obs_time| date:'short'}}</td>
                <td style="font-size: 16px;">{{vval.value}}</td>
                <!-- <td style="font-size: 16px;">{{vval.coal_savings}}</td> -->
              </tr>
              <!-- <tr>
                <th style="font-size: 16px;color: yellow;">Kiln speed(RPM)</th>
                <td style="font-size: 16px;">{{vval.kiln_actual}}</td>
                <td style="font-size: 16px;">{{vval.kiln_optimized}}</td>
                <td style="font-size: 16px;">{{vval.kiln_savings}}</td>
              </tr> -->
            </table>

            <table class="tableStyle1" *ngIf="SopAlerts.length == 0">
              <tr>
                <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Recorded Time</th>
                <th colspan="1" style="font-size: 16px;color: yellow;text-align: center;border-radius: 10px;">Clinker Quality</th>
                <!-- <td style="font-size: 16px;">{{vval.obs_time}}</td>
                <td style="font-size: 16px;">{{vval.value}}</td> -->
              </tr>
              <tr>
                <!-- <th style="font-size: 16px;color: yellow;">Main Coal(TPH)</th> -->
                <td colspan="2">No Data</td>
                <!-- <td style="font-size: 16px;">{{vval.coal_savings}}</td> -->
              </tr>
              <!-- <tr>
                <th style="font-size: 16px;color: yellow;">Kiln speed(RPM)</th>
                <td style="font-size: 16px;">{{vval.kiln_actual}}</td>
                <td style="font-size: 16px;">{{vval.kiln_optimized}}</td>
                <td style="font-size: 16px;">{{vval.kiln_savings}}</td>
              </tr> -->
            </table>
          </div>
        </div>
        </div>


        <div class="red1">
          <div class="row" style="padding: 1%;">
            <div class="col-md-12">
              <div style="display:flex ;flex-direction: row;justify-content: space-between;padding: 1%;">
                <p class="mainGridtitle1" style="font-size: 20px;font-weight: 600;">SOP Table</p>
                <span style=" color: #fff;">
                  <tr *ngIf="SopTable.length != 0">
                    <!-- <td>{{vval.obs_time | date:'dd/MM/yyyy, HH:mm:ss'}}                 
                          </td> -->
                    <td *ngFor="let vval of SopTable  ">Time -&nbsp;{{vval.obs_time | date: 'HH:mm' }}
                    </td>
                  </tr>
                  <tr *ngIf="SopTable.length == 0">
                    <!-- <td>{{vval.obs_time | date:'dd/MM/yyyy, HH:mm:ss'}}                 
                          </td> -->
                    <td *ngFor="let vval of SopTable  ">No Data
                    </td>
                  </tr>
                </span>
              </div>
              <table class="tableStyle1" *ngIf="SopTable.length != 0">
                <tr>
                  <th colspan="3" style="font-size: 15px;color: yellow;text-align: center;border-radius: 10px;">Constrained Value</th>
                  <!-- <th colspan="2" style="font-size: 15px;color: yellow;text-align: center;border-radius: 10px;">Recommended</th> -->
                </tr>
                <tr>
                  <td style="font-size: 15px;color: yellow;">Recorded Time</td>
                  <td style="font-size: 15px;color: yellow;">Temperature (°C)</td>
                  <td style="font-size: 15px;color: yellow;">CO (PPM)</td>
                  <!-- <td style="font-size: 15px;color: yellow;">Coal (TPH)</td>
                  <td style="font-size: 15px;color: yellow;">AFR (M3/Hr)</td>
                </tr> -->
                <tr *ngFor="let vval of SopTable  ">
                   <td style="font-size: 16px;">{{vval.obs_time| date:'short'}}</td>
                   <td style="font-size: 16px;">{{vval.constrained_temp}}</td>
                   <td style="font-size: 16px;">{{vval.constrained_co}}</td>
                   <!-- <td>- &nbsp;{{vval.coal_decreased}}</td>
                   <td> + &nbsp;{{vval.afr_increased}}</td> -->
                </tr>
              </table>

              <table class="tableStyle1" *ngIf="SopTable.length == 0">
                <tr>
                  <th colspan="3" style="font-size: 15px;color: yellow;text-align: center;border-radius: 10px;">Constrained Value</th>
                  <!-- <th colspan="2" style="font-size: 15px;color: yellow;text-align: center;border-radius: 10px;">Recommended</th> -->
                </tr>
                <tr>
                  <td style="font-size: 15px;color: yellow;">Recorded Time</td>
                  <td style="font-size: 15px;color: yellow;">Temperature (°C)</td>
                  <td style="font-size: 15px;color: yellow;">CO (PPM)</td>
                  <!-- <td style="font-size: 15px;color: yellow;">Coal (TPH)</td>
                  <td style="font-size: 15px;color: yellow;">AFR (M3/Hr)</td>
                </tr> -->
                <tr>
                  <td colspan="3">No Data</td>
                   <!-- <td>- &nbsp;{{vval.coal_decreased}}</td>
                   <td> + &nbsp;{{vval.afr_increased}}</td> -->
                </tr>
              </table>
            </div>
          </div>
          </div>
    
          <!-- <hr style="    margin-top: 10px !important;
          margin-bottom: 7px !important;
      "> -->
  
      <div style="display: flex;flex-direction:row;justify-content: space-between;padding: 1%;
            margin-top: 1%;
        ">
        <div>
          <span ><button mat-raised-button color="primary"
              (click)="onPrevClick()"> Previous</button></span>
          <!-- <span *ngIf="this.selectedDatenext=='1660847400'"><button mat-raised-button disabled style="border: 1px solid #999999;
            background-color: #cccccc;
            color: #666666;">
              Previous</button></span> -->
        </div>
        <button mat-raised-button color="primary" (click)="onNextclick()"> Next</button>
      </div>
    </div>

    <div *ngIf="isLoading" style="height: 75vh; display: grid; width: 100%">
      <div style="margin: auto">
        <img
          src="../../../../assets/icons/Spinner.gif"
          style="width: 80px"
        />
        <p style="text-align: center;color: #fff;">Loading...</p>
      </div>
    </div>
  </section>