import { AuthenticationService } from './../../core/authenticationn.service';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/core/models';
import { environment } from 'src/environments/environment';
import { APiService } from 'src/app/core/balco.service';

import { UtilsService } from "src/app/core/utils.service";
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  [x: string]: any;
  email_id: string;
  isValid: string;
  lnsURL=environment.lnsURL

  today: number = Date.now();
  activeTab :any;
  Realtimedata:any;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  
  constructor(private authenticationService: AuthenticationService, 
    private ApicallService:APiService ,    readonly utils: UtilsService) {
    
      this.currentUser = JSON.parse(localStorage.getItem('boilerUser'));    
        // // console.log(this.currentUser);       
  }

   

  ngOnInit(): void {
    this.isValid = localStorage.getItem("backToken");
    // if (this.utils.dateFilter) {
    //   this.appliedDateFilters["start_date"] = new Date(
    //     this.utils.dateFilter["start_date"]
    //   );
    // } else {
    //   // this.appliedDateFilters["start_date"] = new Date(
    //   //    new Date().setDate(new Date().getDate())
    //   //    ).setHours(0, 0, 0, 0);
    //   this.appliedDateFilters["start_date"] = this.maxDate;
    // }
    // console.log(this.appliedDateFilters["start_date"], "date1");
    
    // this.lastUpdated();
    // setInterval(() => {
    //   this.lastUpdated();
    // }, 120000); // 5 minutes in milliseconds  }
   
  }

  lastUpdated(){
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime() / 1000;
  console.log(this.startDate, "apidate1");
    this.ApicallService.getTableValues( this.startDate).subscribe(
      (data: any) => {
        this.Realtimedata = data;
        // console.log(this.Realtimedata, "data")
      },
      (err) => {
        // (err);

      }
    )
  }
  
  logout() {
    if(this.isValid){
      // // console.log("isBackToken",this.isValid);
      localStorage.removeItem('backToken');
      localStorage.removeItem("boilerUser")
      window.location.href=environment.lnsURL;
    }
    else{
    this.authenticationService.logout();
  }
  }
}
