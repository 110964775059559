<section>
  <div
    class="mainGridRgt11"
    style="
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: white;
      border-bottom: 2px solid;
    "
  >
    <div class="asImgBoxTop pt-2 pb-2">
      <div class="asImgBoxTopTxt">Kiln-Critical Parameters</div>
    </div>
    <div style="display: flex">
      <div class="mr-2 date-picker">
        <mat-form-field class="padding-test" appearance="outline">
          <mat-label style="color: white">Date</mat-label>
          <input
            style="color: white; font-size: 15px"
            matInput
            [min]="minDate"
            [max]="maxDate"
            [matDatepicker]="startdate"
            placeholder="Choose Start Date"
            [(ngModel)]="appliedDateFilters.start_date"
            [disabled]="false"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startdate"
          ></mat-datepicker-toggle>
          <mat-datepicker #startdate></mat-datepicker>
        </mat-form-field>
      </div>
      <button
        title="Select the date and click."
        style="
          font-size: 16px;
          width: 70px;
          cursor: pointer;
          background-color: #302c2c;
          outline: unset;
          /* margin-left: 4%; */
          /* padding-top: 2px; */
          border: 1px solid rgba(255, 255, 255, 0.38);
          height: 35px;
          border-radius: 0.9375em !important;
        "
        mat-raised-button
        color="primary"
        class="get-sm-btn btn1"
        (click)="changeDate()"
      >
        Apply
      </button>
    </div>
    <!-- <div  style="display: flex;margin-left: 15px;margin-right: 2px; font-size: 20px; font-weight: 600;">Status:
        <span style="margin-left:6px;margin-right:6px;" [ngStyle]="{'color': coloval}" >  {{checkstatus}}</span>
      </div> -->
  </div>
  <div *ngIf="!isLoading"
    style="
      font-size: 13px;
      flex-direction: column;
      display: flex;
      justify-content: space-evenly;
      height: 590px;
    "
  >
    <div style="display: flex; flex-direction: row; justify-content: flex-end">
      <p
        style="
          margin: 0px;
          margin-left: 10px;
          font-weight: 400;
          font-size: 20px;
          color: #fff;
        "
      >
        Alert Level:
      </p>
      &nbsp;&nbsp;
      <button
        style="
          height: 10px;
          width: 17px;
          left: 66px;
          top: 860px;
          margin-top: 11px;
          border-radius: 10px;
          border-width: 0px;
          /* background: #0eab73; */
        "
        [ngStyle]="{
          background: this.colorMix1 == 'G' ? '#39FF14' : '#0a3207'
        }"
      ></button
      >&nbsp;&nbsp;
      <button
        style="
          height: 10px;
          width: 17px;
          left: 66px;
          top: 860px;
          margin-top: 11px;
          border-radius: 10px;
          border-width: 0px;
          /* background: #e5b027; */
        "
        [ngStyle]="{
          background: this.colorMix1 == 'Y' ? '#e5b027' : '#332f00'
        }"
      ></button
      >&nbsp;&nbsp;
      <button
        style="
          height: 10px;
          width: 17px;
          left: 66px;
          top: 860px;
          margin-top: 11px;
          border-radius: 10px;
          border-width: 0px;
          /* background: #c7152a; */
        "
        [ngStyle]="{
          background: this.colorMix1 == 'R' ? '#c7152a' : '#300'
        }"
      ></button
      >&nbsp;&nbsp;
    </div>

    <div class="red1">
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <p class="mainGridtitle1">RealTime Parameters</p>
        <span style="color: #fff">
          <tr *ngFor="let vval of ActualForecastKilnRCdata">
            <!-- <td>{{vval.obs_time | date:'dd/MM/yyyy, HH:mm:ss'}}                 
                </td> -->
            <td>Time -&nbsp;{{ vval.obs_time | date : "HH:mm" }}</td>
          </tr>
        </span>
      </div>
      <div class="row" style="padding: 1%">
        <div class="col-md-6">
          <div
            class="red"
            style="
              border-radius: 6px;
              box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
            "
          >
            <label for="temp">Main Coal (TPH)</label>
            <span *ngIf="ActualForecastKilnRCdata.length != 0">
              <div *ngFor="let vval of ActualForecastKilnRCdata">
                <label class="per" *ngIf="vval.main_coal_tph < 0">0.00</label>
                <label class="per" *ngIf="vval.main_coal_tph >= 0">
                  {{ vval.main_coal_tph }}
                </label>
              </div>
            </span>
            <div *ngIf="ActualForecastKilnRCdata.length == 0 || null">
              <label class="per">No data</label>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="red"
            style="
              border-radius: 6px;
              box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
            "
          >
            <label for="temp">Kiln Speed (RPM)</label>
            <span *ngIf="ActualForecastKilnRCdata.length != 0">
              <div *ngFor="let vval of ActualForecastKilnRCdata">
                <label class="per" *ngIf="vval.kiln_speed < 0">0.00</label>
                <label class="per" *ngIf="vval.kiln_speed >= 0">
                  {{ vval.kiln_speed }}
                </label>
              </div>
            </span>
            <div *ngIf="ActualForecastKilnRCdata.length == 0 || null">
              <label class="per">No data</label>
            </div>
          </div>
        </div>

        <div class="col-md-12" style="padding-top: 10px">
          <div
            class="red"
            style="
              border-radius: 6px;
              box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
            "
          >
            <label for="temp">PA Fan Speed (RPM)</label>
            <span *ngIf="ActualForecastKilnRCdata.length != 0">
              <div *ngFor="let vval of ActualForecastKilnRCdata">
                <label class="per" *ngIf="vval.pa_fan_speed < 0">0.00</label>
                <label class="per" *ngIf="vval.pa_fan_speed >= 0">
                  {{ vval.pa_fan_speed }}
                </label>
              </div>
            </span>
            <div *ngIf="ActualForecastKilnRCdata.length == 0 || null">
              <label class="per">No data</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <hr style="    margin-top: 10px !important;
      margin-bottom: 7px !important;
  "> -->
    <div class="red1">
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <p class="mainGridtitle1">Recommended Values</p>
        <span style="color: #fff">
          <tr *ngFor="let vval of ForecastKilnRCdata">
            <!-- <td>{{vval.obs_time | date:'dd/MM/yyyy, HH:mm:ss'}}                 
                </td> -->
            <td>Time -&nbsp;{{ vval.obs_time }}</td>
          </tr>
        </span>
      </div>

      <div class="row" style="padding: 1%">
        <div class="col-md-6">
          <div
           
            class="red"
            style="
              border-radius: 6px;
              box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
            "
          >
            <label for="temp">Main Coal (TPH)</label>
            <span  *ngIf="ForecastKilnRCdata.length != 0">
            <div  *ngFor="let vval of ForecastKilnRCdata">
              <label class="per" *ngIf="vval.mc_tph < 0">0.00</label>
              <label class="per" *ngIf="vval.mc_tph >= 0">
                {{ vval.mc_tph }}
              </label>
            </div>
            <span
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
            "
            (click)="openDialogMC()"
          >
            <img
              src="../../../../assets/icons/charticon.png"
              style="width: 15px; height: 15px; margin-top: 4px"
            />
            <p
              style="
                margin-bottom: 0px;
                margin-left: 5px;
                text-decoration: underline;
                cursor: pointer;
              "
            >
              View Chart
            </p>
          </span>
        </span>
            <div *ngIf="ForecastKilnRCdata.length == 0 || null">
              <label class="per">No data</label>
            </div>
         
          </div>
        </div>
        <div class="col-md-6">
          <div
           
            class="red"
            style="
              border-radius: 6px;
              box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
            "
          >
            <label for="temp">Kiln Speed (RPM)</label>
            <span  *ngIf="ForecastKilnRCdata.length != 0">
            <div  *ngFor="let vval of ForecastKilnRCdata">
              <label class="per" *ngIf="vval.kiln_speed < 0">0.00</label>
              <label class="per" *ngIf="vval.kiln_speed >= 0">
                {{ vval.kiln_speed }}
              </label>
            </div>
            <span
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
            "
            (click)="openDialogKS()"
          >
            <img
              src="../../../../assets/icons/charticon.png"
              style="width: 15px; height: 15px; margin-top: 4px"
            />
            <p
              style="
                margin-bottom: 0px;
                margin-left: 5px;
                text-decoration: underline;
                cursor: pointer;
              "
            >
              View Chart
            </p>
          </span>
        </span>
            <div *ngIf="ForecastKilnRCdata.length == 0 || null">
              <label class="per">No data</label>
            </div>
           
          </div>
        </div>

        <div class="col-md-12" style="padding-top: 10px">
          <div
           
            class="red"
            style="
              border-radius: 6px;
              box-shadow: inset 7px 7px 14px black, inset -7px -7px 14px #5a5a5a;
            "
          >
            <label for="temp">PA Fan Speed (RPM)</label>
            <span  *ngIf="ForecastKilnRCdata.length != 0"> 
            <div  *ngFor="let vval of ForecastKilnRCdata">
              <label class="per" *ngIf="vval.pa_fan_rpm < 0">0.00</label>
              <label class="per" *ngIf="vval.pa_fan_rpm >= 0">
                {{ vval.pa_fan_rpm }}
              </label>
            </div> 
            <span
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
            "
            (click)="openDialogPA()"
          >
            <img
              src="../../../../assets/icons/charticon.png"
              style="width: 15px; height: 15px; margin-top: 4px"
            />
            <p
              style="
                margin-bottom: 0px;
                margin-left: 5px;
                text-decoration: underline;
                cursor: pointer;
              "
            >
              View Chart
            </p>
          </span>
        </span>
            <div *ngIf="ForecastKilnRCdata.length == 0 || null">
              <label class="per">No data</label>
            </div>
           
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1%;
        margin-top: 1%;
      "
    >
      <div>
        <span
          ><button mat-raised-button color="primary" (click)="onPrevClick()">
            Previous
          </button></span
        >
        <!-- <span *ngIf="this.selectedDatenext=='1660847400'"><button mat-raised-button disabled style="border: 1px solid #999999;
            background-color: #cccccc;
            color: #666666;">
              Previous</button></span> -->
      </div>
      <button mat-raised-button color="primary" (click)="onNextclick()">
        Next
      </button>
    </div>
  </div>

  <div *ngIf="isLoading" style="height: 75vh; display: grid; width: 100%">
    <div style="margin: auto">
      <img
        src="../../../../assets/icons/Spinner.gif"
        style="width: 80px"
      />
      <p style="text-align: center;color: #fff;">Loading...</p>
    </div>
  </div>
</section>
