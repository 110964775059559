import { APiService } from 'src/app/core/balco.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { ExcelsheetComponent } from 'src/app/components/excelsheet/excelsheet.component';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/core/utils.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  lnsURL=environment.lnsURL;
  public isValid: string;
  imageToggle = 1;
  imageFix='100%';
  selectedDatenext: any;
  isDefinedFilterSelected: boolean = false;
  selectedDefaultDate: string;
  // maxDate: any = new Date();
  isDatePickerOpened: boolean = false;
  dateRangaPicker: any;
  appliedDateFilters: any = {};
  maxDate: Date = new Date(Date.now());
  minDate:  Date = new Date('04/01/2023');

  SelectTheme = [
    'Dashboard',
    // 'KPI Validation',
    'Clinker Quality',
    'Kiln 4.0',
    'AFR 4.0',
    'SOP & Alerts',
    // 'CO Savings',
    // 'File Upload',
    'User Management',
  
  ];

  SelectedTheme = 'Dashboard';
  dataToggle: number =1;
  kilnToggle:number=1;
  ForecastKilndata: any;
  startdatef: any;

  constructor(private ApicallService: APiService , public dialog: MatDialog, 
        readonly utils: UtilsService,) {
  }

  ngOnInit(): void {
    // this.dataToggle = 1;
    this.isValid = localStorage.getItem("backToken");
    if (this.utils.dateFilter) {
      this.appliedDateFilters["start_date"] = new Date(
        this.utils.dateFilter["start_date"]
      );
    } else {
      // this.appliedDateFilters["start_date"] = new Date(
      //    new Date().setDate(new Date().getDate())
      //    ).setHours(0, 0, 0, 0);
         this.appliedDateFilters["start_date"]= this.maxDate;
    }
    console.log(this.appliedDateFilters['start_date'] ,"date1")
      
  }
  changeDate(){
    this.appliedDateFilters["start_date"] = this.appliedDateFilters.start_date;
    this.utils.dateFilter = this.appliedDateFilters;
    console.log(this.appliedDateFilters['start_date'] ,
    this.appliedDateFilters.start_date,
    "changedata")
    this.getClinker();
  }
  
  getClinker() {
    let startDate =
      new Date(
        new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
      ).getTime()/1000
    ;
    console.log( startDate, "apidate1")
    this.ApicallService.getClinkerValues(startDate).subscribe(
      (data: any) => {
        this.ForecastKilndata = data;
        
        console.log(this.ForecastKilndata,startDate ,"apidate2")
      },
      (err) => {
        (err);

      }
    )
  }


  // radio buttom theme event
  onItemChange(event) {
    // this.SelectedTheme = event.SelectTheme.value;
    this.imageToggle = 2;

    setTimeout(()=>{                           
           this.imageToggle = 1;
        }, 5000);
   
  }
  onItemChangecontent(event) {
    // this.SelectedTheme = event.SelectTheme.value;
    this.dataToggle = 2;

    // setTimeout(()=>{                           
    //        this.dataToggle = 1;
    //     }, 5000);
   
  }
  onItemChangeconten1t(event) {
    // this.SelectedTheme = event.SelectTheme.value;
    this.dataToggle = 1;

    // setTimeout(()=>{                           
    //        this.dataToggle = 1;
    //     }, 5000);
   
  }
  // kilnToggle
  onItemChangecontentkiln(event) {
    // this.SelectedTheme = event.SelectTheme.value;
    this.kilnToggle = 2;

    // setTimeout(()=>{                           
    //        this.dataToggle = 1;
    //     }, 5000);
   
  }
  onItemChangeconten1tkiln(event) {
    // this.SelectedTheme = event.SelectTheme.value;
    this.kilnToggle = 1;

    // setTimeout(()=>{                           
    //        this.dataToggle = 1;
    //     }, 5000);
   
  }
  toggleImage(value:any){
    if(value == 1){
      this.imageToggle = 1;
    }
    else{
      this.imageToggle = 2;
    }
  }
  
  cleanUp(){
    localStorage.removeItem('boilerUser');
    localStorage.removeItem('backToken');
  }

 

 

}
