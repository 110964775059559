import { APiService } from 'src/app/core/balco.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit ,Input} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PlotChartsService } from 'src/app/core/charts/plot-charts.service';
import { DialogboxComponent } from 'src/app/components/dialogbox/dialogbox.component';
// import { setInterval } from 'timers';
import { UtilsService } from 'src/app/core/utils.service';

@Component({
  selector: 'app-theme9',
  templateUrl: './theme9.component.html',
  styleUrls: ['./theme9.component.scss'],
})
export class Theme9Component implements OnInit {
  @Input() ForecastKilndata1: any[];

  [x: string]: any;
  isLoading: boolean = true;

  Realtimedata: any = [];
  Forecastdata: any = [];
  Forecastalertdata: any = [];
  selectedDate: any;
  selectedDatenext: any;
  selectedForecastDatenext: any;
  selectedDateprevious: any;
  AFRdata1: any = [];
  Coaldata: any = [];
  status1: any;
  valuestatus: any;
  checkstatus: any;
  coloval: any;
  Realtimedatadt: any;
  Forecastalertdatadt: any;
  Forecastdatatemp: any;
  ForecastdatatempAvg: any;
  ForecastKilndata:any;
  ForecastKilnRCdata:any;
  ActualForecastKilnRCdata:any;
  appliedDateFilters: any = {};
  maxDate: Date = new Date('06/22/2023');
  minDate:  Date = new Date('04/01/2023');

  constructor(
    private toastr: ToastrService,
    private ApicallService: APiService,
    private object: FormBuilder,
    public dialog: MatDialog,
    private plotChartsService: PlotChartsService,
    readonly utils: UtilsService,) {
  }

  openDialogMC() {
    const dialogRef: MatDialogRef<DialogboxComponent> = this.dialog.open(
      DialogboxComponent,
      {
        width: "1200px",
        height: "520px",
        data: { clicked: 15,   ApiDate: this.appliedDateFilters["start_date"]},
      }
    );
  }
  openDialogKS() {
    const dialogRef: MatDialogRef<DialogboxComponent> = this.dialog.open(
      DialogboxComponent,
      {
        width: "1200px",
        height: "520px",
        data: { clicked: 16,   ApiDate: this.appliedDateFilters["start_date"]},
      }
    );
  }
  openDialogPA() {
    const dialogRef: MatDialogRef<DialogboxComponent> = this.dialog.open(
      DialogboxComponent,
      {
        width: "1200px",
        height: "520px",
        data: { clicked: 17,   ApiDate: this.appliedDateFilters["start_date"]},
      }
    );
  }



  ngOnInit() {
    this.isLoading = true;
    if (this.utils.dateFilter) {
      this.appliedDateFilters["start_date"] = new Date(
        this.utils.dateFilter["start_date"]
      );
    } else {
      // this.appliedDateFilters["start_date"] = new Date(
      //    new Date().setDate(new Date().getDate())
      //    ).setHours(0, 0, 0, 0);
         this.appliedDateFilters["start_date"]= this.maxDate;
    }
    console.log(this.appliedDateFilters['start_date'] ,"date1")

    // setTimeout(() => {
      this.getActualkilnforecastRC();
      this.getkilnforecastRC();
      this.getkilnforecast();
    // }, 100);
  
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);

    // console.log(this.appliedDateFilters, startDate)
    // this.selectedDatenext='1660847400';
    // this.selectedForecastDatenext = parseInt(this.selectedDatenext) + 300;

    
  }
  changeDate(){
    this.appliedDateFilters["start_date"] = this.appliedDateFilters.start_date;
    this.utils.dateFilter = this.appliedDateFilters;
    console.log(this.appliedDateFilters['start_date'] ,
    this.appliedDateFilters.start_date,
    "changedata")
    this.getActualkilnforecastRC();
    this.getkilnforecastRC();
    this.getkilnforecast();
  }
  
  getActualkilnforecastRC(){
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
  console.log(  this.startDate, "forecastlin")
    this.ApicallService.getActualForecastKilnRecmnd( this.startDate).subscribe(
      (data: any) => {
        this.ActualForecastKilnRCdata = data;

        console.log(this.ActualForecastKilnRCdata, "data")
      },
      (err) => {
        // (err);

      }
    ) 
  }

  getkilnforecast(){
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
  console.log(  this.startDate, "forecastlin")
    this.ApicallService.getForecastKiln( this.startDate).subscribe(
      (data: any) => {
        this.ForecastKilndata = data;
        this.colorMix1 = this.ForecastKilndata[0].alert_level;
        console.log(this.ForecastKilndata, "data")
      },
      (err) => {
        // (err);

      }
    ) 
  }

  getkilnforecastRC(){
    this.startDate =
    new Date(
      new Date(this.appliedDateFilters["start_date"]).setHours(0, 0, 0, 0)
    ).getTime()/1000
  ;
  console.log(  this.startDate, "forecastlin")
    this.ApicallService.getForecastKilnRecmnd( this.startDate).subscribe(
      (data: any) => {
        this.ForecastKilnRCdata = data;

        console.log(this.ForecastKilnRCdata, "data")
      },
      (err) => {
        // (err);

      }
    ) 
  }





  onNextclick() {
    console.log(this.startDate, "5mns")
    this.startDate = parseInt(this.startDate) + 600;
    console.log(this.startDate)
  
    this.ApicallService.getForecastKilnRecmnd( this.startDate).subscribe(
      (data: any) => {
        this.ForecastKilnRCdata = data;

        console.log(this.ForecastKilnRCdata, "data")
      },
      (err) => {
        // (err);

      }
    ) 

    this.ApicallService.getActualForecastKilnRecmnd( this.startDate).subscribe(
      (data: any) => {
        this.ActualForecastKilnRCdata = data;

        console.log(this.ActualForecastKilnRCdata, "data")
      },
      (err) => {
        // (err);

      }
    ) 
  }
  onPrevClick(){
    console.log(this.startDate, "5mns")
    this.startDate = parseInt(this.startDate) - 600;
    console.log(this.startDate)
  
    this.ApicallService.getForecastKilnRecmnd( this.startDate).subscribe(
      (data: any) => {
        this.ForecastKilnRCdata = data;

        console.log(this.ForecastKilnRCdata, "data")
      },
      (err) => {
        // (err);

      }
    ) 

    this.ApicallService.getActualForecastKilnRecmnd( this.startDate).subscribe(
      (data: any) => {
        this.ActualForecastKilnRCdata = data;

        console.log(this.ActualForecastKilnRCdata, "data")
      },
      (err) => {
        // (err);

      }
    ) 
  }
  onPreviousclick() {
    this.selectedDatenext = parseInt(this.selectedDatenext) - 300;
    console.log(this.selectedDatenext)
    // this.ApicallService.getTableValues().subscribe(
    //   (data: any) => {
    //     this.Realtimedata = data;
    //   },
    //   (err) => {
    //     // (err);
    //   }
    // )
    this.selectedForecastDatenext = parseInt(this.selectedForecastDatenext) - 300;
    console.log(this.selectedForecastDatenext)
    this.ApicallService.getForecastTableValues(this.selectedForecastDatenext).subscribe(

      (data: any) => {
        this.Forecastdata = data;
      },
      (err) => {
        // (err);
      }
    )
    this.getforecastAlertTable();
  }
}
