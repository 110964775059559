import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const baseUrl = environment['baseUrl'];
const baseUrl2 = environment['baseUrl2'];
const baseUrl3 =environment['baseUrl3'];

@Injectable({
  providedIn: 'root',
})
export class PlotChartsService {
  constructor(private http: HttpClient) { }



  // 
  getFC1GraphData(starttime: any): Observable<any> {
    return this.http.get(`${baseUrl2}real_time_actual_forecast_graph_01?date=${starttime}`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          // console.log(error);
        }
      )
    );
  }

  getFC2GraphData(starttime: any): Observable<any> {
    // real_time_tracking_graph?start_time=
    // actual_forecast_graph_02?start_time=
    // https://api.dev.cement4.0.livnsense.com/real_time_actual_forecast_graph_01
    return this.http.get(`${baseUrl2}real_time_actual_forecast_graph_02?date=${starttime}`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          // console.log(error);
        }
      )
    );
  }

  getAfrGraphData(starttime: any): Observable<any> {
    // afr_recommendation_graph?start_time=1661040000
    // realtime_opt_graph?start_time=
    return this.http.get(`${baseUrl3}afr_recommendation_graph?start_time=${starttime}`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          // console.log(error);
        }
      )
    );
  }

getCOdata1(starttime: any): Observable<any> {
  // afr_recommendation_graph?start_time=1661040000
  // realtime_opt_graph?start_time=
  return this.http.get(`${baseUrl2}coal_graph?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        return response;
      },
      (error) => {
        // console.log(error);
      }
    )
  );
}
getCOdata2(starttime: any): Observable<any> {
  // afr_recommendation_graph?start_time=1661040000
  // realtime_opt_graph?start_time=
  return this.http.get(`${baseUrl2}co_graph?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        return response;
      },
      (error) => {
        // console.log(error);
      }
    )
  );
}


// Theme 7

// FreeLime Graph
getFreelimeGraph(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl2}free_lime_graph?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        return response;
      },
      (error) => {
        // console.log(error);
      }
    )
  );
}

// Lsf Graph

getLSFGraph(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl2}lsf_graph?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        return response;
      },
      (error) => {
        // console.log(error);
      }
    )
  );
}

// SR Graph

getSRGraph(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl2}sr_graph?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        return response;
      },
      (error) => {
        // console.log(error);
      }
    )
  );
}
// AF Graph

getAFGraph(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl2}af_graph?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        return response;
      },
      (error) => {
        // console.log(error);
      }
    )
  );
}

// Theme 8
getFreelimeKILNGraph(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl2}forecast_free_lime_graph?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        return response;
      },
      (error) => {
        // console.log(error);
      }
    )
  );
}

getBurningGraph(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl2}burning_zone_temp_graph?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        return response;
      },
      (error) => {
        // console.log(error);
      }
    )
  );
}

getinleto2Graph(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl2}kiln_inlet_o2_graph?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        return response;
      },
      (error) => {
        // console.log(error);
      }
    )
  );
}

getinletCOGraph(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl2}kiln_inlet_co_graph?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        return response;
      },
      (error) => {
        // console.log(error);
      }
    )
  );
}


// Theme 9
getinletMCGraph(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl2}main_coal_graph?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        return response;
      },
      (error) => {
        // console.log(error);
      }
    )
  );
}


getinletKSGraph(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl2}kiln_speed_graph?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        return response;
      },
      (error) => {
        // console.log(error);
      }
    )
  );
}
getinletPAGraph(starttime: any): Observable<any> {
  return this.http.get(`${baseUrl2}pa_fan_graph?start_time=${starttime}`).pipe(
    map(
      (response: any) => {
        return response;
      },
      (error) => {
        // console.log(error);
      }
    )
  );
}























}
