import { AuthGuard } from './core/helpers/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component'
import { HttpClientModule } from '@angular/common/http';
import { MarketplaceloginComponent } from './pages/marketplacelogin/marketplacelogin.component';
// import { MarketplaceloginComponent } from './pages/marketplacelogin/marketplacelogin.component';

const routes: Routes = [
  { 
    path: 'login', 
    component: LoginComponent 
  },{ 
    path: 'dashboard', 
    component: DashboardComponent,
    canActivate: [AuthGuard], 
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  { path: 'pyrologin/:id', component: MarketplaceloginComponent },
  // { path: 'boilerlogin/:id', component: MarketplaceloginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule,HttpClientModule]
})
export class AppRoutingModule { }
